import {
  defaultFont,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { BackgroundImage } from "views/Pages/ad/addAdcomponents/BackgroundImage";

const notificationsStyle = theme => ({
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: grayColor[2],
    fontSize: "18px"
  },
  cardHeader: {
    zIndex: "3"
  },
  cardSubtitle: {
    ...defaultFont,
    color: grayColor[0],
    fontSize: "14px",
    margin: "0 0 10px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  marginRight: {
    marginRight: "5px"
  },
  modalSectionTitle: {
    marginTop: "30px"
  },
  title: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "30px",
    color: "#1A405F"
  },
  markButton: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    borderRadius: "7px",
    background: "#1A405F",
    color: "#FFFFFF",
    textTransform: "capitalize",
    padding: "10px",
    marginLeft: "20px",
    "&:hover": {
      background: "#06667C"
    }
  },
  card: {
    padding: "15px 10px",
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    borderRadius: "7px",
    marginTop: "15px"
  },
  cardUnread: {
    padding: "15px 10px",
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    borderRadius: "7px",
    border: "2px solid #56CA93",
    marginTop: "15px"
  },
  cardDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#1A405F",
  },
  cardDescUnderline: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#1A405F",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  ...modalStyle(theme)
});

export default notificationsStyle;
