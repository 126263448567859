import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, FormControl, Hidden, MenuItem, Select, TextField } from '@material-ui/core';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";


// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';


export function TravelRewardName(props) {
    const {
        classes,
        history,
        rewardName,
        setRewardName,
        handleSelect,
        address,
        setAddress
    } = props;
    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex" }}>
                            <p style={{ fontWeight: 600, marginRight: "10px", marginTop: "5px", whiteSpace: "noWrap", }}>Hotel Name</p>
                            <TextField
                                className={classes.textField}
                                value={rewardName}
                                onChange={(e) => setRewardName(e.target.value)}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        adornedEnd: classes.adournedEnd,
                                        input: classes.input
                                    },
                                }}
                            />
                        </GridItem>

                        <GridItem xs={6} style={{ display: "flex" }}>
                            <p style={{ fontWeight: 600, marginRight: "10px", marginTop: "5px", whiteSpace: "noWrap", }}>Address</p>
                            <PlacesAutocomplete
                                value={address}
                                onChange={(e) => setAddress(e)}
                                onSelect={(e) => handleSelect(e, "Search the address..")}
                                googleCallbackName={"initOne"}
                                searchOptions={{
                                    componentRestrictions: { country: "us" },
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div style={{ width: "100%" }}>
                                        <FormControl
                                            style={{ width: "100%" }}
                                        >
                                            <TextField
                                                className={classes.textField}
                                                value={rewardName}
                                                onChange={(e) => setRewardName(e.target.value)}
                                                InputProps={{
                                                    autocomplete: "new-password",
                                                    form: {
                                                        autocomplete: "off",
                                                    },
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classes.textField,
                                                        adornedEnd: classes.adournedEnd,
                                                        input: classes.input
                                                    },
                                                }}
                                                {...getInputProps({
                                                    placeholder: "Search the address..",
                                                    className: "location-search-input",
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? "suggestion-item--active"
                                                        : "suggestion-item";
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? {
                                                            backgroundColor: "#fafafa",
                                                            cursor: "pointer",
                                                        }
                                                        : {
                                                            backgroundColor: "#ffffff",
                                                            cursor: "pointer",
                                                        };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </FormControl>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(TravelRewardName));