import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { default as DefaultChip } from '@material-ui/core/Chip';

import style from 'assets/jss/material-dashboard-pro-react/components/chipArrayStyle';

function ChipArray(props) {

    const { id, classes, values = [], readOnly = false, Chip = DefaultChip, onChange } = props;

    return (
        <Paper id={id} component="ul" className={classes.root}>
            { values.map((data, index) => {
                const chipProps = typeof data === 'string' ? { label : data } : data 
                return (
                    <li key={index}>
                        <Chip {...chipProps} 
                            className={classes.chip}
                            onDelete={()=>!readOnly && onChange(values.filter((_,ix)=>ix!==index))}
                        />
                    </li>
                );
            })}
        </Paper>
    );
}

export default withStyles(style)(ChipArray);