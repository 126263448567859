const isoMerchantProfileTableStyle = (theme) => ({
    rewardsTable: {
        width: "100% !important",
        borderCollapse: "collapse",
        padding: "0px 30px 0px 20px"
        // [theme.breakpoints.down("md")]: {
        //   tableLayout: "fixed",
        //   width: "100%",
        // },
      },
      tableRow: {
        height: "30px",
        background: "#FFFFFF !important"
      },
      tableRowOdd: {
        height: "30px",
        background: "#E8EBEF  !important"
      },
      tableHeaders: {
        border: "0.5px solid #1A405F",
        background: "#1A405F",
        color: "#FFFFFF",
        fontFamily: "Josefin Sans",
        fontSize: "15px",
        padding: "10px",
        textTransform: "uppercase",
      },
      tableCell: {
        textAlign: "center",
        border: "0.5px solid #1A405F",
        padding: "10px 20px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        color: "#1A405F",
        
      },
      indexes: {
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        color: "#1A405F"
      },
})

export default isoMerchantProfileTableStyle