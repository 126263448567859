import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/adStyle';

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, FormControlLabel, Hidden, Radio, Typography } from '@material-ui/core';

export function AdType(props) {
    const {
        classes,
        history,
        option,
        setOption,
        adLocation,
        setAdLocation
    } = props;


    return (
        <div>
           <Hidden smDown implementation="css">
                <Card className={classes.card} style={{ marginTop: "0px" }}>
                    <GridContainer style={{ alignItems: "center" }}>
                        <GridItem xs={2}>
                            <Typography className={classes.adTypeTitle}>
                                Type
                            </Typography>
                        </GridItem>
                        <GridItem xs={4} style={{ display: "flex", alignItems: "center" }}>
                            <FormControlLabel
                                className={classes.checkboxes}
                                control={
                                    <Radio
                                        style={{ color: "#1A405F" }}
                                        checked={option === "Product"}
                                        onClick={() => setOption("Product")}
                                        value="Product"
                                    />
                                }
                            />

                            <Typography className={classes.radioText}>
                                Featured Product
                            </Typography>
                        </GridItem>
                        <GridItem xs={4} style={{ display: "flex", alignItems: "center" }}>
                            <FormControlLabel
                                className={classes.checkboxes}
                                control={
                                    <Radio
                                        style={{ color: "#1A405F" }}
                                        checked={option === "Ad"}
                                        onClick={() => setOption("Ad")}
                                        value="Ad"
                                    />
                                }
                            />

                            <Typography className={classes.radioText}>
                                Promotional Ad
                            </Typography>
                        </GridItem>

                        <GridItem xs={2} />

                        <GridItem xs={2}>
                            <Typography className={classes.adTypeTitle}>
                                Location
                            </Typography>
                        </GridItem>
                        <GridItem xs={4} style={{ display: "flex", alignItems: "center" }}>
                            <FormControlLabel
                                className={classes.checkboxes}
                                control={
                                    <Radio
                                        style={{ color: "#1A405F" }}
                                        checked={adLocation === "Travel"}
                                        onClick={() => setAdLocation("Travel")}
                                        value="Travel"
                                    />
                                }
                            />

                            <Typography className={classes.radioText}>
                                Travel Rewards Banner
                            </Typography>
                        </GridItem>
                        <GridItem xs={4} style={{ display: "flex", alignItems: "center" }}>
                            <FormControlLabel
                                className={classes.checkboxes}
                                control={
                                    <Radio
                                        style={{ color: "#1A405F" }}
                                        checked={adLocation === "Dashboard"}
                                        onClick={() => setAdLocation("Dashboard")}
                                        value="Dashboard"
                                    />
                                }
                            />

                            <Typography className={classes.radioText}>
                                Merchant Activity Dashboard
                            </Typography>
                        </GridItem>

                        <GridItem xs={2} />
                    </GridContainer>


                </Card>
            </Hidden>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(AdType));
