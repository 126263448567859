import React from "react";
import Card from "components/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import PopupMenu from "components/Navbars/PopupMenu";
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from "components/CustomButtons/Button";

import FlagIcon from '@material-ui/icons/Flag';

import styles from "assets/jss/material-dashboard-pro-react/components/explorerStyle";

const useStyles = makeStyles(styles);


export default function ExplorerCard(props) {


    const classes = useStyles();

    const { CardFields, CardField, editRoute, viewRoute, notesRoute, order, children, index, needsAttention, routeName } = props;

    return (
        <Card key={index} onDoubleClick={() => props.openChild("View Record", viewRoute)}>
            {/* Explorer Cards for Leads */}
            {props.routeName == "/admin/leads" ?
                <div style={{ padding: "15px" }}>
                    <CardIcon color="info">
                        <PopupMenu items={{ edit: () => props.openChild("Edit Record", editRoute), showDetail: () => props.openChild("View Record", viewRoute) }} />
                    </CardIcon>
                    <h1>{props.firstName} {props.middleName} {props.lastName}</h1>
                    {/* <h3>Join Date: {props.joinDate.c.month}/{props.joinDate.c.day}/{props.joinDate.c.year}</h3> */}
                    <h3><b>Notes: </b>{props.notes ? `${props.notes}` : "No notes available at this time"}</h3>
                    <h3 style={{ color: "#05669b" }}><b>Join Date: </b>{props.joinDate ? `${props.joinDate.c.month}/${props.joinDate.c.day}/${props.joinDate.c.year}` : "Please edit this lead to add Join Date"}</h3>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3><b>Current Score: </b>{props.currentScore ? `${props.currentScore}` : "Current Score not entered yet"}</h3>
                        <h3><b>Goal Score: </b>{props.goalScore ? `${props.goalScore}` : "Goal Score not entered yet"}</h3>
                    </div>
                </div>
                :
                <div>
                    {/* Explorer Cards for Customers */}
                    <CardHeader icon>
                        <CardIcon color="info">
                            <PopupMenu items={{ edit: () => props.openChild("Edit Record", editRoute), showDetail: () => props.openChild("View Record", viewRoute) }} />
                        </CardIcon>
                    </CardHeader>
                    {props.needsAttention == true ?
                        <div className={classes.attentionFlag}>
                            <FlagIcon />
                        </div> : null}
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={6}>
                                <h6>Client Name</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="fullName"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                            <GridItem xs={6}>
                                <h6>Nickname</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="nickName"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={2}>
                                <h6>Initial Credit Score</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="currentScore"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                            <GridItem xs={2}>
                                <h6>Goal Score</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="goalScore"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                            <GridItem xs={4}>
                                <h6>Credit Analyst</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="creditAnalyst"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                            <GridItem xs={4}>
                                <h6>Referral Source</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="loanOfficer"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={3}>
                                <h6>State</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="state"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                            <GridItem xs={4}>
                                <h6>Estimated Pull Date</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="estimatedPullDate"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                            <GridItem xs={5}>
                                <h6>Email Address</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="emailAddress"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={6}>
                                <h6>Status</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="status"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                            <GridItem xs={2}>
                                <h6>Last Four SSN</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="lastFourSSN"
                                        index={index}
                                        value={props.lastFourSSN}
                                    />
                                </Card>
                            </GridItem>
                            <GridItem xs={2}>
                                <h6>Tuzo</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="isQualified"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                            <GridItem xs={2}>
                                <h6>Credit Report</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="hasCreditReport"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12}>
                                <h6>Important Notes</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="importantNotes"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12}>
                                <h6>Description</h6>
                                <Card>
                                    <CardField
                                        classes={{ value: classes.cardBody }}
                                        label=""
                                        name="description"
                                        index={index}
                                    />
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <div className={classes.buttonContainer}>
                            {/* <Button className={classes.advanceStatusButton} disabled onClick={() => props.advance()}>Advance Status</Button> */}
                            {/* <Button className={classes.notesButton} onClick={() => props.openChild("Notes", notesRoute)}>Notes</Button> */}
                        </div>
                        {/* <CardFields index={index} order={order} />
                {children} */}
                    </CardBody>
                </div>
            }
        </Card>
    );
}