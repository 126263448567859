const activityStyle = (theme) => ({
    bannerTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "24px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "24px",
    },
    bannerTitlePadding: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "24px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    bannerDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        color: "#FFFFFF",
        lineHeight: "19px",
        margin: "5px 0px 0px 10px",
        whiteSpace: "noWrap"
    },
    bannerDescPadding: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        color: "#FFFFFF",
        lineHeight: "19px",
        margin: "5px 0px 0px 10px",
        whiteSpace: "noWrap",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    picDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        color: "#FFFFFF",
        lineHeight: "24px",
        marginTop: "15px",
        textAlign: 'left',
        padding: "0px 20px"
    },
    transferButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        lineHeight: "17px",
        color: "white",
        background: "#E2A400",
        textTransform: "capitalize",
        "&:hover, &:focus": {
            backgroundColor: "#E2A400",
        }
    },
    transferPtsButton: {
        padding: "6px 15px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        lineHeight: "17px",
        color: "white",
        background: "#E2A400",
        textTransform: "capitalize",
        "&:hover, &:focus": {
            backgroundColor: "#E2A400",
        }
    },
    modalButton: {
        borderRadius: "7px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19px",
        lineHeight: "19px",
        color: "white",
        background: "#E2A400",
        textTransform: "capitalize",
        height: "42px",
        width: "125px",
        "&:hover, &:focus": {
            backgroundColor: "#E2A400",
        },
        marginLeft: "10px"
    },
    card: {
        padding: "15px",
        boxShadow: "0px 1px 4px 0px #00000040",
        borderRadius: "7px"
    },
    earningsCard: {
        padding: "15px",
        boxShadow: "0px 1px 4px 0px #00000040",
        borderRadius: "7px",
        marginTop: "30px",
        minHeight: "274px",
        display: "flex"
    },
    modalCard: {
        padding: "20px 20px 30px 20px",
        boxShadow: "0px 1px 4px 0px #00000040",
        borderRadius: "7px",
        margin: "0px",
        width: "650px"
    },
    confirmModalCard: {
        padding: "15px",
        boxShadow: "0px 1px 4px 0px #00000040",
        borderRadius: "7px",
        margin: "0px",
        width: "600px",
        padding: "20px 20px 30px 20px",
    },
    modalCardDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "20px",
        color: "#1A405F",
        marginTop: "12px",
    },
    acceptModalCardDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        marginTop: "26px",
    },
    modalCardDescSpan: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "13px",
        color: "#1A405F",
        marginTop: "12px",
        marginLeft: "5px",
        marginRight: "5px"
    },
    cardHeader: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#1A405F"
    },
    cardHeaderModal: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#1A405F",
        marginRight: "20px"
    },
    cardDescBold: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "28px",
        color: "#1A405F",
        lineHeight: "28px",
        margin: "5px 0px 0px 10px",
        whiteSpace: "noWrap",
        [theme.breakpoints.down("xl")]: {
            fontFamily: "Josefin Sans",
            fontStyle: "normal",
            padding: "5px",
            fontSize: "20px",
        },
    },
    cardDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "20px",
        color: "#1A405F",
        lineHeight: "20px",
        margin: "5px 0px 0px 10px",
        whiteSpace: "noWrap",
    },
    cardDescMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "20px",
        color: "#1A405F",
        lineHeight: "20px",
        margin: "5px 0px 0px 0px",
        whiteSpace: "noWrap",
    },
    cardDescSmall: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        marginTop: "7px"
    },
    cardDescSmallEnd: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        marginTop: "7px",
        alignSelf: "flex-end"
    },
    cardDescSmallEndMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        marginTop: "15px",
        alignSelf: "flex-end"
    },
    divColumnFlex: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginTop: "15px",
        height: "60px"
    },
    divColumnFlex2: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginTop: "10px",
        height: "128px",
        paddingBottom: "10px",
        paddingTop: "10px"
    },
    divColumnFlex2Mobile: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginTop: "25px",
        height: "180px",
        paddingBottom: "20px"
    },
    tooltip: {
        fontFamily: "Josefin Sans",
        fontSize: "12px",
        lineHeight: "20px",
        fontWeight: 400,
        textAlign: "center",
        backgroundColor: "#323232",
        color: "white"
    },
    tooltip2: {
        fontFamily: "Josefin Sans",
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 400,
        textAlign: "center",
        backgroundColor: "#323232",
        color: "white"
    },
    cardDescPoints: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19px",
        lineHeight: "19px",
        color: "#1A405F",
        whiteSpace: "noWrap",
        margin: "0px 0px 0px 10px",
    },
    cardDescPointsMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19px",
        lineHeight: "19px",
        color: "#1A405F",
        whiteSpace: "noWrap",
        margin: "10px 0px 0px 10px",
        textAlign: "left"
    },
    tableRow: {
        height: "39px",
        background: "#FFFFFF !important"
    },
    tableRowOdd: {
        height: "39px",
        background: "#E8EBEF  !important"
    },
    tableHeaders: {
        border: "0.5px solid #1A405F",
        background: "#1A405F",
        color: "#FFFFFF",
        fontFamily: "Josefin Sans",
        fontSize: "17px",
        padding: "10px",
        textTransform: "uppercase",
    },
    tableCell: {
        textAlign: "center",
        border: "0.5px solid #1A405F",
        padding: "10px 20px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        // background: "#FFFFFF",
        color: "#1A405F",
    },
    indexes: {
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        color: "#1A405F"
    },
    indexesMobile: {
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        color: "#1A405F"
    },
    reportsTable: {
        width: "100% !important",
        borderCollapse: "collapse",
        padding: "0px 30px 10px 20px"
        // [theme.breakpoints.down("md")]: {
        //   tableLayout: "fixed",
        //   width: "100%",
        // },
    },
    icons: {
        marginBottom: "-3px",
        marginRight: "10px"
    },
    requestModal: {
        padding: "15px",
        overflow: "hidden",
        width: "90%",
        position: "absolute",
        top: "0% !important",
        left: "5% !important",
    },
    modalTextField: {
        width: "100%",
        background: "white",
        height: "30px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        border: "1px solid #1A405F",
        paddingLeft: "5px",
        borderRadius: "7px",
        marginTop: "5px"
    },
    adournedEnd: {
        paddingRight: "0px !important",
        margin: "0px !important",
        maxHeight: "none !important",
        height: "30px"
    },
    input: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        fontSize: "16px",
        '&::placeholder': {
            opacity: 0.5,
            fontFamily: "Josefin Sans",
            fontWeight: 400,
            color: "#1A405F",
        },
    },
    spanHover: {
        "&:hover": {
            cursor: "pointer ",
        },
    },
    infoIcon: {
        color: "#E2A400"
    },
    rewardsCardListStyling: {
        height: "275px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        justifyContent: "center",
        width: "420px",
        [theme.breakpoints.down("lg")]: {
            width: "400px"
        },
        [theme.breakpoints.down("1001")]: {
            height: "325px",
        },
    },
    rewardsCardListStylingMobile: {
        height: "auto",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "0px 5px !important",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        width: "100%",
        marginTop: "20px",
        marginBottom: "0px",
    },
    cardListImg: {
        width: "180px",
        height: "150px",
        objectFit: "contain",
        [theme.breakpoints.up("1200")]: {
            width: "100px",
            height: "100px"
        },
    },
    cardListTitleNoOverflow: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "20px",
        textTransform: "uppercase",
        marginTop: "10px",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    cardListDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "capitalize",
        marginTop: "10px",
        paddingLeft: "10px"
    },
    cardListPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "17px",
        marginTop: "10px",
        paddingLeft: "10px"
    },
    cardListPtsMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "17px",
        marginTop: "10px",
        paddingLeft: "10px",
        textAlign: "right"
    },
    rightArrow: {
        background: "transparent",
        border: 0,
        color: "#FECD2C",
        fontSize: "20px",
        position: "absolute",
        right: "41px",
        [theme.breakpoints.up("xl")]: {
            right: "8px",
        },
        [theme.breakpoints.down("1380")]: {
            right: "33px",
        },
        [theme.breakpoints.down("1300")]: {
            right: "22px",
        },
        [theme.breakpoints.down("sm")]: {
            right: "2px",
        },
    },
    leftArrow: {
        background: "transparent",
        border: 0,
        color: "#FECD2C",
        fontSize: "20px",
        position: "absolute",
        left: "388px",
        [theme.breakpoints.up("xl")]: {
            left: "484px",
        },
        [theme.breakpoints.down('1400')]: {
            left: "366px"
        },
        [theme.breakpoints.down('1300')]: {
            left: "333px"
        },
        [theme.breakpoints.down("sm")]: {
            left: "2px",
        },
    },
    errText: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        color: "red"
    },
    gridItemMarginTop: {
        marginTop: "15px"
    },
    divHover: {
        textAlign: "center",
        borderBottom: "1px solid #56CA93",
        "&:hover ": {
            cursor: "pointer"
        }
    },
    divBorder: {
        borderBottom: "1px solid #1A405F",
        marginTop: "20px",
        marginBottom: "15px"
    },
    usePointsText: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1A405F",
    },
    ptsTextField: {
        width: "30%",
        background: "white",
        height: "30px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        border: "1px solid #1A405F",
        paddingLeft: "5px",
        borderRadius: "7px",
        marginTop: "-7px",
        [theme.breakpoints.down('1300')]: {
            width: "28%",
        },
    },
    updateButton: {
        width: "80px",
        borderRadius: "7px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        background: "#E2A400",
        height: "30px",
        "&:hover, &:focus": {
            backgroundColor: "#E2A400",
        },
        marginTop: "-7px",
        padding: "15px 8px"
    },
    noPaddingGridItem: {
        marginTop: "10px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "0px!important"
    }
});

export default activityStyle;