import React, { useEffect } from 'react';
import { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment"

// mui
import { Button, Hidden, MenuItem, Select, Typography } from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from 'components/Card/Card';
// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";



export function SelectRoom(props) {
    const {
        classes,
        history,
        data,
        user
    } = props;

    const capitalizeStr = (str) => {
        if(data){
            let lowerCaseStr = str.toLowerCase().split(/[\s/ ]+/)
            let capitalizeStr = lowerCaseStr.map(word => word.charAt(0).toUpperCase() + word.slice(1))
            const newStr = capitalizeStr.join(" ")

            return newStr

        }
    }

    return (
        <div >
            <Card className={classes.detailCard} style={{marginTop:"0px"}}>
                <GridContainer>
                    <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h2" className={classes.title}>
                            Select Your Room
                        </Typography>
                    </GridItem>

                    <GridItem xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                            {data.HotelRooms.map((d, index) => (
                                <GridItem xs={4} >
                                    <div className={classes.gridBorder}>
                                        <img
                                            style={{ width: "100%" }}
                                            className={classes.cardListImg}
                                            src={data.ImageURL}
                                            alt="img here"
                                        />
                                        <Typography className={classes.travelDetailNightlyDesc} >
                                            {capitalizeStr(d.RoomDescription.Text[0])}
                                        </Typography>

                                        {d.Amenities && d.Amenities.Amenity.map((a, index) => (
                                            index < 4 ?
                                                <Typography className={classes.travelDetailNightlyDesc} style={{ fontWeight: 400 }}>
                                                    • {a.Description}
                                                </Typography>
                                                : null
                                        ))}

                                        <Button className={classes.selectRoomButton}>
                                            <Add style={{ marginRight: "10px" }} /> Select Room
                                        </Button>
                                    </div>
                                </GridItem>
                            ))}
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </Card>
        </div>
    )
}

function mapStateToProps(state, location) {
    return {
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(SelectRoom));