
const cardFieldsStyle = {
  root: {
    padding: "10px",
  },

  label: {
    fontWeight: 800
  },

  value: {
      padding: "10px"
  }
};

export default cardFieldsStyle;
