import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import form from './form';
import auth from './auth';
import dialog from './dialog';
import system from './system';
import navigation from './navigation';
import windowReducer from './windowReducer'
import termsAndCondtionReducer from './termsAndCondtionReducer';
import search from "./search/searchReducer";
import cart from "./cart"
import rewards from './rewardsList';
import wishlist from './wishlistReducer';
import dropdown from './dropdown'
import notifications from './notificationsReducer'

export default (history) => combineReducers({
    router: connectRouter(history),
    auth,
    dialog,
    system,
    navigation,
    search,
    form,
    window: windowReducer,
    terms: termsAndCondtionReducer,
    cart,
    rewards,
    wishlist,
    dropdown,
    notifications
});