const initState = {
    globalCalulcatorOpen: false,
    globalArrowOpen: false,
  };
  
  export default function reduce(state = initState, action) {
    switch (action.type) {
      case "SET_GLOBAL_CALCULATOR":
        return {
          ...state,
          globalArrowOpen: false,
          globalCalulcatorOpen: action.boolean,
        };
      case "SET_GLOBAL_ARROW":
        return {
          ...state,
          globalCalulcatorOpen: false,
          globalArrowOpen: action.boolean,
        };
      default:
        return state;
    }
  }
  