/*eslint-disable*/
// routes
import ISOMerchantRegisterPage from 'views/Pages/isoMerchantSignUp/RegisterPage';
import ISOMerchantPasswordPage from 'views/Pages/isoMerchantSignUp/PasswordPage';
import MerchantRegisterPage from 'views/Pages/merchantSignUp/RegisterPage';
import MerchantPasswordPage from 'views/Pages/merchantSignUp/PasswordPage';
import AdminRegisterPage from 'views/Pages/adminsignup/RegisterPage';
import AdminPasswordPage from 'views/Pages/adminsignup/PasswordPage';
import Calculator from 'views/Pages/calculator/Calculator.js';
import RegisterPage from 'views/Pages/RegisterPage';
import LoginBox from 'views/Pages/LoginBox';
import ForgetPassword from 'views/Pages/ForgetPassword';
import IsoPublicCalculator from 'views/Pages/isopubliccalculator/Calculator'
import IsoAdminSignUp from 'views/Pages/isoSignUp/RegisterPage'
import IsoAdminPasswordPage from 'views/Pages/isoSignUp/PasswordPage'
import LoginPage from "views/Pages/isoLogin/LoginPage"
import Referral from 'views/Pages/referral/Referral';
var authRoutes = [
  {
    path: '/merchant-register/:id/:code',
    name: 'Merchant Register',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: MerchantPasswordPage,
    layout: '/auth'
  },
  {
    path: '/merchant-register/:id',
    name: 'Merchant Register',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: MerchantRegisterPage,
    layout: '/auth'
  },
  {
    path: '/admin-register/:id/:code',
    name: 'Admin Register',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: AdminPasswordPage,
    layout: '/auth'
  },
  {
    path: '/admin-register/:id',
    name: 'Admin Register',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: AdminRegisterPage,
    layout: '/auth'
  },
  {
    path: '/referral/indipop',
    name: 'Referral',
    component: Referral,
    layout: '/auth'
  },
  {
    path: '/iso-register/:id/:code',
    name: 'IsoAdmin Register',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: IsoAdminPasswordPage,
    layout: '/auth'
  },
  {
    path: '/iso-register/:id',
    name: 'IsoAdmin Register',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: IsoAdminSignUp,
    layout: '/auth'
  },
  {
    path: '/iso-merchant-register/:id/:code',
    name: 'Merchant Register',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: ISOMerchantPasswordPage,
    layout: '/auth'
  },
  {
    path: '/iso-merchant-register/:id',
    name: 'Merchant Register',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: ISOMerchantRegisterPage,
    layout: '/auth'
  },
  {
    path: '/:iso/login',
    name: 'Login Page',
    component: LoginPage,
    layout: '/auth'
  },
  {
    path: '/login-page',
    name: 'Login Page',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: LoginBox,
    layout: '/auth'
  },
  {
    path: '/register-page',
    name: 'Register Page',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: RegisterPage,
    layout: '/auth'
  },
  {
    path: '/forget-password',
    name: 'Forget Password',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: ForgetPassword,
    layout: '/auth',
  },
  {
    path: '/calculator',
    name: 'Calculator',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: Calculator,
    layout: '/auth',
  },
  {
    path: '/:merchant/iso-calculator',
    name: 'Calculator',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: IsoPublicCalculator,
    layout: '/auth',
  },
];
export default authRoutes;
