const initState = {
    notifications: [],
    initDataRetrieved: false
}

export default function (state = initState, action) {
    switch (action.type) {
        case "SET_NOTIFICATIONS":
            var toReturn = { ...state }
            toReturn.notifications = action.notifications
            toReturn.initDataRetrieved = true
            return toReturn
        default:
            return state
    }
}