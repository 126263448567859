import React, { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Hidden from "@material-ui/core/Hidden";
import { Card, Typography } from '@material-ui/core';

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/travelStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import carRental from 'assets/img/tuzo/carRental.png'
import tuzoHotel from 'assets/img/tuzo/tuzoHotel.png'


export function EmptyData(props) {
    const { classes, travelButtonFilter } = props

    const displayImg = travelButtonFilter === "Hotels" ? tuzoHotel : carRental

    return (
        <GridContainer>
        <Card className={classes.travelCardStyling}>
            <GridContainer>
                <GridItem xs={4}>
                    <img src={displayImg} className={classes.emptyMerch} style={{ width: "70%" }} />
                </GridItem>

                <GridItem xs={8}
                    style={{ marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "left", flexDirection: "column" }}>
                    <Typography className={classes.invMerchHeader}>
                        No Results Available
                    </Typography>

                    <Typography className={classes.invMerchDesc}>
                        Please update the filter and try again.
                    </Typography>
                </GridItem>
            </GridContainer>
        </Card>
        </GridContainer>
    )
}


function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(EmptyData));