import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";

// core components
import { Button, FormControlLabel, Radio, TextField, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/assignRewardStyle';
import withStyles from '@material-ui/core/styles/withStyles';

export function UserInfo(props) {
    const {
        classes,
        data,
        history,
        numberFormatter,
        rewardData,
        redeemPoints,
        setRedeemPoints,
        option,
        setOption,
        handleOption,
        updatePts,
        updatePartialPts
    } = props

    const [stop, setStop] = useState(false)

    const formatPhoneNumber = (phoneNumber) => {
        var cleaned = ("" + phoneNumber).replace(/\D/g, "");
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return "";
    }

    useEffect(() => {
        if (option !== "Partial") {
            setRedeemPoints("")
        }
    }, [option])

    return (
        <div>
            <GridContainer style={{ marginTop: "15px" }}>
                <GridItem xs={4}>
                    <GridContainer>
                        <GridItem xs={4}>
                            <Typography className={classes.userDetailDesc}>
                                User:
                            </Typography>
                        </GridItem>
                        <GridItem xs={8}>
                            <Typography
                                onClick={() => history.push(`/admin/user-profile/${data.uid}`)}
                                className={classes.userdetailDescUnderline}>
                                {data.firstName + " " + data.lastName}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </GridItem>

                <GridItem xs={4}>
                    <GridContainer>
                        <GridItem xs={4}>
                            <Typography className={classes.userDetailDesc}>
                                Phone Number:
                            </Typography>
                        </GridItem>
                        <GridItem xs={8}>
                            <Typography className={classes.userDetailDesc}>
                                {data.cellPhone ? formatPhoneNumber(data.cellPhone) : "N/A"}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </GridItem>

                <GridItem xs={4} >
                    <GridContainer>
                        <GridItem xs={4}>
                            <Typography className={classes.userDetailDesc}>
                                Email:
                            </Typography>
                        </GridItem>
                        <GridItem xs={8}>
                            <Typography
                                // onClick={() => history.push(`/admin/user-profile/${data.uid}`)}
                                className={classes.userdetailDescUnderline}>
                                {data.email}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </GridItem>

                <GridItem xs={4} style={{ marginTop: "30px" }}>
                    <GridContainer>
                        <GridItem xs={4}>
                            <Typography className={classes.userDetailDesc}>
                                Points:
                            </Typography>
                        </GridItem>
                        <GridItem xs={8}>
                            <Typography
                                // onClick={() => history.push(`/admin/user-profile/${data.uid}`)}
                                className={classes.userDetailDesc}>
                                {data.approvedPts ? numberFormatter.format(data.approvedPts) : 0} points
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </GridItem>

                <GridItem xs={8} style={{ marginTop: "30px" }}>
                    <GridContainer>
                        <GridItem xs={3} className={classes.gridItemRadio}>
                            <FormControlLabel
                                classes={{
                                    label: classes.checkboxesLabel
                                }}
                                control={
                                    <Radio
                                        checked={option === "Only"}
                                        onClick={(e) => {
                                            handleOption(e.target.value)
                                        }}
                                        value="Only"
                                        className={classes.radioButton}
                                    />
                                }
                            />

                            <Typography className={classes.userDetailDescMarginTop}>
                                Redeem with only points
                            </Typography>
                        </GridItem>

                        <GridItem xs={6} className={classes.gridItemRadio}>
                            <FormControlLabel
                                classes={{
                                    label: classes.checkboxesLabel
                                }}
                                control={
                                    <Radio
                                        checked={option === "Partial"}
                                        onClick={(e) => {
                                            handleOption(e.target.value)
                                            setStop(false)
                                        }}
                                        value="Partial"
                                        className={classes.radioButton}
                                    />
                                }
                            />

                            <Typography className={classes.userDetailDescMarginTop}>
                                Redeem
                            </Typography>

                            <TextField
                                disabled={option !== "Partial" || !rewardData || stop ? true : false}
                                placeholder=""
                                type="number"
                                className={classes.ptsTextField}
                                value={redeemPoints}
                                onChange={(e) => {
                                    let ptsToUpdate = 0
                                    if (Number(e.target.value) > (rewardData.salePrice * 100) && (data.approvedPts && (data.approvedPts) > (rewardData.salePrice * 100))) {
                                        setRedeemPoints(rewardData.salePrice * 100)
                                        ptsToUpdate = rewardData.salePrice * 100
                                        setStop(true)
                                    }
                                    else if (Number(e.target.value) > data.tuzoPoints && (data.approvedPts && data.approvedPts < (rewardData.salePrice * 100))) {
                                        setRedeemPoints(Number(data.approvedPts))
                                        ptsToUpdate = Number(data.approvedPts)
                                        setStop(true)
                                    }
                                    else {
                                        setRedeemPoints(e.target.value)
                                        ptsToUpdate = e.target.value
                                    }
                                    updatePartialPts(Number(data.approvedPts), ptsToUpdate)
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        adornedEnd: classes.adournedEnd,
                                        input: classes.input
                                    },
                                    inputProps: {
                                        min: 0,
                                        max: rewardData.approvedPts ? rewardData.approvedPts : 0
                                    }
                                }}
                            />

                            <Typography className={classes.userDetailDescMarginTop} style={{ marginLeft: "10px" }}>
                                points and charge the rest
                            </Typography>
                        </GridItem>

                        <GridItem xs={3} className={classes.gridItemRadio}>
                            <FormControlLabel
                                classes={{
                                    label: classes.checkboxesLabel
                                }}
                                control={
                                    <Radio
                                        checked={option === "None"}
                                        onClick={(e) => {
                                            handleOption(e.target.value)
                                        }}
                                        value="None"
                                        className={classes.radioButton}
                                    />
                                }
                            />

                            <Typography className={classes.userDetailDescMarginTop}>
                                Charge ${rewardData.salePrice}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </GridItem>

            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(UserInfo);