/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment"
//mui core
import { Button, Card, Checkbox, IconButton, InputAdornment, MenuItem, Select, Snackbar, SvgIcon, Switch, TextField, Tooltip, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

//styling
import withStyles from '@material-ui/core/styles/withStyles';
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";

//icons
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { ReactComponent as Lock } from "assets/img/tuzo/icons/LockIcon.svg";
import { ReactComponent as Unlock } from "assets/img/tuzo/icons/UnlockIcon.svg";
import { ReactComponent as UnlockWhite } from "assets/img/tuzo/icons/UnlockIconWhite.svg";


const intervalArr = [
    "60",
    "90",
    "120",
    "180"
]

export function PointsSetup(props) {
    const {
        classes,
        globalEdit,
        userData,
        ptsSchedule,
        setPtsSchedule,
        autoApprove,
        setAutoApprove,
        lockSchedule,
        setLockSchedule
    } = props

    const calculateMonth = () => {
        let currentDate = moment("01-01-24")
        let futureDate = moment(currentDate).add(Number(ptsSchedule), 'Days')
        return futureDate.format("MMMM")
    }


    return (
        <div>
            <GridContainer>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                    <Card className={classes.profileCardStyling}>
                        <GridContainer>
                            <GridItem xs={12} style={{ display: "flex" }}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Points Setup
                                </Typography>

                                {!globalEdit ?
                                    <Tooltip
                                        id="tooltip-top"
                                        title={<>From what you’ve selected, you are delaying 1 month’s points by {ptsSchedule} days.
                                            {"\n\n"}
                                            For example, all of January’s points would be released on {calculateMonth()}.
                                            {"\n\n"}
                                            <Unlock /> Unlocked means ISOs can set their own Points Release Schedule.</>}
                                        placement="top"
                                        classes={{ tooltip: classes.tooltipPts }}
                                        style={{ fontSize: "13px !important" }}
                                    >
                                        <IconButton>
                                            <IoIosInformationCircleOutline className={classes.infoIcon} style={{ fontSize: "24px" }} />
                                        </IconButton>
                                    </Tooltip>
                                    : null
                                }
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescWeight500}>
                                            Points Release Schedule
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        {globalEdit ?
                                            <div style={{ display: "flex" }}>
                                                <Select
                                                    style={{ width: "80%", height: "30px", margin: "10px 0px 0px 0px" }}
                                                    className={classes.selectMenuSort}
                                                    value={ptsSchedule}
                                                    disableUnderline
                                                    classes={{
                                                        root: classes.selectText,
                                                    }}
                                                    onChange={(e) => {
                                                        setPtsSchedule(e.target.value)
                                                    }}
                                                >
                                                    {intervalArr.map((o, i) => {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={o}
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                }}
                                                            >
                                                                {o + " Days"}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>

                                                <IconButton
                                                    disabled={globalEdit ? false : true}
                                                    onClick={() => setLockSchedule(!lockSchedule)}
                                                >
                                                    {lockSchedule ?
                                                        <>
                                                            <Lock />
                                                        </>
                                                        :
                                                        <>
                                                            <Unlock />
                                                        </>
                                                    }
                                                </IconButton>

                                                {/* <Switch
                                                    disabled={globalEdit ? false : true}
                                                    className={classes.changeSchedule}
                                                    checked={lockSchedule}
                                                    icon={<Unlock/>}
                                                    checkedIcon={<Lock/>}
                                                    onChange={() => setLockSchedule(!lockSchedule)}
                                                /> */}
                                            </div>
                                            :
                                            <Typography variant="h2" className={classes.cardDesc}>
                                                {ptsSchedule ? ptsSchedule + " Days" : "N/A"}
                                            </Typography>
                                        }
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescWeight500}>
                                            Auto Approve Points
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        {globalEdit ?
                                            <>
                                                <Switch
                                                    disabled={globalEdit ? false : true}
                                                    className={classes.autoApprove}
                                                    checked={autoApprove}
                                                    onChange={() => setAutoApprove(!autoApprove)}
                                                    style={{ color: "#FF0000" }}
                                                />
                                            </>
                                            :
                                            <Typography variant="h2" className={classes.cardDesc}>
                                                {autoApprove ? "Enabled" : "Disabled"}
                                            </Typography>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(PointsSetup));