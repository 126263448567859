import React, { useState } from 'react';
import moment from "moment"
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Datetime from "react-datetime";
import { CSVLink } from "react-csv";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import { Card, Dialog, DialogActions, DialogContent, IconButton, InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core';

// @material-ui/icons
import { ReactComponent as CSVIcon } from "assets/img/tuzo/icons/CSVIcon.svg";
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai';

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { BsCheckCircleFill } from 'react-icons/bs';
import { BiSolidLeftArrow } from 'react-icons/bi';




export function ReportsFilter(props) {
    const {
        classes,
        history,
        sortValue,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        reportSelections,
        headers,
        csvData
    } = props;


    const [openModal, setOpenModal] = useState(false)

    const disablePastFrom = (current) => {
        return current.isAfter(fromDate)
    }

    const reset = () => {
        setFromDate(moment().format("MMMM DD YYYY"))
        setToDate(moment().format("MMMM DD YYYY"))
        setOpenModal(false)
    }
    return (
        <div>
            <GridContainer>
                <GridItem xs={6} >
                    <div style={{ display: "flex", }}>
                        <Typography variant="h2" className={classes.filterDesc} >
                            Switch Report
                        </Typography>
                        <Select
                            className={classes.selectMenuSortReportsPage}
                            value={sortValue}
                            disableUnderline
                            classes={{
                                root: classes.selectText,
                            }}
                            onChange={(e) => {
                                history.push(e.target.value.link)
                            }}
                            displayEmpty
                            renderValue={
                                sortValue !== "" ? undefined : () => "Deposit History"
                            }
                        >
                            {reportSelections.map((o, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={o}
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        {o.name}

                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </GridItem>


                <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        className={classes.csvButton}
                        style={{marginTop:"3px"}}
                    >
                        <CSVLink 
                        style={{ color: "white" }}
                        headers={headers} 
                        data={
                            csvData.map(d => ({
                                date: d.date,
                                numBatches: d.numBatches,
                                total: d.total,
                                fees: d.fees,
                                referenceNum: d.referenceNum,
                                pts: d.pts
                            }))
                        } 
                        filename={`${fromDate}-${toDate}_deposit_history`}
                        
                        >
                            <CSVIcon style={{ marginRight: "10px" }} /> Export CSV

                        </CSVLink>

                    </Button>
                </GridItem>

                <GridItem xs={6}>
                    <div style={{ display: "flex",  margin:"10px 0px" }}>
                        <Typography className={classes.dateDeposit}>
                            {fromDate} - {toDate}
                        </Typography>

                        <Button
                            onClick={() => setOpenModal(true)}
                            style={{ background: "#56CA93", width: "192px", marginLeft: "10px" }}
                            className={classes.csvButton}>
                            <CalendarIcon style={{ marginRight: "10px" }} /> Change Dates
                        </Button>
                    </div>

                </GridItem>
            </GridContainer>

            <Dialog
                className={classes.changeDateModal}
                open={openModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // onClose={() => setOpenModal(false)}
            >
                <DialogContent style={{ overflow: "visible", padding: "25px" }}>
                    <GridContainer>
                        <GridItem xs={6}>
                            <Typography variant="h2" className={classes.modalFilterDesc}>
                                From
                            </Typography>
                        </GridItem>
                        <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Datetime
                                className={classes.checkOverflow}
                                selected={fromDate}
                                dateFormat="MM/DD/YY"
                                onChange={(date, e) => {
                                    setFromDate(date.format("MMMM DD YYYY"));
                                }}
                                timeFormat={false}
                                closeOnSelect={true}
                                closeOnClickOutside
                                renderInput={(props, openCalendar, closeCalendar) => (
                                    <div onClick={openCalendar} >
                                        <TextField
                                            className={classes.datetime}
                                            value={fromDate}
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                endAdornment: (
                                                    <InputAdornment
                                                        className={classes.adournedEnd}
                                                        position="end"
                                                    >
                                                        <Button className={classes.datetimeButton}>
                                                            <CalendarIcon
                                                                className={classes.calendarIcon}
                                                                onClick={openCalendar}
                                                            />
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        </GridItem>

                        <GridItem xs={6} style={{ marginTop: "20px" }}>
                            <Typography variant="h2" className={classes.modalFilterDesc}>
                                To
                            </Typography>

                        </GridItem>
                        <GridItem xs={6} style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end" }}>
                            <Datetime
                                className={classes.checkOverflow}
                                selected={toDate}
                                dateFormat="MM/DD/YY"
                                onChange={(date, e) => {
                                    setToDate(date.format("MMMM DD YYYY"));
                                }}
                                isValidDate={disablePastFrom}
                                timeFormat={false}
                                closeOnSelect={true}
                                closeOnClickOutside
                                renderInput={(props, openCalendar, closeCalendar) => (
                                    <div onClick={openCalendar}>
                                        <TextField
                                            className={classes.datetime}
                                            value={toDate}
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                endAdornment: (
                                                    <InputAdornment
                                                        className={classes.adournedEnd}
                                                        position="end"
                                                    >
                                                        <Button className={classes.datetimeButton}>
                                                            <CalendarIcon
                                                                className={classes.calendarIcon}
                                                                onClick={openCalendar}
                                                            />
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        </GridItem>
                    </GridContainer>

                    <div style={{ display: "flex", justifyContent: 'space-evenly', marginBottom: "10px", marginTop: "20px" }}>
                        <Button
                            style={{ background: "#1A405F", width: "50%",  marginRight:"30px"}}
                            className={classes.csvButton}
                            onClick={() => setOpenModal(false)}
                        >
                            <BsCheckCircleFill style={{ marginRight: "5px", fontSize: "22px" }} />Save
                        </Button>
                        <Button
                            onClick={reset}
                            className={classes.csvButton}
                            style={{ background: "#56CA93", width: "50%" }}
                        >
                            <BiSolidLeftArrow style={{ marginRight: "5px", fontSize: "22px" }} /> Cancel
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    );
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ReportsFilter));
