import React, { useState, useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { removeFromWishlist } from "store/actions/wishlistAction";
import ReactPaginate from "react-paginate";
import NoWishlist from "./NoWishlist";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";

// mui core
import { CardContent, IconButton, Typography, Hidden } from "@material-ui/core";

// stylings
import Style from "assets/jss/material-dashboard-pro-react/views/wishlistStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import "views/Pages/reports/batch/components/pagination.css";

// icons
import { ReactComponent as Info } from "assets/img/tuzo/icons/Info.svg";
import { AiFillHeart } from "react-icons/ai";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIconYellow.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import Loader from "components/Loader/Loader";

export function Wishlist(props) {
  const {
    classes,
    history,
    userId,
    wishlist,
    wishlistLoaded,
    removeFromWishlist,
    user,
  } = props;

  const itemsPerPage = 5;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const numberFormatterDecimals = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (!wishlistLoaded) return <Loader />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div style={{ marginTop: "50px" }}>
          <GridContainer>
            <GridItem
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography variant="h2" className={classes.wishlistTitle}>
                Your Wish List
              </Typography>
            </GridItem>
          </GridContainer>

          <GridContainer>
            {wishlist.length > 0 ? (
              <GridItem xs={12}>
                <Card className={classes.outerCard}>
                  <GridContainer>
                    {wishlist.map((data, index) => (
                      <>
                        <GridItem
                          xs={12}
                          className={classes.gridSpace}
                          key={index}
                        >
                          <Card
                            className={classes.wishlistCardListStyling}
                            key={index}
                          >
                            <CardContent style={{ padding: "0px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    if (user.IsoAdminCompanyName) {
                                      history.push(
                                        `/iso-merchant/${user.IsoAdminCompanyName.replace(
                                          / /g,
                                          ""
                                        ).toLowerCase()}/${user.companyName
                                          .replace(/ /g, "")
                                          .toLowerCase()}/reward-detail/${
                                          data.rewardId
                                        }`,
                                        {
                                          rewardData: data,
                                        }
                                      );
                                    } else {
                                      history.push(
                                        `/merchant/reward-detail/${data.rewardId}`,
                                        {
                                          rewardData: data,
                                        }
                                      );
                                    }
                                  }}
                                >
                                  <Info />
                                </IconButton>

                                <IconButton
                                  onClick={() => {
                                    removeFromWishlist(data, userId, index);
                                  }}
                                  style={{ color: "#E2A400" }}
                                >
                                  <AiFillHeart />
                                </IconButton>
                              </div>

                              <div
                                onClick={() => {
                                  if (user.IsoAdminCompanyName) {
                                    history.push(
                                      `/iso-merchant/${user.IsoAdminCompanyName.replace(
                                        / /g,
                                        ""
                                      ).toLowerCase()}/${user.companyName
                                        .replace(/ /g, "")
                                        .toLowerCase()}/reward-detail/${
                                        data.rewardId
                                      }`,
                                      {
                                        rewardData: data,
                                      }
                                    );
                                  } else {
                                    history.push(
                                      `/merchant/reward-detail/${data.rewardId}`,
                                      {
                                        rewardData: data,
                                      }
                                    );
                                  }
                                }}
                                className={classes.divHover}
                              >
                                <img
                                  className={classes.cardListImg}
                                  src={data.mainImage}
                                  alt="img here"
                                />

                                <Typography
                                  variant="h2"
                                  className={classes.cardListTitleNoOverflow}
                                >
                                  {data.rewardName.length < 33 ? <br /> : null}
                                  {data.rewardName}
                                </Typography>
                              </div>

                              <div className={classes.divStyle}>
                                <div>
                                  <Typography
                                    variant="h2"
                                    className={classes.cardListDesc}
                                  >
                                    Price
                                  </Typography>
                                  <Typography
                                    variant="h2"
                                    className={classes.cardListPts}
                                  >
                                    $
                                    {numberFormatterDecimals.format(
                                      Number(data.salePrice)
                                    )}{" "}
                                    / {data.salePricePoints.toLocaleString()}{" "}
                                    points
                                  </Typography>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </GridItem>
                      </>
                    ))}
                  </GridContainer>
                </Card>
              </GridItem>
            ) : (
              <>
                <GridItem xs={12}>
                  <NoWishlist history={history} />
                </GridItem>
              </>
            )}
          </GridContainer>
          <GridContainer style={{ marginTop: "-20px" }}>
            <GridItem
              xs={8}
              style={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <h5 className={classes.indexes}>
                {index + 1} - {endIndex} of {Math.ceil(wishlist.length)}
              </h5>
            </GridItem>
            <GridItem xs={4}>
              <ReactPaginate
                forcePage={pageNumber}
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(wishlist.length / itemsPerPage)}
                nextLabel={
                  <ArrowRight
                    style={{
                      fontSize: 25,
                      fontWeight: "bold",
                      color: "white",
                      marginTop: "-2px",
                    }}
                  />
                }
                previousLabel={
                  <ArrowLeft
                    style={{
                      fontSize: 25,
                      fontWeight: "bold",
                      color: "white",
                      marginTop: "-2px",
                    }}
                  />
                }
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="h2" className={classes.wishlistTitle}>
              Your Wish List
            </Typography>
          </GridItem>
        </GridContainer>

        <GridContainer>
          {wishlist.length > 0 ? (
            <GridItem xs={12}>
              <Card className={classes.outerCard}>
                <GridContainer>
                  {wishlist.map((data, index) => (
                    <>
                      <GridItem
                        xs={4}
                        md={4}
                        xl={3}
                        className={classes.gridSpace}
                        key={index}
                      >
                        <Card
                          className={classes.wishlistCardListStyling}
                          key={index}
                        >
                          <CardContent style={{ padding: "0px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  if (user.IsoAdminCompanyName) {
                                    history.push(
                                      `/iso-merchant/${user.IsoAdminCompanyName.replace(
                                        / /g,
                                        ""
                                      ).toLowerCase()}/${user.companyName
                                        .replace(/ /g, "")
                                        .toLowerCase()}/reward-detail/${
                                        data.rewardId
                                      }`,
                                      {
                                        rewardData: data,
                                      }
                                    );
                                  } else {
                                    history.push(
                                      `/merchant/reward-detail/${data.rewardId}`,
                                      {
                                        rewardData: data,
                                      }
                                    );
                                  }
                                }}
                              >
                                <Info />
                              </IconButton>

                              <IconButton
                                onClick={() => {
                                  removeFromWishlist(data, userId, index);
                                }}
                                style={{ color: "#E2A400" }}
                              >
                                <AiFillHeart />
                              </IconButton>

                              {/* <IconButton
                                                                onClick={() => {
                                                                    // handleRemoveFromWishlist(index)
                                                                    removeFromWishlist(data, userId, index)
                                                                }}
                                                            >
                                                                <TrashIcon />
                                                            </IconButton> */}
                            </div>

                            <div
                              onClick={() => {
                                if (user.IsoAdminCompanyName) {
                                  history.push(
                                    `/iso-merchant/${user.IsoAdminCompanyName.replace(
                                      / /g,
                                      ""
                                    ).toLowerCase()}/${user.companyName
                                      .replace(/ /g, "")
                                      .toLowerCase()}/reward-detail/${
                                      data.rewardId
                                    }`,
                                    {
                                      rewardData: data,
                                    }
                                  );
                                } else {
                                  history.push(
                                    `/merchant/reward-detail/${data.rewardId}`,
                                    {
                                      rewardData: data,
                                    }
                                  );
                                }
                              }}
                              className={classes.divHover}
                            >
                              <img
                                className={classes.cardListImg}
                                src={data.mainImage}
                                alt="img here"
                              />

                              <Typography
                                variant="h2"
                                className={classes.cardListTitleNoOverflow}
                              >
                                {data.rewardName.length < 33 ? <br /> : null}
                                {data.rewardName}
                              </Typography>
                            </div>

                            <div className={classes.divStyle}>
                              <div>
                                <Typography
                                  variant="h2"
                                  className={classes.cardListDesc}
                                >
                                  Price
                                </Typography>
                                <Typography
                                  variant="h2"
                                  className={classes.cardListPts}
                                >
                                  $
                                  {numberFormatterDecimals.format(
                                    Number(data.salePrice)
                                  )}{" "}
                                  / {data.salePricePoints.toLocaleString()}{" "}
                                  points
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </GridItem>
                    </>
                  ))}
                </GridContainer>
              </Card>
            </GridItem>
          ) : (
            <>
              <GridItem xs={12}>
                <NoWishlist history={history} />
              </GridItem>
            </>
          )}
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userId: state.auth.user.uid,
    wishlist: state.wishlist.wishlist,
    wishlistLoaded: state.wishlist.initDataRetrieved,
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    removeFromWishlist: (data, uid, index) =>
      dispatch(removeFromWishlist(data, uid, index)),
  });
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(Wishlist)
);
