import {
    actionsForQueryDialog,
    actionsForQueryForm,
    actionsForEditorForm,
    actionsForNewForm,
} from 'store/actions/recordset';

import { actionsForDialog } from 'store/actions/dialog';
import { actionsForForm } from 'store/actions/form';
import { config } from 'store/reducers/recordsets/leads';

import actionsForRecordset from 'store/actions/recordsets/leads';

export default {
    recordset: actionsForRecordset,
    queryDialog: actionsForQueryDialog(actionsForRecordset, actionsForDialog('leads/query'), actionsForForm(config, 'leads/query')),
    queryForm: actionsForQueryForm(actionsForRecordset, actionsForDialog('leads/query'), actionsForForm(config, 'leads/query')),
    editorForm: actionsForEditorForm(actionsForRecordset, actionsForForm(config, 'leads/editor')),
    newForm: actionsForNewForm(actionsForRecordset, actionsForForm(config, 'leads/new')),
};