import { push } from "connected-react-router";
import { uris } from "variables/config";

import { useParams } from 'react-router';

function _open(title, url) {
    return {
        type: "NAVIGATION_OPEN",
        title,
        url
    }
}

function _openChild(title, url) {
    return {
        type: "NAVIGATION_OPEN_CHILD",
        title,
        url
    }
}

export function _close() {
    return {
        type: "NAVIGATION_CLOSE",
    }
}

function _addToStack(url) {
    return {
        type: "NAVIGATION_PAGESTACK",
        url
    }
}

export function open(title, url) {
    return (dispatch) => {
        dispatch(_open(title, url));
        dispatch(push(url));
    }
}

export function openChild(title, url) {
    return (dispatch) => {
        dispatch(_openChild(title, url));
        if (title === 'View Record') {
            window.open(url, "_blank");
        } else {
            dispatch(push(url));
        }
    }
}

export function close(location) {
    return (dispatch, getState) => {
        let initState = getState().navigation;
        if (initState.pageStack.editForm) {
            dispatch(push(initState.pageStack.url));
            dispatch(_close());
        } else {
            dispatch(_close());
            const state = getState().navigation;
            if (state && state.pageStack && state.pageStack.url) {
                dispatch(push(state.pageStack.url));
            } else {
                dispatch(push(uris.HOME));
            }
        }
    }
}

export function addToStack(id) {
    return (dispatch) => {
        dispatch(_addToStack(`/admin/customers/${id}?mode=READ_ONLY`));
    }
}

export default {
    open, openChild, close
}