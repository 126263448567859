import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import styles from "assets/jss/material-dashboard-pro-react/components/headingStyle.js";
import 'assets/css/material-dashboard-pro-react.css';


const useStyles = makeStyles(styles);

export default function Heading(props) {
  const { textAlign, category, title } = props;
  const classes = useStyles();
  const heading =
    classes.heading +
    " " +
    cx({
      [classes[textAlign + "TextAlign"]]: textAlign !== undefined
    });
console.log('titleeee', title[0])

  if (title !== undefined || category !== undefined) {
    return (
      <Hidden smDown implementation="css">
      <div
        className={heading}
        style={{ marginBottom: '0'}}
        >
        {title[0] === "Profile" ? (
          null
        ) : title !== undefined ? (
          <h3
            className={classes.title}
            style={{ fontFamily: "Abril Fatface", fontSize: "35px", fontWeight: 500, color: "rgba(0, 0, 0, 1)" }}
            >{title}</h3>
        ) : ( null )}
        {category !== undefined ? (
          <p className={classes.category}>{category}</p>
        ) : null}
      </div>
      </Hidden>
    );
  }
  return null;
}

Heading.propTypes = {
  title: PropTypes.node,
  category: PropTypes.node,
  textAlign: PropTypes.oneOf(["right", "left", "center"])
};
