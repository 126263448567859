/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from 'routes/authRoutes.js'


import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import register from "assets/img/register.jpeg";
import explore from "assets/img/card-1.jpeg";
import login from "assets/img/login.jpeg";
import lock from "assets/img/lock.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import pricing from "assets/img/bg-pricing.jpeg";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LoginPageBackground from "assets/img/tuzo/loginBackground.png";
import TuzoFullLogo from "assets/img/tuzo/LogoGlow.png";
import { db } from "firebase/client";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const { ...rest } = props;

  const [logo, setLogo] = useState(TuzoFullLogo)
  const [iso, setIso] = useState(false)
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() { };
  });

  useEffect(() => {
    let url = window.location.pathname.split('/')
    if(url.includes("login") && url.length === 4){
      setIso(true)
    }
  },[])

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBgImage = () => {
    if (window.location.pathname.indexOf("/auth/login-page") !== -1) {
      return LoginPageBackground;
    }
    if (window.location.pathname.indexOf("/auth/forget-password") !== -1) {
      return LoginPageBackground;
    }
    if (window.location.pathname.indexOf('/auth/merchant-register') !== -1) {
      return LoginPageBackground;
    }
    if (window.location.pathname.indexOf('/auth/admin-register') !== -1) {
      return LoginPageBackground;
    }
    if (window.location.pathname.indexOf('/auth/iso-register') !== -1) {
      return LoginPageBackground;
    }
    // if (window.location.pathname.indexOf('/auth/iso-merchant-register') !== -1) {
    //   return LoginPageBackground;
    // }
    if (window.location.pathname.indexOf("/auth/register-page") !== -1) {
      return LoginPageBackground;
    } else if (window.location.pathname.indexOf("/auth/explorer-page") !== -1) {
      return explore;
    } else if (window.location.pathname.indexOf("/auth/pricing-page") !== -1) {
      return pricing;
    } else if (
      window.location.pathname.indexOf("/auth/lock-screen-page") !== -1
    ) {
      return lock;
    } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
      return error;
    }
  };


  // const getActiveRoute = routes => {
  //   let activeRoute = "Default Brand Text";
  //   for (let i = 0; i < routes.length; i++) {
  //     if (routes[i].collapse) {
  //       let collapseActiveRoute = getActiveRoute(routes[i].views);
  //       if (collapseActiveRoute !== activeRoute) {
  //         return collapseActiveRoute;
  //       }
  //     } else {
  //       if (
  //         window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
  //       ) {
  //         return routes[i].name;
  //       }
  //     }
  //   }
  //   return activeRoute;
  // };

  // console.log('checking innerWidth', innerWidth, innerHeight)


  return (
    <div style={{ overflow: "hidden" }}>
      <Hidden mdUp implementation='css'>
        <div className={classes.wrapper} ref={wrapper}>
          <div
            className={!window.location.pathname.includes("calculator") ? classes.fullPage : classes.fullPage2}
            style={{ backgroundImage: "url(" + getBgImage() + ")" }}
          >
            {!window.location.pathname.includes("calculator") 
            && !window.location.pathname.includes("merchant-register") 
            && !window.location.pathname.includes("admin-register") 
            && !iso && !window.location.pathname.includes("referral")?
              <div className={classes.logoAndText}>
                <div style={{ background: "radial-gradient(22.45% 45.17% at 27.3% 55.94%, rgba(255, 255, 255, 0.49) 0%, rgba(232, 168, 5, 0) 100%)" }}>
                  <img
                    className={window.location.pathname == "/auth/register-page"  ?
                      classes.logoImgMobileRegister
                      : classes.logoImgMobile
                    }
                    src={logo} />
                </div>
              </div>
              :
              null
            }
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/auth" to="/auth/login-page" />
            </Switch>
          </div>
        </div>
      </Hidden>

      <Hidden smDown implementation='css'>
        <div className={classes.wrapper} ref={wrapper}>
          <div
            className={!window.location.pathname.includes("calculator") ? classes.fullPage : classes.fullPage2}
            style={{ backgroundImage: "url(" + getBgImage() + ")" }}
          >
            {!window.location.pathname.includes("calculator") && !window.location.pathname.includes("iso-merchant-register") && !iso && window.innerWidth > 800 
            && !window.location.pathname.includes("referral") ?
              <div className={classes.logoAndText}>
                <div style={{ background: "radial-gradient(22.45% 45.17% at 27.3% 55.94%, rgba(255, 255, 255, 0.49) 0%, rgba(232, 168, 5, 0) 100%)" }}>
                  <img className={classes.logoImg} src={logo} />
                </div>
              </div>
              :
              null
            }

            <Switch>
              {getRoutes(routes)}
              <Redirect from="/auth" to="/auth/login-page" />
            </Switch>
          </div>
        </div>
      </Hidden>

    </div>
  );
}