import tuzoBg from "assets/img/tuzo/POS.png"
import { boxShadow } from "assets/jss/material-dashboard-pro-react";

const travelStyle = (theme) => ({
    rewardsBannerTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "24px",
        // textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "24px"
    },
    rewardsBannerDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        // textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "20px"
    },
    rewardsBannerButton: {
        width: "200px",
        borderRadius: "5px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "16px",
        background: "#E2A400",
        height: "42px",
        padding:"12px 25px",
        "&:hover, &:focus": {
            backgroundColor: "#E2A400",
        },
        [theme.breakpoints.down("lg")]: {
            fontFamily: "Josefin Sans",
            fontStyle: "normal",
            fontSize: "16px",
            width: "180px"
        },
    },
    updateButton: {
        border: "1px solid #1A405F",
        width: "100%",
        borderRadius: "7px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "17px",
        background: "#FFFFFF",
        height: "42px",
        "&:hover": {
            backgroundColor: "#06667C",
            color: "#FFFFFF"
        },
        "&:focus": {
            backgroundColor: "#FFFFFF",
            color: "#1A405F"
        },
        "&:hover, &:focus": {
            backgroundColor: "#06667C",
            color: "#FFFFFF"
        },
        marginRight: "15px"
    },
    travelFiltersButton: {
        width: "175px",
        borderRadius: "7px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "17px",
        background: "#06667C",
        height: "42px",
        "&:hover, &:focus": {
            backgroundColor: "#06667C",
        },
        marginRight: "15px",
        padding:"12px 25px"
    },
    travelCardStyling: {
        borderRadius: "7px",
        boxShadow: "0px 1px 4px 0px #00000040",
        padding: "15px",
        width: "100%"
    },
    input: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        '&::placeholder': {
            opacity: 1,
            fontFamily: "Josefin Sans",
            fontWeight: 400,
            color: "#1A405F",
        },
    },
    inputCalendar: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        '&::placeholder': {
            opacity: 1,
            fontFamily: "Josefin Sans",
            fontWeight: 400,
            color: "#1A405F",
        },
        padding:"18.5px 10px"
    },
    searchBar: {
        width: "300px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        textTransform: "capitalize",
        height: "42px",
        background: "white",
        borderBottom: "1px solid #56CA93",
    },
    datetime: {
        marginLeft: "10px",
        width: "100%",
        height: "30px !important",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        textTransform: "capitalize",
        borderRadius: "7px",
        background: "white",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#56CA93',
            },
            '&:hover fieldset': {
                borderColor: '#56CA93',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#56CA93',
            },
        }
    },
    adournedEnd: {
        paddingRight: "0px !important",
        margin: "0px !important",
        maxHeight: "none !important",
        height: "30px"
    },
    datetimeButton: {
        background: "#56CA93",
        display: "block",
        margin: "0px !important",
        padding: "7px 11px !important",
        height: "30px",
        color: "white",
        width: "40px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 500,
        "&:hover, &:focus": {
            background: "#56CA93",
        },
    },
    travelFilterDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "17px",
        textTransform: "capitalize",
        color: "#004253",
        lineHeight: "17px",
        marginLeft: "30px"
        
    },
    selectMenuSortTravelPage: {
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        background: "#FFFFFF",
        border: "0.5px solid #56CA93",
        borderRadius: "7px",
        width: "180px",
        height: "30px",
        textAlign: "center",
        fontWeight: "700",
        margin: "0px 0px 0px 20px",
        paddingTop:"5px"
    },
    selectMenuHotelInfo: {
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        background: "#FFFFFF",
        border: "2px solid #56CA93",
        borderRadius: "7px",
        width: "60%",
        height: "42px",
        textAlign: "center",
        fontWeight: "700",
        margin: "0px 0px 0px 0px",
    },
    selectText: {
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F"
    },
    selectMenuItem: {
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        color: "#1A405F"
    },
    rightArrow: {
        background: "transparent",
        border: 0,
        color: "#FECD2C",
        fontSize: "20px",
        position: "absolute",
        right: window.innerWidth <= 1280 && window.innerWidth > 1024 ? "80px" : "54px",
        [theme.breakpoints.up("xl")]: {
            right: "70px",
        },
        [theme.breakpoints.down("md")]: {
            right: "20px",
        },
        [theme.breakpoints.down("sm")]: {
            right: "20px",
        },
    },
    leftArrow: {
        background: "transparent",
        border: 0,
        color: "#FECD2C",
        fontSize: "20px",
        position: "absolute",
        left: window.innerWidth <= 1280 && window.innerWidth > 1024 ? "433px" : "475px",
        [theme.breakpoints.up("xl")]: {
            left: "635px",
        },
        [theme.breakpoints.down("md")]: {
            left: "360px",
        },
        [theme.breakpoints.down("sm")]: {
            left: "20px",
        },
    },
    icon: {
        marginRight: "10px !important",
        marginTop: "-5px !important",
        width: "24px !important",
        height: "24px !important"
    },
    divBackground: {
        padding: "10px",
        width: "98.4vw",
        marginLeft: "-17px",
        height: "45vh",
        backgroundColor: "#06667C",
        borderRadius: "7px",
        display: "flex",
        alignItems: "center",
        backgroundPhoto: "no-repeat",
        backgroundSize: "cover",
        background: "linear-gradient(rgba(0,0,0,.75), rgba(0,0,0,.75)), url(" + tuzoBg + ")",
    },
    hotelCardTitle: {
        fontSize: "19px",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        lineHeight: "19px",
        color: "#1A405F",
    },
    hotelDataCard: {
        borderRadius: "7px",
        boxShadow: "0px 1px 4px 0px #00000040",
        padding: "15px",
        width: "100%"
    },
    hotelDataCardDesc: {
        lineHeight: "16px",
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        color: "#1A405F",
        marginTop: "15px"
    },
    hotelDataCardNightlyDesc: {
        lineHeight: "14px",
        fontSize: "14px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        color: "#1A405F",
        // marginTop:"15px"
    },
    hotelDataCardNightlyDescPrice: {
        lineHeight: "20px",
        fontSize: "20px",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        color: "#1A405F",
        marginLeft: "15px"
    },
    hotelDataCardGridItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    hotelDataCardGridItemMarginTop: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "10px"
    },
    addToCart: {
        fontFamily: "Josefin Sans",
        fontSize: "17px",
        fontWeight: 500,
        lineHeight: "17px",
        color: "#FFFFFF",
        borderRadius: "7px",
        textTransform: "capitalize",
        background: "#1A405F",
        boxShadow: "none",
        "&:hover": {
            background: "#06667C"
        },
        height: "35px"
    },
    gridItemFlexCenter: {
        display: "flex",
        alignItems: "center"
    },
    hotelImg: {
        width: "200px",
        height: "130px",
        objectFit: "contain"
    },
    emptyMerch: {
        objectFit: "contain",
        marginTop: "10px",
        marginLeft: "10px"
      },
      invMerchHeader: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "22px",
        color: "#1A405F"
      },
      invMerchDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "18px",
        color: "#1A405F",
        marginTop: "20px"
      },

})

export default travelStyle;