import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import ItemInfo from "./components/ItemInfo";
// styling
import Style from "assets/jss/material-dashboard-pro-react/views/cartStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import tuzoLogo from "assets/img/tuzo/Tuzo-square.png";

// core components
import { Button, Hidden, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Summary from "./components/Summary";
import { db } from "firebase/client";

// icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import Loader from "components/Loader/Loader";

export function OrderDetails(props) {
  const { classes, history, cart, user } = props;

  const orderId = window.location.pathname.split("/")[3];
  const [totalAmount, setTotalAmount] = useState(0);
  const [copyArr, setCopyArr] = useState([]);
  const [orderData, setOrderData] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  const numberFormatterDecimals = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const numberFormatter = new Intl.NumberFormat("en-US", {});

  useEffect(() => {
    if (orderData) {
      const total = orderData.reduce(
        (partialSum, a) => partialSum + Number(a.salePrice),
        0
      );
      setTotalAmount(total);
      const newArr = [...orderData];
      setCopyArr(newArr);
    }
  }, [orderData]);

  const getData = () => {
    return db
      .collection("users")
      .doc(user.uid)
      .collection("orders")
      .doc(orderId)
      .get()
      .then((snapshots) => {
        setPaymentType(snapshots.data().paymentType);
        setOrderData(snapshots.data().orderData);
      });
  };

  useEffect(() => {
    if (!orderData) {
      getData();
    }
  }, []);

  if (!orderData) {
    return <Loader />;
  }

  return (
    <div>
      <Hidden mdUp implementation="css">
        <GridContainer style={{ marginTop: "60px" }}>
          <GridItem
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="h2"
              className={classes.cartTitle}
              style={{ fontSize: "24px" }}
            >
              Order # {orderId}
            </Typography>
          </GridItem>

          <GridItem xs={12}>
            <Button
              onClick={() => {
                window.open("https://www.tuzorewards.com/contact/", "_blank");
              }}
              className={classes.checkoutButton}
              style={{ width: "65%" }}
            >
              <Add style={{ marginRight: "10px" }} /> Request Assistance
            </Button>
          </GridItem>

          <GridItem xs={12}>
            <ItemInfo
              cart={copyArr}
              totalAmount={totalAmount}
              numberFormatterDecimals={numberFormatterDecimals}
              history={history}
              orderData={orderData}
            />
          </GridItem>

          <GridItem xs={12} style={{ marginTop: "-30px" }}>
            <Summary
              history={history}
              orderData={orderData}
              totalAmount={totalAmount}
              numberFormatterDecimals={numberFormatterDecimals}
              numberFormatter={numberFormatter}
              paymentType={paymentType}
            />
          </GridItem>
        </GridContainer>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h2"
              className={classes.cartTitle}
              style={{ fontSize: "24px" }}
            >
              Order #{orderId}
            </Typography>

            <Button
              onClick={() => {
                window.open("https://www.tuzorewards.com/contact/", "_blank");
              }}
              className={classes.checkoutButton}
              style={{ width: "200px", marginTop: "0px", marginLeft: "20px" }}
            >
              <Add style={{ marginRight: "10px" }} /> Request Assistance
            </Button>
          </GridItem>

          <GridItem xs={8}>
            <ItemInfo
              cart={copyArr}
              totalAmount={totalAmount}
              numberFormatterDecimals={numberFormatterDecimals}
              history={history}
              orderData={orderData}
            />
          </GridItem>

          <GridItem xs={4}>
            <Summary
              history={history}
              orderData={orderData}
              totalAmount={totalAmount}
              numberFormatterDecimals={numberFormatterDecimals}
              numberFormatter={numberFormatter}
              paymentType={paymentType}
            />
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state, location) {
  return {
    cart: state.cart.cart,
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(OrderDetails);
