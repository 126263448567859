import React from 'react';
import { useState } from 'react';
import Datetime from "react-datetime";

// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import Slide from '@material-ui/core/Slide';
import Hidden from "@material-ui/core/Hidden";
import { InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core';

// @material-ui/icons


// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/travelStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import tuzoBg from "assets/img/tuzo/POS.png"

export function TravelSystem(props) {
    const {
        classes,
        history,
    } = props;

    return (
        // <div style={{ background: "#000", opacity: 0.5 }}>
        <div
            className={classes.divBackground}
        >
                <GridContainer >
                    <GridItem xs={4} style={{ marginLeft: "40px" }}>

                        <Typography variant="h2" className={classes.rewardsBannerTitle} style={{ fontSize: "28px", fontWeight: 700, lineHeight: "28px" }}>
                            Flights, Hotels, Rental Cars
                        </Typography >

                        <Typography variant="h2" className={classes.rewardsBannerTitle} style={{ fontSize: "19px", fontWeight: 600, lineHeight: "24px", marginTop: "20px" }}>
                            Access travel in our simple to use platform. Book, Change, Conceirge!
                        </Typography >

                        <Button
                            style={{ background: "#E2A400", marginTop: "25px", borderRadius: "7px", width: "90%", fontWeight: 700 }}
                            className={classes.travelFiltersButton}
                        >
                            Learn More Travel Opportunities
                        </Button>

                    </GridItem>

                </GridContainer>
            </div>
    )
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(TravelSystem));
