// /*eslint-disable*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from "store/actions/auth";
import { db, firebase, storage } from "firebase/client";
import UserInfo from "./components/UserInfo";
import BillingInfo from "./components/BillingInfo";
import {  updateUserPhoto, updateUserLogo, deleteUserPhoto, deleteUserLogo } from "store/actions/auth.js";
import moment from "moment"
import Table from "./components/Table";
import MerchantDataTable from "./components/MerchantDataTable";
import { zip } from "lodash";
import { ptsFormula } from "globalCalculationFunction/globalCalculationFunction";
import MerchantDataFilter from "./components/MerchantDataFilter";
import { reportSelections } from "../reports/reportSelections";
import Filter from "./components/Filter";
import DateRange from "./components/DateRange";
import GatewayPage from "./components/Gateway";
import  {
    geocodeByAddress,
} from "react-places-autocomplete";
import { sendIrisCloudFunction } from "firebase/client";
import { sendCloudFunction } from "firebase/client";
import { sendIsoAdminSignUpLinkLive } from "firebase/client";
import { sendIsoSignUpLinkLive } from "firebase/client";
import { AddModal } from "./AddModal";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Dialog, Typography, Card } from "@material-ui/core";

// @material-ui/icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";
import { BsCheckCircleFill } from "react-icons/bs";
import { Add, Close } from '@material-ui/icons';

//styling
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import loader from "assets/img/Tuzo-Preloader-Transparent.gif";
import PublicCalculator from "./components/PublicCalculator";
import PointsSetup from "./components/PointsSetup";
import Loader from "components/Loader/Loader";
import { sendMavCloudFunction } from "firebase/client";

const headersDaily = [
  { label: "Merchant Id", key: "merchantId", hide: true },
  { label: "Date", key: "date" },
  { label: "# of Transactions", key: "numTransactions" },
  { label: "Total ($)", key: "total" },
  { label: "Reference", key: "referenceNum" },
  { label: "Points", key: "pts" },
];

const headersMonthly = [
  { label: "Merchant Id", key: "merchantId", hide: true },
  { label: "Date", key: "date" },
  { label: "# of Batches", key: "numTransactions" },
  { label: "Total ($)", key: "total" },
  { label: "Reference", key: "referenceNum" },
  { label: "Points", key: "pts" },
];

const headersISO = [
  { label: "Points", key: "pts" },
  { label: "Name", key: "name" },
  { label: "Company Name", key: "companyName" },
  { label: "Email", key: "email" },
  { label: "Status", key: "status" },
];

const statusArr = ["Total Points", "Name", "Joined"];

const ascArr = ["Asc", "Desc"];

const userTypeArr = ["Sub ISO", "Staff", "Merchants"];

const entriesArr = [10, 15, 20, 50, 100];

const gatewayArr = [
  {
    type: "Fiserv AccessOne",
    id: 0,
  },
  {
    type: "IRIS",
    id: 1,
  },
  {
    type: "Maverick",
    id: 2,
  },
];

const modalProcessorArr = ["Fiserv AccessOne", "IRIS", "Maverick"];

const modalTypeArr = ["Merchant", "Sub ISO", "Staff"];

export function UserProfile(props) {
  const { classes, history, uid, user, editState, userCompany } = props;

    const docId = window.location.pathname.split("/")[3]
    const [dataLoaded, setDataLoaded] = useState(false)
    const [merchantDataArr, setMerchantDataArr] = useState(false)
    const [userData, setUserData] = useState("")
    const [username, setUsername] = useState("")
    const [firstNameState, setFirstNameState] = useState("")
    const [lastNameState, setLastNameState] = useState("")
    const [emailState, setEmailState] = useState("")
    const [cellPhoneState, setCellPhoneState] = useState("")
    const [secondaryPhoneState, setSecondaryPhoneState] = useState("")
    const [shippingAddressState, setShippingAddressState] = useState("")
    const [companyState, setCompanyState] = useState("");
    const [birthdayState, setBirthdayState] = useState("")
    const [demoAcc, setDemoAcc] = useState(false)
    const [approvedPtsState, setApprovedPtsState] = useState(0)
    const [profilePic, setProfilePic] = useState("")
    const [progress, setProgress] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [globalEdit, setGlobalEdit] = useState(editState ? editState : false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [billingEdit, setBillingEdit] = useState(false)
    const [cardNumber, setCardNumber] = useState("")
    const [expDate, setExpDate] = useState("")
    const [nameOnCard, setNameOnCard] = useState("")
    const [billingAddress, setBillingAddress] = useState("")
    const [asDefault, setAsDefault] = useState(true)
    const [newPayment, setNewPayment] = useState(false)
    const [typeOfPayment, setTypeOfPayment] = useState({
        card: true,
        ach: false
    })
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [zippedArr, setZippedArr] = useState(false)
    const [copyZippedArr, setZippedCopyArr] = useState(false)
    const [points, setPoints] = useState("")
    const [reportArr, setReportArr] = useState([])
    const [sortValue, setSortValue] = useState("")
    const [reportType, setReportType] = useState("Monthly")
    const [dateFilter, setDateFilter] = useState(moment().format("MMM DD, YYYY"))
    const [headers, setHeaders] = useState(null)
    const [reportData, setReportData] = useState([])
    const [merchDocId, setMerchDocId] = useState("")
    const [statusDropdown, setStatusDropdown] = useState("Total Points")
    const [ascDropdown, setAscDropdown] = useState("Asc")
    const [searchString, setSearchString] = useState("")
    const [copyArr, setCopyArr] = useState(false)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [isoAdminReportData, setIsoAdminReportData] = useState(false)
    const csvLinkRef = React.createRef();
    const [userTypeArrFilter, setUserTypeArrFilter] = useState([])
    const [emptyData, setEmptyData] = useState(false)
    const [gateway, setGateway] = useState("Fiserv AccessOne")
    const [apiInfo, setApiInfo] = useState(
        [
            {
            apiType: "Fiserv AccessOne",
            apiKey: "",
            apiSecret: "",
            status: "Unused",
            id: 0
            },
            {
                apiType: "IRIS",
                apiKey: "",
                apiSecret: "",
                status: "Unused",
                id: 1
            },
            {
                apiType: "Maverick",
                apiKey: "",
                apiSecret: "",
                status: "Unused",
                id: 2
            },

    ])

    const EmailValidation = require('emailvalid')
    const ev = new EmailValidation({ allowFreemail: true })
    const [modalType, setModalType] = useState("Merchant")
    const [addModal, setAddModal] = useState(false)
    const [modalUserBasisPts, setModalUserBasisPts] = useState("")
    const [modalSignUpBonus, setModalSignUpBonus] = useState("")
    const [modalMerchantId, setModalMerchantId] = useState("")
    const [modalUsername, setModalUsername] = useState("")
    const [modalFirstName, setModalFirstName] = useState("")
    const [modalLastName, setModalLastName] = useState("")
    const [modalEmail, setModalEmail] = useState("")
    const [modalCellPhone, setModalCellPhone] = useState("")
    const [modalSecondaryPhone, setModalSecondaryPhone] = useState("")
    const [modalAddressState, setModalAddressState] = useState("")
    const [modalCity, setModalCity] = useState("")
    const [modalState, setModalState] = useState("")
    const [modalZipCode, setModalZipCode] = useState("")
    const [modalCompanyName, setModalCompanyName] = useState("")
    const [modalEin, setModalEin] = useState("")
    const [modalNotes, setModalNotes] = useState("")
    const [modalProcessorName, setModalProcessorName] = useState("Fiserv AccessOne")
    const [modalProcessorEmail, setModalProcessorEmail] = useState("")
    const [modalApiUserName, setModalApiUsername] = useState("")
    const [modalApiPassword, setModalApiPassword] = useState("")
    const [accountId, setAccountId] = useState("")
    const [modalProcessorPhone, setModalProcessorPhone] = useState("")
    const [modalprocessorMid, setModalProcessorMid] = useState("")
    const [modalErrors, setModalErrors] = useState({
        points: false,
        processor: false,
        dba: false,
        email: false,
        emailTypo: false,
        id: false,
        username: false,
        firstName: false,
        lastName: false,
        apiUsername: false,
        apiPassword: false,
        accountId: false
    })
    const [ptsSchedule, setPtsSchedule] = useState("60")
    const [autoApprove, setAutoApprove] = useState(false)
    const [lockSchedule, setLockSchedule] = useState(true)
    const [defaultCalcBasisPts, setDefaultCalcBasisPts] = useState("")

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();
      if (placeholder === "Search your pick up address..") {
      }
      if (placeholder === "Search the address..") {
        const addressResults = results[0];
        addressResults.address_components.forEach((r) => {
          if (r.types.includes("locality")) {
            setModalCity(r.long_name);
          }
          if (r.types.includes("administrative_area_level_1")) {
            setModalState(r.short_name);
          }
          if (r.types.includes("postal_code")) {
            setModalZipCode(r.short_name);
          }
        });
        setModalAddressState(addressResults.formatted_address);
      }
    });
  };

  const resetState = () => {
    setModalUserBasisPts("");
    setModalSignUpBonus("");
    setModalMerchantId("");
    setModalUsername("");
    setModalFirstName("");
    setModalLastName("");
    setModalEmail("");
    setModalCellPhone("");
    setModalSecondaryPhone("");
    setModalAddressState("");
    setModalCity("");
    setModalState("");
    setModalZipCode("");
    setModalCompanyName("");
    setModalEin("");
    setModalProcessorPhone("");
    setModalProcessorEmail("");
    setModalProcessorMid("");
    setModalNotes("");
    setModalApiUsername("");
    setModalApiPassword("");
    setAccountId("");
  };

    const sendCustomerEmail = async (e) => {
        e.preventDefault();
        const emailCheck = ev.check(modalEmail)
        if (modalType === "Merchant" && modalProcessorName.includes("Fiserv AccessOne") &&
            (!modalUserBasisPts || !modalCompanyName || !modalEmail || !modalMerchantId || !modalUsername ||
                !modalApiUserName || !modalApiPassword | !modalFirstName || !modalLastName || emailCheck.typo || emailCheck.valid === false)
        ) {
            let newErrs = {
                points: false,
                processor: false,
                dba: false,
                email: false,
                id: false,
                username: false,
                firstName: false,
                lastName: false,
                emailTypo: false,
                apiUsername: false,
                apiPassword: false
            }
            if (!modalUserBasisPts) {
                newErrs = { ...newErrs, points: true }
            }
            if (!modalCompanyName) {
                newErrs = { ...newErrs, dba: true }
            }
            if (!modalEmail) {
                newErrs = { ...newErrs, email: true }
            }
            if (emailCheck.typo || emailCheck.valid === false) {
                newErrs = { ...newErrs, emailTypo: true }
            }
            if (!modalMerchantId) {
                newErrs = { ...newErrs, id: true }
            }
            if (!modalUsername) {
                newErrs = { ...newErrs, username: true }
            }
            if (!modalApiUserName) {
                newErrs = { ...newErrs, apiUsername: true }
            }
            if (!modalApiPassword) {
                newErrs = { ...newErrs, apiPassword: true }
            }
            if (!modalFirstName) {
                newErrs = { ...newErrs, firstName: true }
            }
            if (!modalLastName) {
                newErrs = { ...newErrs, lastName: true }
            }
            setModalErrors({ ...newErrs })
        }
        else if (modalType === "Merchant" && modalProcessorName.includes("IRIS") &&
            (!modalUserBasisPts || !modalCompanyName || !modalEmail || !modalMerchantId || !modalUsername || !modalApiUserName ||
                !modalFirstName || !modalLastName || emailCheck.typo || emailCheck.valid === false)
        ) {
            let newErrs = {
                points: false,
                processor: false,
                dba: false,
                email: false,
                id: false,
                username: false,
                firstName: false,
                lastName: false,
                emailTypo: false,
                apiUsername: false,
            }
            if (!modalUserBasisPts) {
                newErrs = { ...newErrs, points: true }
            }
            if (!modalCompanyName) {
                newErrs = { ...newErrs, dba: true }
            }
            if (!emailState) {
                newErrs = { ...newErrs, email: true }
            }
            if (emailCheck.typo || emailCheck.valid === false) {
                newErrs = { ...newErrs, emailTypo: true }
            }
            if (!modalMerchantId) {
                newErrs = { ...newErrs, id: true }
            }
            if (!modalUsername) {
                newErrs = { ...newErrs, username: true }
            }
            if (!modalFirstName) {
                newErrs = { ...newErrs, firstName: true }
            }
            if (!modalLastName) {
                newErrs = { ...newErrs, lastName: true }
            }
            if (!modalApiUserName) {
                newErrs = { ...newErrs, apiUsername: true }
            }
            setModalErrors({ ...newErrs })
        }
        else if (modalType === "Merchant" && modalProcessorName.includes("Maverick") &&
        (!modalUserBasisPts || !modalCompanyName || !modalEmail || !modalMerchantId || !modalUsername ||
            !modalApiUserName || !accountId | !modalFirstName || !modalLastName || emailCheck.typo || emailCheck.valid === false)
        ) {
            let newErrs = {
                points: false,
                processor: false,
                dba: false,
                email: false,
                id: false,
                username: false,
                firstName: false,
                lastName: false,
                emailTypo: false,
                apiUsername: false,
                accountId: false,
            }
            if (!modalUserBasisPts) {
                newErrs = { ...newErrs, points: true }
            }
            if (!modalCompanyName) {
                newErrs = { ...newErrs, dba: true }
            }
            if (!emailState) {
                newErrs = { ...newErrs, email: true }
            }
            if (emailCheck.typo || emailCheck.valid === false) {
                newErrs = { ...newErrs, emailTypo: true }
            }
            if (!modalMerchantId) {
                newErrs = { ...newErrs, id: true }
            }
            if (!modalUsername) {
                newErrs = { ...newErrs, username: true }
            }
            if (!modalFirstName) {
                newErrs = { ...newErrs, firstName: true }
            }
            if (!modalLastName) {
                newErrs = { ...newErrs, lastName: true }
            }
            if (!modalApiUserName) {
                newErrs = { ...newErrs, apiUsername: true }
            }
            if (!accountId) {
              newErrs = { ...newErrs, accountId: true }
          }
            setModalErrors({ ...newErrs })
        }
        else if (
            (modalType === "Staff") && (!modalEmail || !modalUsername ||
                !modalFirstName || !modalLastName || emailCheck.typo || emailCheck.valid === false)
        ) {
            let newErrs = {
                email: false,
                username: false,
                firstName: false,
                lastName: false,
                emailTypo: false
            }
            if (!modalEmail) {
                newErrs = { ...newErrs, email: true }
            }
            if (emailCheck.typo || emailCheck.valid === false) {
                newErrs = { ...newErrs, emailTypo: true }
            }
            if (!modalUsername) {
                newErrs = { ...newErrs, username: true }
            }
            if (!modalFirstName) {
                newErrs = { ...newErrs, firstName: true }
            }
            if (!modalLastName) {
                newErrs = { ...newErrs, lastName: true }
            }
            setModalErrors({ ...newErrs })
        }
        else if (
            (modalType === "Sub ISO") && (!modalEmail || !modalUsername || !modalCompanyName ||
                !modalFirstName || !modalLastName || emailCheck.typo || emailCheck.valid === false)
        ) {
            let newErrs = {
                email: false,
                username: false,
                firstName: false,
                lastName: false,
                emailTypo: false,
                dba: false
            }
            if (!modalEmail) {
                newErrs = { ...newErrs, email: true }
            }
            if (emailCheck.typo || emailCheck.valid === false) {
                newErrs = { ...newErrs, emailTypo: true }
            }
            if (!modalUsername) {
                newErrs = { ...newErrs, username: true }
            }
            if (!modalFirstName) {
                newErrs = { ...newErrs, firstName: true }
            }
            if (!modalLastName) {
                newErrs = { ...newErrs, lastName: true }
            }
            if (!modalCompanyName) {
                newErrs = { ...newErrs, dba: true }
            }
            setModalErrors({ ...newErrs })
        }
        else {
            if (modalType === "Merchant") {
                console.log("checking apiInfo", apiInfo)
                const docId = db.collection('users').doc().id
                let tempData = {}
                if (userData.userType === "SubIso") {
                  if(modalProcessorName.includes("Maverick")){
                    tempData = {
                      userType: "IsoMerchants",
                      username: modalUsername,
                      firstName: modalFirstName,
                      lastName: modalLastName,
                      SubIsoCompanyName: userData.companyName,
                      IsoAdminCompanyName: userData.IsoAdminCompanyName,
                      contactUrl: userData.contactUrl ? userData.contactUrl : "",
                      companyName: modalCompanyName,
                      DBA: modalCompanyName,
                      shippingAddress: modalAddressState,
                      city: modalCity,
                      state: modalState,
                      zipCode: modalZipCode,
                      EIN: modalEin,
                      userBasisPts: Number(modalUserBasisPts),
                      merchantId: modalMerchantId,
                      email: modalEmail,
                      id: docId,
                      processor: modalProcessorName,
                      processorMid: modalprocessorMid,
                      processorPhone: modalProcessorPhone,
                      processorEmail: modalProcessorEmail,
                      notes: modalNotes,
                      signUpBonus: modalSignUpBonus,
                      logo: userData.logo ? userData.logo : "",
                      emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                      apiInfo,
                      apiUsername: modalApiUserName,
                      apiPassword: modalApiPassword,
                      apiUrl: userData.companyName.replace(/ /g, '').toLowerCase(),
                      tuzoPoints: 0,
                      approvedPts: 0,
                      demo: demoAcc,
                      accountId
                    }
                  }else{
                    tempData = {
                      userType: "IsoMerchants",
                      username: modalUsername,
                      firstName: modalFirstName,
                      lastName: modalLastName,
                      SubIsoCompanyName: userData.companyName,
                      IsoAdminCompanyName: userData.IsoAdminCompanyName,
                      contactUrl: userData.contactUrl ? userData.contactUrl : "",
                      companyName: modalCompanyName,
                      DBA: modalCompanyName,
                      shippingAddress: modalAddressState,
                      city: modalCity,
                      state: modalState,
                      zipCode: modalZipCode,
                      EIN: modalEin,
                      userBasisPts: Number(modalUserBasisPts),
                      merchantId: modalMerchantId,
                      email: modalEmail,
                      id: docId,
                      processor: modalProcessorName,
                      processorMid: modalprocessorMid,
                      processorPhone: modalProcessorPhone,
                      processorEmail: modalProcessorEmail,
                      notes: modalNotes,
                      signUpBonus: modalSignUpBonus,
                      logo: userData.logo ? userData.logo : "",
                      emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                      apiInfo,
                      apiUsername: modalApiUserName,
                      apiPassword: modalApiPassword,
                      apiUrl: userData.companyName.replace(/ /g, '').toLowerCase(),
                      tuzoPoints: 0,
                      approvedPts: 0,
                      demo: demoAcc
                    }
                  }
                }
                else if (userData.userType === "Staff") {
                  if(modalProcessorName.includes("Maverick")){
                    tempData = {
                      userType: "IsoMerchants",
                      username: modalUsername,
                      firstName: modalFirstName,
                      lastName: modalLastName,
                      IsoStaffMember: userData.modalEmail,
                      IsoAdminCompanyName: userData.IsoAdminCompanyName,
                      contactUrl: userData.contactUrl ? userData.contactUrl : "",
                      companyName: modalCompanyName,
                      DBA: modalCompanyName,
                      shippingAddress: modalAddressState,
                      city: modalCity,
                      state: modalState,
                      zipCode: modalZipCode,
                      EIN: modalEin,
                      userBasisPts: Number(modalUserBasisPts),
                      merchantId: modalMerchantId,
                      email: modalEmail,
                      id: docId,
                      processor: modalProcessorName,
                      processorMid: modalprocessorMid,
                      processorPhone: modalProcessorPhone,
                      processorEmail: modalProcessorEmail,
                      notes: modalNotes,
                      signUpBonus: modalSignUpBonus,
                      logo: userData.logo ? userData.logo : "",
                      emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                      apiInfo,
                      apiUsername: modalApiUserName,
                      apiPassword: modalApiPassword,
                      apiUrl: userData.companyName.replace(/ /g, '').toLowerCase(),
                      tuzoPoints: 0,
                      approvedPts: 0,
                      demo: demoAcc,
                      accountId
                  }
                  }else{
                    tempData = {
                      userType: "IsoMerchants",
                      username: modalUsername,
                      firstName: modalFirstName,
                      lastName: modalLastName,
                      IsoStaffMember: userData.modalEmail,
                      IsoAdminCompanyName: userData.IsoAdminCompanyName,
                      contactUrl: userData.contactUrl ? userData.contactUrl : "",
                      companyName: modalCompanyName,
                      DBA: modalCompanyName,
                      shippingAddress: modalAddressState,
                      city: modalCity,
                      state: modalState,
                      zipCode: modalZipCode,
                      EIN: modalEin,
                      userBasisPts: Number(modalUserBasisPts),
                      merchantId: modalMerchantId,
                      email: modalEmail,
                      id: docId,
                      processor: modalProcessorName,
                      processorMid: modalprocessorMid,
                      processorPhone: modalProcessorPhone,
                      processorEmail: modalProcessorEmail,
                      notes: modalNotes,
                      signUpBonus: modalSignUpBonus,
                      logo: userData.logo ? userData.logo : "",
                      emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                      apiInfo,
                      apiUsername: modalApiUserName,
                      apiPassword: modalApiPassword,
                      apiUrl: userData.companyName.replace(/ /g, '').toLowerCase(),
                      tuzoPoints: 0,
                      approvedPts: 0,
                      demo: demoAcc
                    }
                  }
                }
                else {
                  if(modalProcessorName.includes("Maverick")){
                    tempData = {
                      userType: "IsoMerchants",
                      username: modalUsername,
                      firstName: modalFirstName,
                      lastName: modalLastName,
                      IsoAdminCompanyName: userData.companyName,
                      contactUrl: userData.contactUrl ? userData.contactUrl : "",
                      companyName: modalCompanyName,
                      DBA: modalCompanyName,
                      shippingAddress: modalAddressState,
                      city: modalCity,
                      state: modalState,
                      zipCode: modalZipCode,
                      EIN: modalEin,
                      userBasisPts: Number(modalUserBasisPts),
                      merchantId: modalMerchantId,
                      email: modalEmail,
                      id: docId,
                      processor: modalProcessorName,
                      processorMid: modalprocessorMid,
                      processorPhone: modalProcessorPhone,
                      processorEmail: modalProcessorEmail,
                      notes: modalNotes,
                      signUpBonus: modalSignUpBonus,
                      logo: userData.logo ? userData.logo : "",
                      emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                      apiInfo,
                      apiUsername: modalApiUserName,
                      apiPassword: modalApiPassword,
                      apiUrl: userData.companyName.replace(/ /g, '').toLowerCase(),
                      tuzoPoints: 0,
                      approvedPts: 0,
                      demo: demoAcc,
                      accountId
                    }
                  }
                  else{
                    tempData = {
                      userType: "IsoMerchants",
                      username: modalUsername,
                      firstName: modalFirstName,
                      lastName: modalLastName,
                      IsoAdminCompanyName: userData.companyName,
                      contactUrl: userData.contactUrl ? userData.contactUrl : "",
                      companyName: modalCompanyName,
                      DBA: modalCompanyName,
                      shippingAddress: modalAddressState,
                      city: modalCity,
                      state: modalState,
                      zipCode: modalZipCode,
                      EIN: modalEin,
                      userBasisPts: Number(modalUserBasisPts),
                      merchantId: modalMerchantId,
                      email: modalEmail,
                      id: docId,
                      processor: modalProcessorName,
                      processorMid: modalprocessorMid,
                      processorPhone: modalProcessorPhone,
                      processorEmail: modalProcessorEmail,
                      notes: modalNotes,
                      signUpBonus: modalSignUpBonus,
                      logo: userData.logo ? userData.logo : "",
                      emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                      apiInfo,
                      apiUsername: modalApiUserName,
                      apiPassword: modalApiPassword,
                      apiUrl: userData.companyName.replace(/ /g, '').toLowerCase(),
                      tuzoPoints: 0,
                      approvedPts: 0,
                      demo: demoAcc
                  }
                  }
                }
                await db.collection('users').doc(docId).set(tempData)
                .then(async () => {
                    let newObj = {
                      email: modalEmail,
                      firstName: modalFirstName,
                      lastName: modalLastName,
                      id: docId,
                      headerImg: userData.branding !== "Default" && profilePic? profilePic : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FEmailHeader.png?alt=media&token=f03aab60-d827-408c-9679-01688999f5dd",
                      footerImg: userData.branding === "Priority" && profilePic? profilePic : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FtuzoHorizontal.png?alt=media&token=f161b013-c40c-49f2-b6d7-468beae5a488",
                      domain: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" ? "notifications@wpirewards.com" : "notifications@tuzorewards.com",
                      emailTitle: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" ? "WPI Rewards" : "Tuzo Rewards",
                      emailName: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" ? "WPI" : "Tuzo",
                      linkUrl: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" ? `https://www.wpirewards.com/auth/iso-merchant-register/${docId}` : `https://www.tuzo.app/auth/iso-merchant-register/${docId}`,
                      supportEmail: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" ? "info@wpirewards.com" : "info@tuzorewards.com",
                      supportPhone: window.location.host === "www.wpirewards.com" || userData.companyName === "Wholesale Payments" ? "Support Phone" : "(503) 939-5853",
                    }
                    await sendIsoSignUpLinkLive(newObj)
                })
                    .then(async () => {
                        let idObj = {
                            merchantId: modalMerchantId,
                            apiUsername: modalApiUserName,
                            apiPassword: modalApiPassword,
                            apiUrl: userData.companyName.replace(/ /g, '').toLowerCase(),
                            accountId: accountId
                        }
                        if (modalProcessorName.includes("IRIS")) {
                          await sendIrisCloudFunction(idObj)
                        }
                        else if (modalProcessorName.includes("Maverick")) {
                          await sendMavCloudFunction(idObj)
                        }
                        else {
                          await sendCloudFunction(idObj)
                        }
                    })
                    .then(() => {
                        window.location.reload()
                    })
            }
            else {
                const docId = db.collection('temp-user').doc().id
                let tempData = {}
                if (modalType === "Staff") {
                    if (userData.userType === "SubIso") {
                        tempData = {
                            username: modalUsername,
                            firstName: modalFirstName,
                            lastName: modalLastName,
                            SubIsoCompanyName: userData.companyName,
                            IsoAdminCompanyName: userData.IsoAdminCompanyName,
                            contactUrl: userData.contactUrl ? userData.contactUrl : "",
                            companyName: userData.companyName,
                            userBasisPts: Number(modalUserBasisPts),
                            email: modalEmail,
                            id: docId,
                            processor: modalProcessorName,
                            processorMid: modalprocessorMid,
                            processorPhone: modalProcessorPhone,
                            processorEmail: modalProcessorEmail,
                            notes: modalNotes,
                            logo: userData.logo ? userData.logo : "",
                            emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                            userType: "Staff",
                            demo: demoAcc,
                            apiInfo
                        }
                    }
                    else if (userData.userType === "Staff") {
                        tempData = {
                            username: modalUsername,
                            firstName: modalFirstName,
                            lastName: modalLastName,
                            IsoStaffMember: userData.modalEmail,
                            IsoAdminCompanyName: userData.IsoAdminCompanyName,
                            contactUrl: userData.contactUrl ? userData.contactUrl : "",
                            companyName: userData.companyName,
                            userBasisPts: Number(modalUserBasisPts),
                            email: modalEmail,
                            id: docId,
                            processor: modalProcessorName,
                            processorMid: modalprocessorMid,
                            processorPhone: modalProcessorPhone,
                            processorEmail: modalProcessorEmail,
                            notes: modalNotes,
                            logo: userData.logo ? userData.logo : "",
                            emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                            userType: "Staff",
                            demo: demoAcc,
                            apiInfo
                        }
                    } else {
                        tempData = {
                            username: modalUsername,
                            firstName: modalFirstName,
                            lastName: modalLastName,
                            IsoAdminCompanyName: userData.companyName,
                            contactUrl: userData.contactUrl ? userData.contactUrl : "",
                            companyName: userData.companyName,
                            userBasisPts: Number(modalUserBasisPts),
                            email: modalEmail,
                            id: docId,
                            processor: modalProcessorName,
                            processorMid: modalprocessorMid,
                            processorPhone: modalProcessorPhone,
                            processorEmail: modalProcessorEmail,
                            notes: modalNotes,
                            logo: userData.logo ? userData.logo : "",
                            emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                            userType: "Staff",
                            demo: demoAcc,
                            apiInfo
                        }
                    }
                } else {
                    if (userData.userType === "SubIso") {
                        tempData = {
                            username: modalUsername,
                            firstName: modalFirstName,
                            lastName: modalLastName,
                            SubIsoCompanyName: userData.companyName,
                            IsoAdminCompanyName: userData.IsoAdminCompanyName,
                            contactUrl: userData.contactUrl ? userData.contactUrl : "",
                            companyName: modalCompanyName,
                            userBasisPts: Number(modalUserBasisPts),
                            email: modalEmail,
                            id: docId,
                            processor: modalProcessorName,
                            processorMid: modalprocessorMid,
                            processorPhone: modalProcessorPhone,
                            processorEmail: modalProcessorEmail,
                            notes: modalNotes,
                            logo: userData.logo ? userData.logo : "",
                            emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                            userType: "SubIso",
                            demo: demoAcc,
                            apiInfo
                        }
                    } 
                    else if(userData.userType === "Staff"){
                        tempData = {
                            username: modalUsername,
                            firstName: modalFirstName,
                            lastName: modalLastName,
                            IsoStaffMember: userData.modalEmail,
                            IsoAdminCompanyName: userData.IsoAdminCompanyName,
                            contactUrl: userData.contactUrl ? userData.contactUrl : "",
                            companyName: modalCompanyName,
                            userBasisPts: Number(modalUserBasisPts),
                            email: modalEmail,
                            id: docId,
                            processor: modalProcessorName,
                            processorMid: modalprocessorMid,
                            processorPhone: modalProcessorPhone,
                            processorEmail: modalProcessorEmail,
                            notes: modalNotes,
                            logo: userData.logo ? userData.logo : "",
                            emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                            userType: "SubIso",
                            demo: demoAcc,
                            apiInfo
                        }
                    }
                    else {
                        tempData = {
                            username: modalUsername,
                            firstName: modalFirstName,
                            lastName: modalLastName,
                            IsoAdminCompanyName: userData.companyName,
                            contactUrl: userData.contactUrl ? userData.contactUrl : "",
                            companyName: modalCompanyName,
                            userBasisPts: Number(modalUserBasisPts),
                            email: modalEmail,
                            id: docId,
                            processor: modalProcessorName,
                            processorMid: modalprocessorMid,
                            processorPhone: modalProcessorPhone,
                            processorEmail: modalProcessorEmail,
                            notes: modalNotes,
                            logo: userData.logo ? userData.logo : "",
                            emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
                            userType: "SubIso",
                            demo: demoAcc,
                            apiInfo
                        }
                    }
                }
                await db.collection('temp-user').doc(docId).set(tempData)
                    .then(() => {
                        sendIsoAdminSignUpLinkLive(tempData)
                    })
                    .then(() => {
                        window.location.reload()
                    })
            }
        }
    }


  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const handleReportToggle = (value) => {
    setReportType(value);
  };

  const increaseDate = (date) => {
    let newDate = "";
    if (reportType === "Monthly") {
      newDate = moment(date, "MMM YYYY").add(1, "months").format("MMM YYYY");
    } else {
      newDate = moment(date, "MMM DD, YYYY").add(1, "d").format("MMM DD, YYYY");
    }

    setDateFilter(newDate);
  };

  const decreaseDate = (date) => {
    let newDate = "";
    if (reportType === "Monthly") {
      newDate = moment(date, "MMM YYYY")
        .subtract(1, "months")
        .format("MMM YYYY");
    } else {
      newDate = moment(date, "MMM DD, YYYY")
        .subtract(1, "d")
        .format("MMM DD, YYYY");
    }
    setDateFilter(newDate);
  };

  useEffect(() => {
    if (reportType === "Monthly") {
      setDateFilter(moment(dateFilter).format("MMM YYYY"));
      setHeaders(headersMonthly);
    } else {
      setDateFilter(moment(dateFilter).format("MMM DD, YYYY"));
      setHeaders(headersDaily);
    }
  }, [reportType]);

  const handleSearch = () => {
    if (searchString) {
      const filtered = copyArr.filter((f) => {
        if (
          f.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
          f.lastName.toLowerCase().includes(searchString.toLowerCase()) ||
          f.companyName.toLowerCase().includes(searchString.toLowerCase()) ||
          f.email.toLowerCase().includes(searchString.toLowerCase())
        ) {
          return true;
        }
      });
      if (filtered.length === 0) {
        setEmptyData(true);
      }
      setMerchantDataArr(filtered);
    } else {
      setMerchantDataArr(copyArr);
    }
  };

  const resetEmptyData = () => {
    setEmptyData(false);
    setSearchString("");
    setMerchantDataArr(copyArr);
  };

  const resetPageFilter = (filterArr) => {
    setZippedArr([...filterArr]);
    setIndex(0);
    setEndIndex(itemsPerPage);
    setPageNumber(0);
  };

  const resetPageFilterISO = () => {
    setIndex(0);
    setEndIndex(itemsPerPage);
    setPageNumber(0);
  };

  const handleFilter = (ascValue, sortValue) => {
    if (ascValue === "Asc") {
      if (sortValue === "Total Points") {
        const filtered = merchantDataArr.sort(
          (a, b) =>
            (a.tuzoPoints != null ? a.tuzoPoints : -Infinity) -
            (b.tuzoPoints != null ? b.tuzoPoints : -Infinity)
        );
        resetPageFilter(filtered);
      }
      if (sortValue === "Name") {
        const filtered = merchantDataArr.sort(function (a, b) {
          if (a.contact) {
            if (a.contact < b.contact) {
              return -1;
            }
            if (a.contact > b.contact) {
              return 1;
            }
            return 0;
          } else {
            if (a.lastName < b.lastName) {
              return -1;
            }
            if (a.lastName > b.lastName) {
              return 1;
            }
            return 0;
          }
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "Joined") {
        const filtered = merchantDataArr.sort(function (a, b) {
          return (
            (a.timestamp != null ? a.timestamp.seconds : -Infinity) -
            (b.timestamp != null ? b.timestamp.seconds : -Infinity)
          );
        });
        resetPageFilter(filtered);
      }
    }
    if (ascValue === "Desc") {
      if (sortValue === "Total Points") {
        const filtered = merchantDataArr.sort(
          (a, b) =>
            (b.approvedPts != null ? b.approvedPts : -Infinity) -
            (a.approvedPts != null ? a.approvedPts : -Infinity)
        );
        resetPageFilter(filtered);
      }
      if (sortValue === "Name") {
        const filtered = merchantDataArr.sort(function (a, b) {
          if (a.contact) {
            if (a.contact > b.contact) {
              return -1;
            }
            if (a.contact < b.contact) {
              return 1;
            }
            return 0;
          } else {
            if (a.lastName > b.lastName) {
              return -1;
            }
            if (a.lastName < b.lastName) {
              return 1;
            }
            return 0;
          }
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "Joined") {
        const filtered = merchantDataArr.sort(function (a, b) {
          return (
            (b.timestamp != null ? b.timestamp.seconds : -Infinity) -
            (a.timestamp != null ? a.timestamp.seconds : -Infinity)
          );
        });
        resetPageFilter(filtered);
      }
    }
  };

  const handleUserTypeFilter = (event) => {
    let userArr = [];
    const {
      target: { value },
    } = event;
    setUserTypeArrFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    if (value.length === 0) {
      setMerchantDataArr(copyArr);
    } else {
      copyArr.map((data) => {
        if (
          value.includes("Administrator") &&
          (data.userType === "Administrator" || data.userType === "Admin")
        ) {
          userArr.push(data);
        } else if (value.includes("ISO") && data.userType === "IsoAdmin") {
          userArr.push(data);
        } else if (
          data.userType.includes("Merchant") &&
          value.includes("Merchants")
        ) {
          userArr.push(data);
        } else {
          if (value.includes(data.userType)) {
            userArr.push(data);
          }
        }
      });
      if (ascDropdown === "Asc") {
        userArr.sort(function (a, b) {
          if (a.userType < b.userType) {
            return -1;
          }
          if (a.userType > b.userType) {
            return 1;
          }
          return 0;
        });
        resetPageFilterISO();
        setMerchantDataArr(userArr);
      }
      if (ascDropdown === "Desc") {
        userArr.sort(function (a, b) {
          if (a[0].userType > b[0].userType) {
            return -1;
          }
          if (a[0].userType < b[0].userType) {
            return 1;
          }
          return 0;
        });
        resetPageFilterISO();
        setMerchantDataArr(userArr);
      }
    }
  };

  useEffect(() => {
    if (merchantDataArr) {
      if (reportType === "Monthly") {
        const filteredDate = merchantDataArr.filter((b) => {
          if (
            b.reportDate >=
              moment(dateFilter)
                .startOf("month")
                .format("YYYY-MM-DDT00:00:00") &&
            b.reportDate <=
              moment(dateFilter).endOf("month").format("YYYY-MM-DDT23:59:59")
          ) {
            return true;
          }
        });
        setReportData(filteredDate);
      } else if (reportType === "Daily") {
        const filteredDate = merchantDataArr.filter((b) => {
          if (
            b.reportDate >=
              moment(dateFilter).startOf("day").format("YYYY-MM-DDT00:00:00") &&
            b.reportDate <=
              moment(dateFilter).endOf("day").format("YYYY-MM-DDT23:59:59")
          ) {
            return true;
          }
        });
        setReportData(filteredDate);
      } else {
        setReportData([]);
      }
    }
  }, [dateFilter, merchantDataArr]);

  useEffect(() => {
    if (progress === 100) {
      setUploading(false);
    }
  }, [progress]);

  function formatPhoneNumber(phoneNumber) {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "";
  }

    const updateIsoMerchants = async () => {
        await db
            .collection('users')
            .where("IsoAdminCompanyName", "==", userData.companyName)
            .get()
            .then((snapshots) => {
                if (!snapshots.empty) {
                    let updatedObj = {}
                    if(userData.companyName !== companyState){
                        updatedObj = {
                            autoApprove,
                            ptsReleaseDay: autoApprove ? ptsSchedule : "60",
                            lockSchedule,
                            apiInfo,
                            IsoAdminCompanyName: companyState,
                            branding: userData.branding
                        }
                    }else{
                        updatedObj = {
                            autoApprove,
                            ptsReleaseDay: autoApprove ? ptsSchedule : "60",
                            lockSchedule,
                            apiInfo,
                            branding: userData.branding
                        }
                    }
                    snapshots.forEach((s) => {
                        db.collection('users').doc(s.id).update(updatedObj)
                    })
                }
            })
        await db
            .collection('temp-user')
            .where("IsoAdminCompanyName", "==", userData.companyName)
            .get()
            .then((snapshots) => {
                if (!snapshots.empty) {
                    let updatedObj = {}
                    if(userData.companyName !== companyState){
                        updatedObj = {
                            autoApprove,
                            ptsReleaseDay: autoApprove ? ptsSchedule : "60",
                            lockSchedule,
                            apiInfo,
                            IsoAdminCompanyName: companyState
                        }
                    }else{
                        updatedObj = {
                            autoApprove,
                            ptsReleaseDay: autoApprove ? ptsSchedule : "60",
                            lockSchedule,
                            apiInfo,
                        }
                    }
                    snapshots.forEach((s) => {
                        db.collection('temp-user').doc(s.id).update(updatedObj)
                    })
                }
            })
        getData()
    }
    const updateUserInfo = (e) => {
        e.preventDefault();
        var newInfo = {}
        if (userData.userType === "IsoAdmin") {
            newInfo = {
                username: username,
                firstName: firstNameState,
                lastName: lastNameState,
                companyName: companyState,
                birthday: birthdayState,
                email: emailState,
                cellPhone: cellPhoneState,
                secondaryPhone: secondaryPhoneState,
                shippingAddress: shippingAddressState,
                demo: demoAcc,
                apiInfo,
                autoApprove,
                ptsReleaseDay: ptsSchedule,
                lockSchedule,
                defaultCalcBasisPts
            };
        } else {
            newInfo = {
                username: username,
                firstName: firstNameState,
                lastName: lastNameState,
                companyName: companyState,
                birthday: birthdayState,
                email: emailState,
                cellPhone: cellPhoneState,
                secondaryPhone: secondaryPhoneState,
                shippingAddress: shippingAddressState,
                demo: demoAcc,
                apiInfo,
                autoApprove,
                ptsReleaseDay: autoApprove ? ptsSchedule : "60",
                lockSchedule,
            }
        }
        db.collection('users').doc(docId).update(newInfo)
        .then(() => {
            if(userData.userType.includes("Merchant")){
              db.collection('merchants-live')
              .where("merchantNumber", "==", userData.merchantId)
              .get()
              .then((snapshots)=>{
                  if(!snapshots.empty){
                      snapshots.forEach((s) => {
                          let sId = s.id
                          db.collection('merchants-live').doc(sId).update({
                              demo: demoAcc
                          })
                      })
                  }
              })
            }
        })
        .then(() => {
            if (userData.userType === "IsoAdmin") {
                updateIsoMerchants()
            }
            setGlobalEdit(false)
        })
    };

  const handleImageUpload = (event) => {
    var previewUrl = URL.createObjectURL(event.target.files[0]);
    var file = event.target.files[0];
    const storageRef = storage.ref(uid);
    const fileRef = storageRef.child("profilePic");

    var uploadTask = fileRef.put(file);

    // fileRef.put(file).then((snapshot) => {
    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      (snapshot) => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            console.log("downloadURL", downloadURL);
            setProfilePic(downloadURL);
            if (userData.userType === "IsoAdmin") {
              props.updateUserLogo(docId, { url: downloadURL });
            } else {
              props.updateUserPhoto(docId, { url: downloadURL });
            }
          })
          .catch(() => {});
      }
    );
  };

  const getBgImage = () => {
    return tuzoBg;
  };

  const deleteImage = () => {
    if (userData.userType === "IsoAdmin") {
      props.deleteUserLogo(docId);
    } else {
      props.deleteUserPhoto(docId);
    }

    setProfilePic("");
    setConfirmDelete(false);
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    props.startPasswordReset(emailState).then(() => {
      setEmailSent(true);
    });
  };

  const getIsoAdminReport = () => {
    let arr = [];
    let pendArr = [];
    let combineArr = [];
    let docArr = [];
    let filtered = [];

    return db
      .collection("users")
      .where("IsoAdminCompanyName", "==", userData.companyName)
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            s = s.data();
            arr.push(s);
          });
          // setData(arr)
        } else {
          // setData([])
        }
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].merchantId) {
            db.collection("merchants-live")
              .where("merchantNumber", "==", arr[i].merchantId)
              .get()
              .then((snapshots) => {
                if (!snapshots.empty) {
                  snapshots.docs.forEach((doc) => {
                    db.collection("merchants-live")
                      .doc(doc.data().docId)
                      .collection("batch-summary")
                      .get()
                      .then((subSnapshot) => {
                        if (!subSnapshot.empty) {
                          subSnapshot.docs.forEach((subDoc) => {
                            subDoc = subDoc.data();
                            let merchantName = arr[i].companyName;
                            let email = arr[i].email;
                            let userBasisPts = arr[i].userBasisPts;
                            let newObj = {
                              ...subDoc,
                              merchantName,
                              email,
                              parentDocId: doc.data().docId,
                              userBasisPts,
                            };
                            docArr.push(newObj);
                          });
                          setIsoAdminReportData(docArr);
                        } else {
                          setIsoAdminReportData([]);
                        }
                      });
                  });
                } else {
                  setIsoAdminReportData([]);
                }
              });
          }
        }

        // })
      });
  };

  const getMerchantReport = () => {
    let subId = "";
    return db
      .collection("merchants-live")
      .where("merchantNumber", "==", userData.merchantId)
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            s = s.data();
            subId = s.docId;
            setMerchDocId(s.docId);
          });
          db.collection("merchants-live")
            .doc(subId)
            .collection("batch-summary")
            .orderBy("reportDate", "desc")
            .onSnapshot((snapshot) => {
              const arr = [];
              snapshot.forEach((doc) => {
                arr.push(doc.data());
              });
              setMerchantDataArr(arr);
              setReportData(arr);
            });
        } else {
          setMerchantDataArr([]);
          setReportData([]);
        }
      });
  };

  const getMerchantArrData = (queryName) => {
    let arr = [];
    let pendArr = [];
    let combineArr = [];
    return db
      .collection("users")
      .where(queryName, "==", userData.companyName)
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            s = s.data();
            arr.push(s);
          });
          setMerchantDataArr(arr);
          setCopyArr(arr);
        } else {
          setMerchantDataArr(arr);
          setCopyArr(arr);
        }
        db.collection("temp-user")
          .where(queryName, "==", userData.companyName)
          .get()
          .then((snapshots) => {
            if (!snapshots.empty) {
              snapshots.forEach((s) => {
                s = s.data();
                let newObj = {
                  ...s,
                  status: "Pending",
                };
                pendArr.push(newObj);
              });
              combineArr = [...arr, ...pendArr];
              setMerchantDataArr(combineArr);
              setCopyArr(combineArr);
            } else {
              combineArr = [...arr, ...pendArr];
              setMerchantDataArr(combineArr);
              setCopyArr(combineArr);
            }
          });
        // db.collection('iso-temp-user')
        //     .where(queryName, "==", userData.companyName)
        //     .get()
        //     .then((snapshots) => {
        //         if (!snapshots.empty) {
        //             snapshots.forEach((s) => {
        //                 s = s.data()
        //                 let newObj = {
        //                     ...s,
        //                     status: "Pending"
        //                 }
        //                 pendArr.push(newObj)
        //             })
        //             combineArr = [...arr, ...pendArr]
        //             setMerchantDataArr(combineArr)
        //             setCopyArr(combineArr)
        //         }
        //         else {
        //             combineArr = [...arr, ...pendArr]
        //             setMerchantDataArr(combineArr)
        //             setCopyArr(combineArr)
        //         }
        //     })
      });
  };

  useEffect(() => {
    if (
      userData &&
      (userData.userType === "IsoAdmin" || userData.userType === "SubIso")
    ) {
      if (userData.userType === "IsoAdmin") {
        getMerchantArrData("IsoAdminCompanyName");
      } else {
        getMerchantArrData("SubIsoCompanyName");
      }
    }
    if (userData && userData.userType.includes("Merchants")) {
      getMerchantReport();
      reportSelections.map((data) => {
        if (
          data.type === "Merchants" &&
          userData.userType.includes("Merchants")
        ) {
          setReportArr((prevArr) => [...prevArr, data]);
        }
      });
    }
  }, [userData.userType, companyState]);

  useEffect(() => {
    if (reportData) {
      var arr = [];
      setPoints([]);
      reportData.map((d) => {
        const floorDollarPerBassisPts = ptsFormula(
          d.netAmount,
          userData.userBasisPts
        );
        arr.push(floorDollarPerBassisPts);
        setPoints((prevArr) => [...prevArr, floorDollarPerBassisPts]);
      });
    }
    setZippedArr(zip(reportData, arr));
    setZippedCopyArr(zip(reportData, arr));
  }, [reportData]);

    const getData = () => {
        db.collection('users').doc(docId).get()
            .then((snapshots) => {
                if (snapshots.exists) {
                    let data = snapshots.data()
                    setUserData(data)
                    setUsername(data.username ? data.username : "")
                    setFirstNameState(data.firstName ? data.firstName : "")
                    setLastNameState(data.lastName ? data.lastName : "")
                    setEmailState(data.email ? data.email : "")
                    setCellPhoneState(data.cellPhone ? data.cellPhone : "")
                    setSecondaryPhoneState(data.secondaryPhone ? data.secondaryPhone : "")
                    setShippingAddressState(data.shippingAddress ? data.shippingAddress : "")
                    setCompanyState(data.companyName ? data.companyName : "")
                    setBirthdayState(data.birthday ? data.birthday : "")
                    setApprovedPtsState(data.approvedPts ? data.approvedPts : 0)
                    setDemoAcc(data.demo ? data.demo : false)
                    setApiInfo(data.apiInfo ? data.apiInfo : apiInfo)
                    setModalProcessorName(data.apiInfo? data.apiInfo[0].apiType : "")
                    setModalApiUsername(data.apiInfo? data.apiInfo[0].apiKey : "")
                    setModalApiPassword(data.apiInfo? data.apiInfo[0].apiSecret : "")
                    if (data.userType === "IsoAdmin") {
                        setProfilePic(data.logo ? data.logo : "")
                        setAutoApprove(data.autoApprove ? data.autoApprove : false)
                        setPtsSchedule(data.ptsReleaseDay ? data.ptsReleaseDay : "60")
                        setLockSchedule(data.lockSchedule ? data.lockSchedule : false)
                        setDefaultCalcBasisPts(data.defaultCalcBasisPts ? data.defaultCalcBasisPts : "")
                    } else {
                        setProfilePic(data.profilePic ? data.profilePic : "")
                    }
                } else {
                    console.log("checking empty")
                }

            })
            .then(() => {
                setDataLoaded(true)
            })
    }

  useEffect(() => {
    getData();
  }, []);

  if (
    !dataLoaded ||
    (userData &&
      (userData.userType === "IsoAdmin" ||
        userData.userType.includes("Merchants") ||
        userData.userType === "SubIso") &&
      !merchantDataArr)
  )
    return <Loader />;

  return (
    <div>
      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={8} style={{ display: "flex" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              {firstNameState + " " + lastNameState + "  "}
            </Typography>
            {billingEdit && !globalEdit ? null : !globalEdit ? (
              <>
                <Button
                  onClick={() => setGlobalEdit(true)}
                  className={classes.editButton}
                >
                  <Edit style={{ marginRight: "10px", marginTop: "-5px" }} />{" "}
                  Edit
                </Button>

                <Button
                  onClick={handlePasswordReset}
                  className={classes.editButton}
                  style={{ background: "#56CA93", width: "350px" }}
                >
                  <BsCheckCircleFill
                    style={{ marginRight: "10px", marginTop: "-5px" }}
                  />{" "}
                  Send Reset Password Email
                </Button>
              </>
            ) : (
              <div>
                <Button
                  className={classes.editButton}
                  style={{ background: "#1A405F", width: "128px" }}
                  onClick={updateUserInfo}
                >
                  <SaveIcon
                    style={{ marginRight: "10px", marginTop: "-5px" }}
                  />{" "}
                  Save
                </Button>
                <Button
                  onClick={() => setGlobalEdit(false)}
                  className={classes.editButton}
                  style={{ background: "#E2A400", width: "143px" }}
                >
                  <Delete style={{ marginRight: "10px", marginTop: "-5px" }} />{" "}
                  Cancel
                </Button>
              </div>
            )}
          </GridItem>

          <GridItem
            xs={4}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-10px",
                marginRight: "30px",
              }}
            >
              <Typography variant="h2" className={classes.headerDesc}>
                User Type
              </Typography>
              <Typography
                variant="h2"
                className={classes.headerDesc}
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "18px",
                  marginTop: "10px",
                }}
              >
                {userData.userType.includes("Merchant")
                  ? "Merchant"
                  : userData.userType === "IsoAdmin"
                  ? "ISO"
                  : userData.userType}
              </Typography>
            </div>

            {userData.userType.includes("Merchant") ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "-10px",
                    marginRight: "30px",
                  }}
                >
                  <Typography
                    variant="h2"
                    className={classes.headerDesc}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Merchant ID
                  </Typography>
                  <Typography
                    variant="h2"
                    className={classes.headerDesc}
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      lineHeight: "18px",
                      marginTop: "10px",
                    }}
                  >
                    {userData.merchantId}
                  </Typography>
                </div>
              </>
            ) : null}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-10px",
              }}
            >
              <Typography variant="h2" className={classes.headerDesc}>
                Account Number
              </Typography>
              <Typography
                variant="h2"
                className={classes.headerDesc}
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "18px",
                  marginTop: "10px",
                }}
              >
                {docId}
              </Typography>
            </div>
          </GridItem>
        </GridContainer>

                <GridContainer >
                    <GridItem xs={12} style={{ marginTop: "20px" }}>
                        <UserInfo
                            username={username}
                            setUsername={setUsername}
                            user={user}
                            profilePic={profilePic}
                            firstNameState={firstNameState}
                            setFirstNameState={setFirstNameState}
                            lastNameState={lastNameState}
                            setLastNameState={setLastNameState}
                            companyState={companyState}
                            setCompanyState={setCompanyState}
                            birthdayState={birthdayState}
                            setBirthdayState={setBirthdayState}
                            emailState={emailState}
                            setEmailState={setEmailState}
                            cellPhoneState={cellPhoneState}
                            setCellPhoneState={setCellPhoneState}
                            secondaryPhoneState={secondaryPhoneState}
                            setSecondaryPhoneState={setSecondaryPhoneState}
                            shippingAddressState={shippingAddressState}
                            setShippingAddressState={setShippingAddressState}
                            globalEdit={globalEdit}
                            handleImageUpload={handleImageUpload}
                            uploading={uploading}
                            progress={progress}
                            deleteImage={deleteImage}
                            formatPhoneNumber={formatPhoneNumber}
                            confirmDelete={confirmDelete}
                            setConfirmDelete={setConfirmDelete}
                            userData={userData}
                        />
                    </GridItem>
                    {userData.userType !== "IsoAdmin" && userData.userType !== "SubIso" ?
                    <>
                        <GridItem xs={4} className={classes.tighterGrid}>
                            <GatewayPage
                                classes={classes}
                                gateway={gateway}
                                setGateway={setGateway}
                                globalEdit={globalEdit}
                                apiInfo={apiInfo}
                                setApiInfo={setApiInfo}
                                docId={docId}
                                loader={loader}
                                userData={userData}
                            />
                        </GridItem>
                        <GridItem xs={8} >
                            <BillingInfo
                                globalEdit={globalEdit}
                                billingEdit={billingEdit}
                                setBillingEdit={setBillingEdit}
                                cardNumber={cardNumber}
                                setCardNumber={setCardNumber}
                                expDate={expDate}
                                setExpDate={setExpDate}
                                nameOnCard={nameOnCard}
                                setNameOnCard={setNameOnCard}
                                billingAddress={billingAddress}
                                setBillingAddress={setBillingAddress}
                                asDefault={asDefault}
                                setAsDefault={setAsDefault}
                                newPayment={newPayment}
                                setNewPayment={setNewPayment}
                                typeOfPayment={typeOfPayment}
                                setTypeOfPayment={setTypeOfPayment}
                            />
                        </GridItem>
                        </>     
                        :
                        <>
                            <GridItem xs={4} className={classes.tighterGrid}>
                                <GatewayPage
                                    gateway={gateway}
                                    setGateway={setGateway}
                                    globalEdit={globalEdit}
                                    apiInfo={apiInfo}
                                    setApiInfo={setApiInfo}
                                    docId={docId}
                                    loader={loader}
                                    userData={userData}
                                />
                            </GridItem>

                            <GridItem xs={4} className={classes.tighterGrid}>
                                <PublicCalculator
                                globalEdit={globalEdit}
                                userData={userData}
                                defaultCalcBasisPts={defaultCalcBasisPts}
                                setDefaultCalcBasisPts={setDefaultCalcBasisPts}
                                />
                            </GridItem>

                            <GridItem xs={4} className={classes.tighterGrid}>
                                <PointsSetup
                                globalEdit={globalEdit}
                                userData={userData}
                                ptsSchedule={ptsSchedule}
                                setPtsSchedule={setPtsSchedule}
                                autoApprove={autoApprove}
                                setAutoApprove={setAutoApprove}
                                lockSchedule={lockSchedule}
                                setLockSchedule={setLockSchedule}
                                />
                            </GridItem>
            </>
          }

          {(userData && userData.userType === "IsoAdmin") ||
          userData.userType === "SubIso" ? (
            <>
              <GridItem xs={12} style={{ marginTop: "15px" }}>
                <Card className={classes.profileCardStyling}>
                  <GridContainer>
                    <GridItem
                      xs={4}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography variant="h2" className={classes.cardHeader}>
                        User Table
                      </Typography>

                      {userTypeArrFilter.length > 0 ? (
                        <Typography
                          className={classes.cardHeader}
                          style={{ marginLeft: "10px" }}
                        >
                          {userTypeArrFilter.length < 4 &&
                          merchantDataArr.length > 0
                            ? " " +
                              merchantDataArr.length +
                              " " +
                              userTypeArrFilter
                            : null}
                        </Typography>
                      ) : null}

                      <Button
                        onClick={() => setAddModal(true)}
                        className={classes.editButton}
                        style={{
                          background: "#E2A400",
                          width: "250px",
                          height: "35px",
                          marginTop: "0px",
                        }}
                      >
                        <Add
                          style={{ marginRight: "10px", marginTop: "-5px" }}
                        />{" "}
                        Invite User
                      </Button>
                    </GridItem>

                    <GridItem xs={8}>
                      <DateRange
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        getIsoAdminReport={getIsoAdminReport}
                        csvData={isoAdminReportData}
                        csvLinkRef={csvLinkRef}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Filter
                        statusDropdown={statusDropdown}
                        setStatusDropdown={setStatusDropdown}
                        ascDropdown={ascDropdown}
                        setAscDropdown={setAscDropdown}
                        searchString={searchString}
                        setSearchString={setSearchString}
                        statusArr={statusArr}
                        ascArr={ascArr}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        entriesArr={entriesArr}
                        handleSearch={handleSearch}
                        csvData={merchantDataArr}
                        header={headersISO}
                        setEndIndex={setEndIndex}
                        handleFilter={handleFilter}
                        resetEmptyData={resetEmptyData}
                        emptyData={emptyData}
                        userTypeArrFilter={userTypeArrFilter}
                        handleUserTypeFilter={handleUserTypeFilter}
                        userTypeArr={userTypeArr}
                      />
                    </GridItem>

                    <GridItem xs={12}>
                      <Table
                        data={merchantDataArr}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        index={index}
                        setIndex={setIndex}
                        endIndex={endIndex}
                        setEndIndex={setEndIndex}
                        itemsPerPage={itemsPerPage}
                        handlePageClick={handlePageClick}
                        history={history}
                        copyArr={copyArr}
                      />
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </>
          ) : userData.userType.includes("Merchants") ? (
            <>
              <GridItem xs={12} style={{ marginTop: "15px" }}>
                <Card className={classes.profileCardStyling}>
                  <GridContainer>
                    <GridItem xs={12} style={{ marginBottom: "10px" }}>
                      <Typography variant="h2" className={classes.cardHeader}>
                        Merchant Report
                      </Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <MerchantDataFilter
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                        handleReportToggle={handleReportToggle}
                        reportType={reportType}
                        setReportType={setReportType}
                        dateFilter={dateFilter}
                        setDateFilter={setDateFilter}
                        increaseDate={increaseDate}
                        decreaseDate={decreaseDate}
                        reportSelections={reportArr}
                        headers={headers}
                        csvData={reportData}
                        points={points}
                      />
                    </GridItem>

                    <GridItem xs={12}>
                      <MerchantDataTable
                        data={zippedArr}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        index={index}
                        setIndex={setIndex}
                        endIndex={endIndex}
                        setEndIndex={setEndIndex}
                        itemsPerPage={itemsPerPage}
                        handlePageClick={handlePageClick}
                        history={history}
                        headers={headers}
                        docId={merchDocId}
                      />
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </>
          ) : null}
        </GridContainer>
      </Hidden>

      <Dialog
        className={classes.requestModal}
        open={emailSent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card className={classes.confirmModalCard}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeader}>
                Email has been sent to user to reset their password.{" "}
              </h5>
            </GridItem>
            <GridItem
              xs={12}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Button
                className={classes.uploadButton}
                onClick={() => {
                  setEmailSent(false);
                  history.push("/admin/dashboard");
                }}
              >
                <Close style={{ marginRight: "10px" }} /> Close
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </Dialog>

      <Dialog
        className={classes.addUserModal}
        open={addModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AddModal
          classes={classes}
          merchantId={modalMerchantId}
          setMerchantId={setModalMerchantId}
          userBasisPts={modalUserBasisPts}
          setUserBasisPts={setModalUserBasisPts}
          signUpBonus={modalSignUpBonus}
          setSignUpBonus={setModalSignUpBonus}
          username={modalUsername}
          setUsername={setModalUsername}
          apiUsername={modalApiUserName}
          setApiUsername={setModalApiUsername}
          apiPassword={modalApiPassword}
          setApiPassword={setModalApiPassword}
          firstNameState={modalFirstName}
          setFirstNameState={setModalFirstName}
          lastNameState={modalLastName}
          setLastNameState={setModalLastName}
          companyState={modalCompanyName}
          setCompanyState={setModalCompanyName}
          ein={modalEin}
          setEin={setModalEin}
          notes={modalNotes}
          setNotes={setModalNotes}
          processorName={modalProcessorName}
          setProcessorName={setModalProcessorName}
          processorPhone={modalProcessorPhone}
          setProcessorPhone={setModalProcessorPhone}
          processorEmail={modalProcessorEmail}
          setProcessorEmail={setModalProcessorEmail}
          processorMid={modalprocessorMid}
          setProcessorMid={setModalProcessorMid}
          emailState={modalEmail}
          setEmailState={setModalEmail}
          cellPhoneState={modalCellPhone}
          setCellPhoneState={setModalCellPhone}
          secondaryPhoneState={modalSecondaryPhone}
          setSecondaryPhoneState={setModalSecondaryPhone}
          addressState={modalAddressState}
          setAddressState={setModalAddressState}
          handleSelect={handleSelect}
          processorArr={modalProcessorArr}
          errors={modalErrors}
          type={modalType}
          setType={setModalType}
          typeArr={modalTypeArr}
          resetState={resetState}
          addModal={addModal}
          setAddModal={setAddModal}
          sendCustomerEmail={sendCustomerEmail}
          userData={userData}
          accountId={accountId}
          setAccountId={setAccountId}
        />
      </Dialog>
    </div>
  )
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, location) {
  return {
    editState: location.history.location.state?.editState,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)
      ),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
    deleteUserPhoto: (id) => dispatch(deleteUserPhoto(id)),
    deleteUserLogo: (id) => dispatch(deleteUserLogo(id)),
    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
    startPasswordReset: (email) =>
      dispatch(AuthActions.startPasswordReset(email)),
    logout: () => dispatch(AuthActions.startLogout()),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(UserProfile);
