import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import { NavLink } from "react-router-dom";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

// core components
import Button from "components/CustomButtons/Button.js";

import { connect } from 'react-redux';
import compose from 'recompose/compose';

import AuthActions from 'store/actions/auth';

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

export class ProfilePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openProfile: false,
        };
    }

    handleClickProfile(event) {
        if (this.state.openProfile && this.state.openProfile.contains(event.target)) {
            this.setState({ openProfile: false });
        } else {
            this.setState({ openProfile: event.currentTarget });
        }
    };

    handleCloseProfile() {
        this.setState({ openProfile: null });
    };

    render() {

        const { rtlActive, classes } = this.props;
        const { openProfile } = this.state;

        const managerClasses = classNames({
            [classes.managerClasses]: true
        });

        const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
            [classes.dropdownItemRTL]: rtlActive
        });

        return (
            <div className={managerClasses}>
                <Button
                    color="transparent"
                    aria-label="Person"
                    justIcon
                    aria-owns={openProfile ? "profile-menu-list" : null}
                    aria-haspopup="true"
                    onClick={e => this.handleClickProfile(e)}
                    className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                    muiClasses={{
                        label: rtlActive ? classes.labelRTL : ""
                    }}
                >
                    <Person
                        className={
                            classes.headerLinksSvg +
                            " " +
                            (rtlActive
                                ? classes.links + " " + classes.linksRTL
                                : classes.links)
                        }
                    />
                    <Hidden mdUp implementation="css">
                        <span onClick={e => this.handleClickProfile(e)} className={classes.linkText}>
                            {rtlActive ? "الملف الشخصي" : "Profile"}
                        </span>
                    </Hidden>
                </Button>
                <Popper
                    open={Boolean(openProfile)}
                    anchorEl={openProfile}
                    transition
                    disablePortal
                    placement="bottom"
                    className={classNames({
                        [classes.popperClose]: !openProfile,
                        [classes.popperResponsive]: true,
                        [classes.popperNav]: true
                    })}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            id="profile-menu-list"
                            style={{ transformOrigin: "0 0 0" }}
                        >
                            <Paper className={classes.dropdown}>
                                <ClickAwayListener onClickAway={e => this.handleCloseProfile(e)}>
                                    <MenuList role="menu">
                                        <NavLink to="/admin/user-page">
                                            <MenuItem
                                                onClick={e => this.handleCloseProfile(e)}
                                                className={dropdownItem}
                                            >
                                                {rtlActive ? "الملف الشخصي" : "Profile"}
                                            </MenuItem>
                                        </NavLink>
                                        <MenuItem
                                            onClick={e => this.handleCloseProfile(e)}
                                            className={dropdownItem}
                                        >
                                            {rtlActive ? "الإعدادات" : "Settings"}
                                        </MenuItem>
                                        <Divider light />
                                        <MenuItem
                                            onClick={e => { this.handleCloseProfile(e); this.props.logout(); }}
                                            className={dropdownItem}
                                        >
                                            {rtlActive ? "الخروج" : "Log out"}
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
}

ProfilePopup.propTypes = {
    rtlActive: PropTypes.bool
};


function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(AuthActions.startLogout())
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(ProfilePopup);