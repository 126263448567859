/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import dotenv from "dotenv";
import compose from "recompose/compose";
import { connect } from "react-redux";
import axios from "axios";

import Style from "assets/jss/material-dashboard-pro-react/views/rewardsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import "../components/googleMap.css";


let map;
dotenv.config();


const Map = (props) => {
    const { classes, hotelLongitude, hotelLatitude } = props;

    const [gotAddress, setGotAddress] = useState(false);

    useEffect(() => {
        if (!gotAddress) {
            const start = () => {
                const loader = new Loader({
                    apiKey: process.env.REACT_APP_FB_API_KEY,
                    version: "weekly",
                });
                return axios
                    .get("https://geolocation-db.com/json/")
                    .then((response) => {
                        const { latitude, longitude } = response.data;
                        if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition((position) => {
                                loader.load().then((google) => {
                                    map = new google.maps.Map(document.getElementById("map"), {
                                        center: { lat: hotelLatitude, lng: hotelLongitude },
                                        zoom: 15,
                                    });

                                    // let infoWindow = new google.maps.InfoWindow({
                                    //     // content: "Click the map to get Lat/Lng!",
                                    //     position: { lat: position.coords.latitude, lng: position.coords.longitude },
                                    // });

                                    var infoWindow = new google.maps.InfoWindow();

                                    let marker = new google.maps.Marker({
                                        position: { lat: hotelLatitude, lng: hotelLongitude},
                                        map: map
                                    })
                                    infoWindow.open(map);

                                    map.addListener("click", (mapsMouseEvent) => {
                                        // Close the current InfoWindow.
                                        infoWindow.close();

                                        // Create a new a marker.
                                        // setPinnedLocation(mapsMouseEvent.latLng)
                                        if (marker && marker.setMap) {
                                            marker.setMap(null);
                                        }

                                        marker = new google.maps.Marker({
                                            position: mapsMouseEvent.latLng,
                                            map: map
                                        })
                                    });
                                });
                            })
                        }
                        else {
                            loader.load().then((google) => {
                                map = new google.maps.Map(document.getElementById("map"), {
                                    center: { lat: latitude, lng: longitude },
                                    zoom: 10,
                                });

                                let infoWindow = new google.maps.InfoWindow({
                                    content: "Click the map to get Lat/Lng!",
                                    position: { lat: latitude, lng: longitude },
                                });

                                let marker = new google.maps.Marker({
                                    position: { lat: position.coords.latitude, lng: position.coords.longitude },
                                    map: map
                                })

                                
                                infoWindow.open(map);


                                map.addListener("click", (mapsMouseEvent) => {
                                    // Close the current InfoWindow.
                                    infoWindow.close();

                                    // Create a new InfoWindow.
                                    //   setPinnedLocation(mapsMouseEvent.latLng)
                                    if (marker && marker.setMap) {
                                        marker.setMap(null);
                                    }

                                    // marker = new google.maps.Marker({
                                    //     position: mapsMouseEvent.latLng,
                                    //     map: map
                                    // })
                                });
                                setGotAddress(true)
                            });
                        }
                    });
            };
            start();
        }
    }, []);

    return (
        <div id="map" style={{ zIndex: 3, height: "400px" }}></div>

    );
};

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({});
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Map);
