/*eslint-disable*/
const path = window.location.pathname

export const initState = {
  path
}


export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_PATHNAME':
      var toReturn = {...state}
      toReturn.path = action.path
      return toReturn

    default:
      return state
  }
}
