import {
  actionsForQueryDialog,
  actionsForQueryForm,
  actionsForEditorForm,
  actionsForNewForm,
} from 'store/actions/recordset';

import { actionsForDialog } from 'store/actions/dialog';
import { actionsForForm } from 'store/actions/form';
import { config } from 'store/reducers/recordsets/users';

import actionsForRecordset from 'store/actions/recordsets/users';

export default {
  recordset: actionsForRecordset,
  queryDialog: actionsForQueryDialog(actionsForRecordset, actionsForDialog('users/query'), actionsForForm(config, 'users/query')),
  queryForm: actionsForQueryForm(actionsForRecordset, actionsForDialog('users/query'), actionsForForm(config, 'users/query')),
  editorForm: actionsForEditorForm(actionsForRecordset, actionsForForm(config, 'users/editor')),
  newForm: actionsForNewForm(actionsForRecordset, actionsForForm(config, 'users/new')),
};