import {
  container,
  cardTitle,
  blackColor,
  hexToRgb,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const registerPageStyle = (theme) => ({
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: "center",

  },
  container: {
    ...container,
    position: "relative",
    zIndex: "3",
  },
  cardSignup: {
    borderRadius: "7px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    marginBottom: "100px",
    padding: "40px 0px",
    marginTop: "15vh"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  socialTitle: {
    fontSize: "18px"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  customFormControlClasses: {
    margin: "0 12px"
  },
  checkboxLabelControl: {
    margin: "0"
  },
  checkboxLabel: {
    fontSize: "0.875rem",
    marginLeft: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)"
  },
  mobileMainContainer: {
    position: "relative",
    width: "85%",
    height: "100%",
    background: "rgba(249, 249, 249, 1) !important",
    margin: "60px auto 0px auto",
    padding: "10px",
    top:"225px",
    [theme.breakpoints.down('380')]: {
      top: "108px",

    }
  },
  mainContainer: {
    width: "52%",
    maxWidth: "40rem",
    minHeight: "auto",
    maxHeight: "32rem",
    background: "rgba(249, 249, 249, 1)",
    position: "absolute",
    right: "3%",
    bottom: "30%",
    borderRadius: "10px",
    padding:"10px",
    [theme.breakpoints.down('md')]: {
      bottom: "10%",
    },
    [theme.breakpoints.up('lg')]: {
      bottom: "20%"
    },
    [theme.breakpoints.up('xl')]: {
      bottom: "20%",
    }
  },
  cardHeaderTitle: {
    fontSize: "25px",
    fontWeight: 700,
    fontFamily: "Josefin Sans",
    marginBottom: "-25px",
    color: "#004253"
  },
  cardHeaderTitleMobile: {
    fontSize: "25px",
    fontWeight: 700,
    fontFamily: "Josefin Sans",
    marginBottom: "-25px",
    color: "#004253"
  },
  textFieldLabel: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Josefin Sans",
    lineHeight: "16px",
    color: "#004253"
  },
  addIdButtonMobile: {
    background: "#06667C",
    color: "#FFFFFF",
    borderRadius: "8px",
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "capitalize",
    marginTop: "15px",
    height: "44px",
    width:"150px",
    "&:hover": {
      background: "#06667C",
      color: "#FFFFFF",
    }
  },
  addIdButton: {
    background: "#06667C",
    color: "#FFFFFF",
    borderRadius: "8px",
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "capitalize",
    marginTop: "20px",
    height: "44px",
    "&:hover": {
      background: "#06667C",
      color: "#FFFFFF",
    },
    width:"220px",
    [theme.breakpoints.down('md')]: {
      width:"165px",
    },
  },
  textField: {
    width: "100%",
    background: "white",
    height: "35px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "1px solid #929292",
    paddingLeft: "10px",
    borderRadius: "4px",
    marginTop: "-10px",
    "& .Mui-error": {
      fontFamily: "Josefin Sans",
      fontWeight: 600,
      // marginLeft: "-11px"
    },
  },
  textFieldMobile: {
    width: "100%",
    background: "white",
    height: "35px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "1px solid #929292",
    paddingLeft: "10px",
    borderRadius: "4px",
    marginTop: "0px",
    "& .Mui-error": {
      fontFamily: "Josefin Sans",
      fontWeight: 600,
      // marginLeft: "-11px"
    },
  },
  resendEmail: {
    fontFamily: "Josefin Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#1A405F"
  },
  underlineEmail: {
    fontFamily: "Josefin Sans",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  checkBoxValidation: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "0.75rem",
    color: "#f44336"
  },
  gridItemMobileMarginTop: {
    marginTop: "15px"
  },
  captchaDiv:{
    marginTop: "15px",
    display: "flex",
    justifyContent:"center",
    [theme.breakpoints.down('425')]: {
      transform: "scale(0.97)",
    },
    [theme.breakpoints.down('380')]: {
      transform: "scale(0.85)",
    },
  },
  linkContainerMobile: {
    width: "85%",
    maxWidth: "40rem",
    minHeight: "auto",
    maxHeight: "32rem",
    position: "absolute",
    right: "8%",
    bottom: "4%",
    paddingBottom: "15px",
    paddingTop: "15px",
    [theme.breakpoints.down('392')]: {
      bottom: "0%"
    },
  },
  termsStyle: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "white",
    "&:hover": {
      cursor: "pointer",
    },
    textDecoration: "underline"
  },
});

export default registerPageStyle;
