const formStyle = {
    root: {

    },
    formContainer: {
        backgroundColor: "#fff",
        padding: "10px",
        // margin: "25px 0 0 -12px", 
        borderRadius: "5px",
    },
    cancelButton: {
        "&:hover": {
            backgroundColor: "#dc2f02",
        },
        backgroundColor: "#e07a5f",
    },
    submitButton: {
        "&:hover": {
            backgroundColor: "#40916c",
        },
        backgroundColor: "#81b29a",
    }
};

export default formStyle;
