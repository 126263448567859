import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import compose from 'recompose/compose';

// styling
import { Button, withStyles } from "@material-ui/core";
import Style from "assets/jss/material-dashboard-pro-react/views/cartStyle";

// core components
import { Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';

export function SummaryPage(props) {
    const {
        classes,
        cart,
        totalAmount,
        numberFormatterDecimals,
        numberFormatter,
        history,
        user
    } = props;

    return (
        <Card className={classes.card}>
            <GridContainer>
                <GridItem xs={12}>
                    <Typography className={classes.totalCost}>
                        Payment Summary
                    </Typography>
                </GridItem>
            </GridContainer>

            {cart.map((data, index) => (
                data.checked === true ?
                    <div className={classes.borderDiv} key={index}>
                        <GridContainer>
                            <GridItem xs={8}>
                                <Typography className={classes.itemSummary}>
                                    {data.rewardName}
                                </Typography>
                            </GridItem>

                            <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Typography className={classes.itemPriceSummary}>
                                    {numberFormatter.format(data.salePrice * 100)}
                                </Typography>
                            </GridItem>
                        </GridContainer>
                    </div>
                    : null
            ))}

            <div className={classes.borderDiv}>
                <GridContainer>
                    <GridItem xs={8}>
                        <Typography className={classes.tripTotal}>
                            Total
                        </Typography>

                    </GridItem>

                    {/* <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography className={classes.totalCost}>
                            ${numberFormatterDecimals.format(totalAmount)}
                        </Typography>
                    </GridItem>

                    <GridItem xs={8} /> */}

                    <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography className={classes.totalCostPts} style={{ whiteSpace: "nowrap" }}>
                            {totalAmount ? numberFormatter.format(totalAmount * 100) : "0"} points
                        </Typography>
                    </GridItem>
                </GridContainer>
            </div>

            <GridContainer style={{ marginTop: "25px" }}>
                {cart.length > 0 ?
                    <>
                        <GridItem xs={4}>
                            <Typography className={classes.tripTotal}>
                                You will pay
                            </Typography>
                        </GridItem>

                        <GridItem xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography className={classes.totalCost}>
                                {/* 0 points + ${numberFormatterDecimals.format(totalAmount)} */}
                                {totalAmount ? numberFormatter.format(totalAmount * 100) : "0"} points
                            </Typography>
                        </GridItem>
                    </>
                    :
                    null}


                <GridItem xs={6} style={{ marginTop: "15px" }}>
                    <Typography className={classes.remainingPts}>
                        Remaining Points
                    </Typography>
                </GridItem>

                <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
                    <Typography className={classes.remainingPts}>
                        {user.approvedPts ? user.approvedPts.toLocaleString(): 0} Points
                    </Typography>
                </GridItem>

                <GridItem xs={12}>
                    {cart.length > 0 ?

                        <Button
                            className={classes.checkoutButton}
                            onClick={() => {
                                if (user.IsoAdminCompanyName) {
                                    history.push(
                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/checkout`,
                                        {
                                            totalAmount: totalAmount
                                        }
                                    )
                                } else {
                                    history.push(
                                        '/merchant/checkout',
                                        {
                                            totalAmount: totalAmount
                                        }
                                    )
                                }

                            }}
                        >
                            Proceed to checkout
                        </Button>
                        :
                        <Button
                            className={classes.checkoutButton}
                        >
                            No items to checkout
                        </Button>
                    }
                </GridItem>
            </GridContainer>

        </Card>

    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(SummaryPage);

