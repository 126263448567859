import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import moment from "moment"

// core components
import { MenuItem, Select, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

//styling
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/isoAdminDashboardStyle';
import invMerchant from 'assets/img/tuzo/InviteMerchant.png'
// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/tuzo/icons/EditIcon.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { Add } from '@material-ui/icons';


const headers = [
    "Points",
    "Name",
    "Company",
    "Email",
    "Status",
    "Last Login",
    "Actions",
];

function DashboardTable(props) {
    const {
        classes,
        reservations,
        history,
        data,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        handlePageClick,
        userCompany,
        copyArr
    } = props;

    useEffect(() => {
        data.slice(index, endIndex);
    }, [index]);
    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th className={classes.tableHeaders} key={key}>
                            {key}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderEmptyTable = () => {
        return (
            <GridContainer>
                <GridItem xs={4}>
                    <img src={invMerchant} className={classes.emptyMerch} />
                </GridItem>

                <GridItem xs={8} style={{ display: "flex", justifyContent: "center", alignItems: "left", flexDirection: "column" }}>
                    <Typography className={classes.invMerchHeader}>
                        Invite Merchants To Tuzo
                    </Typography>

                    <Typography className={classes.invMerchDesc}>
                        Before we can create any tables, we’ll first need to get some merchants in here!
                    </Typography>

                    <Button
                        style={{ marginTop: "20px" }}
                        className={classes.addButton}
                        onClick={() => {
                            history.push(`/iso-admin/${userCompany}/add-merchant`)
                        }}
                    >
                        <Add /> Invite Merchant
                    </Button>
                </GridItem>
            </GridContainer>
        )
    }

    const renderTableData = () => {
        return data
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <tr
                        className={index % 2 === 0 ? classes.tableRow : classes.tableRowOdd}
                        key={d.name}
                    >
                        <td className={classes.tableCell}>
                            {d.approvedPts && d.status === "Active" ?
                                d.approvedPts :
                                d.tuzoPoints >= 0 && d.status === "Active" ? d.tuzoPoints :
                                    !d.approvedPts && d.tuzoPoints && d.status === "Pending" ? d.tuzoPoints
                                        :
                                        <div className={classes.pendingX}>X</div>
                            }
                        </td>
                        <td className={classes.tableCell} style={{ fontWeight: 700 }}>{d.firstName + " " + d.lastName}</td>
                        <td className={classes.tableCell}>{d.companyName}</td>
                        <td className={classes.tableCell}>{d.email}</td>
                        <td className={classes.tableCell}>{d.status}</td>
                        <td className={classes.tableCell}>
                            {d.lastLoggedIn && d.status !== "Pending" ?
                                moment.unix(d.lastLoggedIn.seconds).format("MM/DD/YYYY h:mm a") :
                                <div className={classes.pendingX}>X</div>
                            }

                        </td>

                        <td className={classes.tableCell}>
                            {d.status !== "Active" ?
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "space-evenly",
                                    }}
                                >
                                    <Tooltip
                                        id="tooltip-top"
                                        title="View"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <Button
                                            style={{ background: "#FECD2C" }}
                                            className={classes.actionButton}
                                            onClick={() => {
                                                if (d.id) {
                                                    history.push(`/iso-admin/${userCompany.replace(/ /g, '').toLowerCase()}/temp-user-profile/${d.id}`)
                                                } else {
                                                    history.push(`/iso-admin/${userCompany.replace(/ /g, '').toLowerCase()}/temp-user-profile/${d.uid}`)
                                                }

                                            }}
                                        >
                                            <ViewIcon />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="Edit"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <Button
                                            className={classes.actionButton}
                                            style={{ background: "#E2A400" }}
                                            onClick={() => {
                                                if (d.id) {
                                                    history.push(`/iso-admin/${userCompany.replace(/ /g, '').toLowerCase()}/edit-temp-user-profile/${d.id}`,
                                                        {
                                                            editState: true
                                                        }
                                                    )
                                                }else{
                                                    history.push(`/iso-admin/${userCompany.replace(/ /g, '').toLowerCase()}/edit-temp-user-profile/${d.uid}`,
                                                    {
                                                        editState: true
                                                    }
                                                )
                                                }

                                            }}

                                        >
                                            <EditIcon />
                                        </Button>
                                    </Tooltip>
                                </div>
                                :
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "space-evenly",
                                    }}
                                >
                                    <Tooltip
                                        id="tooltip-top"
                                        title="View"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <Button
                                            style={{ background: "#FECD2C" }}
                                            className={classes.actionButton}
                                            onClick={() => history.push(`/iso-admin/${userCompany.replace(/ /g, '').toLowerCase()}/user-profile/${d.uid}`)}
                                        >
                                            <ViewIcon />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="Edit"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <Button
                                            className={classes.actionButton}
                                            style={{ background: "#E2A400" }}
                                            onClick={() => {
                                                history.push(`/iso-admin/${userCompany.replace(/ /g, '').toLowerCase()}/edit-user-profile/${d.uid}`,
                                                    {
                                                        editState: true
                                                    }
                                                )
                                            }}

                                        >
                                            <EditIcon />
                                        </Button>
                                    </Tooltip>
                                </div>
                            }
                        </td>
                    </tr>
                );
            });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <table className={classes.rewardsTable} style={{ marginBottom: "10px", marginTop: "10px" }}>
                    {data.length > 0 || copyArr.length > 0 ?
                        <tbody>
                            {renderTableHeader()}
                            {renderTableData()}
                        </tbody>
                        :
                        renderEmptyTable()
                    }
                </table>
                {data.length > 0 ?
                    <GridContainer style={{ marginBottom: "-15px" }}>
                        <GridItem xs={10} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                            <h5
                                className={classes.indexes}
                            >
                                {index + 1} - {endIndex} of{" "}
                                {Math.ceil(data.length)}
                            </h5>
                        </GridItem>
                        <GridItem xs={2}>
                            <ReactPaginate
                                paginationSize={0}
                                onPageChange={handlePageClick}
                                pageCount={Math.ceil(data.length / itemsPerPage)}
                                nextLabel={
                                    <ArrowRight
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "white",
                                        }}
                                    />
                                }
                                previousLabel={
                                    <ArrowLeft
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "white",
                                        }}
                                    />
                                }
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </GridItem>
                    </GridContainer>
                    :
                    null
                }

            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        userCompany: state.auth.user.companyName
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(dashboardStyle)
)(React.memo(DashboardTable));
