/*eslint-disable*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { FooterText } from "./FooterText";
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Button from 'components/CustomButtons/Button';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PersonIcon from '@material-ui/icons/Person';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ProfileIcon from 'assets/img/market-me/profileIcon.png'
import GaugeLogo from 'assets/img/market-me/gaugeLogo.png'
import CreditScoreLogo from 'assets/img/market-me/CreditScoreLogo.png'
import PlayWalletLogo from 'assets/img/market-me/playWalletLogo.png'
import AnalyticsIcon from 'assets/img/market-me/analyticsLogo.png'
import DocumentIcon from 'assets/img/market-me/documentIcon.png'
import LeadsIcon from 'assets/img/market-me/leadsLogo.png'
import CustomersIcon from 'assets/img/market-me/customersLogo.png'
import SelectedAnalyticsLogo from 'assets/img/market-me/selectedAnalyticsLogo.png'
import SelectedCustomerDocumentsIcon from 'assets/img/market-me/selectedCustomerDocumentsIcon.png'
import SelectedProfileIcon from 'assets/img/market-me/selectedProfileIcon.png'

import SelectedCustomersIcon from 'assets/img/market-me/selectedCustomersIcon.png'
import SelectedLeadsIcon from 'assets/img/market-me/selectedLeadsIcon.png'
import SelectedWalletLogo from 'assets/img/market-me/selectedWalletLogo.png'
import SelectedSubscriptionsLogo from 'assets/img/market-me/selectedSubscriptionsLogo.png'
import SelectedGaugeIcon from 'assets/img/market-me/selectedGaugeIcon.png'

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

import { setPathname } from 'store/actions/windowActions'



function Footer({ ...props }) {
  const { classes, fluid, white, rtlActive, pushChat, uid, user, path } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  var anchor =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white,
    });
  const [dataLoaded, setDataLoaded] = useState(false)
  const [pathName, setPathName] = useState(window.location.pathname)
  console.log('pathNamepathName', pathName)

  useEffect(() => {
    if (user.userType && !dataLoaded) {
      setDataLoaded(true)
    }
  }, [user])

  useEffect(() => {
    setPathName(path)
  }, [path])

  return (
    dataLoaded ? (
      <footer className={classes.footer} style={{ width: '100%', backgroundColor: "#2a2a2a" }}>
        <div className={container}>
          <div
            className={classes.right}
            style={{ textAlign: 'center', width: '100%', padding: '5px' }}
          >
            <GridContainer style={{ display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
              {/* <GridItem xs={2}>
                <Link to='/profile' >
                  <PersonIcon style={{ color: "#00C1F3", fontSize: "30px"}} />
                </Link>
              </GridItem> */}
              {pathName === '/admin/user-page' ? (
                <GridItem onClick={() => props.setPathname('/admin/user-page')} xs={2}>
                  <Link to='/admin/user-page'>
                    <img src={SelectedProfileIcon} style={{ width: "20px" }} />
                  </Link>
                </GridItem>
              ) : (
                <GridItem onClick={() => props.setPathname('/admin/user-page')} xs={2}>
                  <Link to='/admin/user-page'>
                    <img src={ProfileIcon} style={{ width: "20px" }} />
                  </Link>
                </GridItem>
              )}

              <GridItem xs={2}>
                {user.userType === 'Data Furnisher' ? (

                  pathName === '/admin/client-documents' ? (
                    <Link onClick={() => props.setPathname('/admin/client-documents')} to='/admin/client-documents'>
                      <img src={SelectedCustomerDocumentsIcon} style={{ width: "25px" }} />
                    </Link>
                  ) : (
                    <Link onClick={() => props.setPathname('/admin/client-documents')} to='/admin/client-documents'>
                      <img src={DocumentIcon} style={{ width: "25px" }} />
                    </Link>
                  )
                ) : (
                  pathName === '/admin/credit-score' ? (
                    <Link onClick={() => props.setPathname('/admin/credit-score')} to='/admin/credit-score'>
                      <img src={SelectedGaugeIcon} style={{ width: "30px" }} />
                    </Link>
                    ) : (
                      <Link onClick={() => props.setPathname('/admin/credit-score')} to='/admin/credit-score'>
                        <img src={GaugeLogo} style={{ width: "30px" }} />
                      </Link>
                    )
                )}
              </GridItem>
              <GridItem xs={2}>

                {user.userType === 'Data Furnisher' ? (
                 pathName === '/admin/leads/explorer' ? (
                    <Link onClick={() => props.setPathname('/admin/leads/explorer')} to='/admin/leads/explorer'>
                      <img src={SelectedLeadsIcon} style={{ width: "20px" }} />
                    </Link>
                  ) : (
                    <Link onClick={() => props.setPathname('/admin/leads/explorer')} to='/admin/leads/explorer'>
                      <img src={LeadsIcon} style={{ width: "20px" }} />
                    </Link>
                  )
                ) : (
                  pathName === '/admin/digital-assets' ? (
                    <Link onClick={() => props.setPathname('/admin/digital-assets')}  to='/admin/digital-assets' >
                    <img src={SelectedWalletLogo} style={{ width: "30px" }} />
                  </Link>
                  ) : (
                    <Link onClick={() => props.setPathname('/admin/digital-assets')} to='/admin/digital-assets' >
                    <img src={CreditScoreLogo} style={{ width: "30px" }} />
                  </Link>
                  )
                )}

              </GridItem>
              <GridItem xs={2}>
                {user.userType === 'Data Furnisher' ? (
                  pathName === '/admin/customers/explorer' ? (
                    <Link onClick={() => props.setPathname('/admin/customers/explorer')} to='/admin/customers/explorer' >
                      <img src={SelectedCustomersIcon} style={{ width: "30px" }} />
                    </Link>
                  ) : (
                    <Link onClick={() => props.setPathname('/admin/customers/explorer')} to='/admin/customers/explorer' >
                      <img src={CustomersIcon} style={{ width: "30px" }} />
                    </Link>
                  )
                ) : (
                  pathName === '/admin/subscriptions' ? (
                    <Link onClick={() => props.setPathname('/admin/subscriptions')} to='/admin/subscriptions' >
                    <img src={SelectedSubscriptionsLogo} style={{ width: "30px" }} />
                  </Link>
                  ) : (
                    <Link onClick={() => props.setPathname('/admin/subscriptions')}  to='/admin/subscriptions' >
                    <img src={PlayWalletLogo} style={{ width: "30px" }} />
                  </Link>
                  )
                )}
              </GridItem>
              <GridItem xs={2}>
                {pathName === '/admin/analytics' ? (
                  <Link onClick={() => props.setPathname('/admin/analytics')} to='/admin/analytics'>
                    <img src={SelectedAnalyticsLogo} style={{ width: "20px" }} />
                  </Link>
                ) : (
                  <Link onClick={() => props.setPathname('/admin/analytics')} to='/admin/analytics'>
                    <img src={AnalyticsIcon} style={{ width: "20px" }} />
                  </Link>
                )}
              </GridItem>
            </GridContainer>
            <FooterText anchor={anchor} rtlActive={rtlActive} />
          </div>
        </div>
      </footer>
    ) : (
      null
    )

  );
}


Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

function mapStateToProps(state) {
  console.log('statteeeeeeee', state.window.path)
  return {
    user: state.auth.user,
    path: state.window.path
  };
}

const mapDispatchToProps = (dispatch) => ({
  setPathname: (path) => dispatch(setPathname(path))
});


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(footerStyle)
)(Footer);
