import React, { useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import 'views/Pages/rewards/carousel.css';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardContent, Hidden, IconButton, Typography } from '@material-ui/core';

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/activityStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// icons
import { ReactComponent as CaretLeft } from "assets/img/tuzo/icons/CaretLeftBlue.svg";
import { ReactComponent as CaretRight } from "assets/img/tuzo/icons/CaretRightBlue.svg";
import { ReactComponent as Info } from "assets/img/tuzo/icons/Info.svg";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1900 },
        items: 3,
    },
    desktop: {
        breakpoint: { max: 1900, min: 1366 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1350, min: 1024 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 1000, min: 0 },
        items: 1,
    },
};

export function CarouselPage(props) {
    const {
        classes,
        rewardsArr,
        history,
        user
    } = props;

    const CustomRightArrow = ({ onClick }) => (
        <button
            className={classes.rightArrow}
            onClick={onClick}>
            <CaretRight />
        </button>
    );
    const CustomLeftArrow = ({ onClick }) => (
        <button
            className={classes.leftArrow}
            onClick={onClick}
        >
            <CaretLeft />
        </button>
    );

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <Carousel
                    autoPlay={false}
                    autoPlaySpeed={20000}
                    infinite={true}
                    swipeable={true}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    style={{ display: "flex" }}
                    arrows={true}
                    customLeftArrow={<CustomLeftArrow />}
                    customRightArrow={<CustomRightArrow />}
                >
                    {rewardsArr.map((data, index) => {
                        return (
                            <div style={{ display: "flex", justifyContent: "center" }} key={index}>
                                <Card className={classes.rewardsCardListStyling}>
                                    <CardContent style={{ padding: "0px" }}>
                                        <IconButton
                                            onClick={() => {
                                                if (user.IsoAdminCompanyName) {
                                                    history.push(
                                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }
                                                else {
                                                    history.push(
                                                        `/merchant/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }

                                            }}
                                        >
                                            <Info />
                                        </IconButton>
                                        <div
                                            onClick={() => {
                                                if (user.IsoAdminCompanyName) {
                                                    history.push(
                                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }
                                                else {
                                                    history.push(
                                                        `/merchant/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }

                                            }}
                                            className={classes.divHover}>
                                            <img
                                                src={data.mainImage}
                                                alt="picture here"
                                                className={classes.cardListImg}
                                            />

                                            <Typography variant="h2" className={classes.cardListTitleNoOverflow}>
                                                {(data.rewardName).length < 33 ? <br /> : null}
                                                {data.rewardName}
                                            </Typography>
                                        </div>


                                        <div>
                                            <Typography variant="h2" className={classes.cardListDesc}>
                                                Points Price
                                            </Typography>
                                            <Typography variant="h2" className={classes.cardListPts}>
                                                {(data.salePricePoints).toLocaleString()}
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                    })}
                </Carousel>
            </Hidden>

            <Hidden smDown implementation='css'>
                <Carousel
                    autoPlay={false}
                    autoPlaySpeed={20000}
                    infinite={true}
                    swipeable={true}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    style={{ display: "flex" }}
                    arrows={true}
                    customLeftArrow={<CustomLeftArrow />}
                    customRightArrow={<CustomRightArrow />}
                >
                    {rewardsArr.map((data, index) => {
                        return (
                            <div style={{ display: "flex", justifyContent: "center" }} key={index}>
                                <Card className={classes.rewardsCardListStyling}>
                                    <CardContent style={{ padding: "0px" }}>
                                        <IconButton
                                            onClick={() => {
                                                if (user.IsoAdminCompanyName) {
                                                    history.push(
                                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }
                                                else {
                                                    history.push(
                                                        `/merchant/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }

                                            }}
                                        >
                                            <Info />
                                        </IconButton>
                                        <div
                                            onClick={() => {
                                                if (user.IsoAdminCompanyName) {
                                                    history.push(
                                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }
                                                else {
                                                    history.push(
                                                        `/merchant/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }

                                            }}
                                            className={classes.divHover}>
                                            <img
                                                src={data.mainImage}
                                                alt="picture here"
                                                className={classes.cardListImg}
                                            />

                                            <Typography variant="h2" className={classes.cardListTitleNoOverflow}>
                                                {(data.rewardName).length < 33 ? <br /> : null}
                                                {data.rewardName}
                                            </Typography>
                                        </div>


                                        <div>
                                            <Typography variant="h2" className={classes.cardListDesc}>
                                                Points Price
                                            </Typography>
                                            <Typography variant="h2" className={classes.cardListPts}>
                                                {(data.salePricePoints).toLocaleString()}
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                    })}
                </Carousel>
            </Hidden>

        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(CarouselPage));
