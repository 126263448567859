const chipArrayStyle = {
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: "0.5em",
        margin: 0,
      },
      chip: {
        margin: "0.5em",
      },
}

export default chipArrayStyle;