import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isReady } from "store/reducers/system";
import loader from "assets/img/Tuzo-Preloader-Transparent.gif";

function RouteLoader({ fullScreen = false, loading, children }) {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (loading) {
      setShowLoader(true);
    } else {
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  return showLoader ? (
    <div style={styles.loaderContainer(fullScreen)}>
      <img
        src={loader}
        alt="Loading..."
        style={styles.loaderImage(fullScreen)}
      />
      {fullScreen && <div style={styles.loadingText}>Loading...</div>}
    </div>
  ) : (
    children
  );
}

const styles = {
  loaderContainer: (fullScreen) => ({
    position: "fixed",
    top: fullScreen ? 0 : 55,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: fullScreen ? "#E2A400" : "#eeeeee",
    zIndex: 2000,
  }),
  loaderImage: (fullScreen) => ({
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.7))",
    borderRadius: "8px",
    width: fullScreen ? 140 : 110,
    height: fullScreen ? 140 : 110,
  }),
  loadingText: {
    marginTop: 14,
    color: "black",
    fontSize: 22,
    fontFamily: "Josefin Sans",
    fontWeight: "800",
  },
};

function mapStateToProps(state) {
  return {
    loading: !isReady(state),
  };
}

export default connect(mapStateToProps)(RouteLoader);
