import React, { useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ReactPaginate from "react-paginate";
import "views/Pages/dashboard/components/pagination.css";
import moment from "moment"
// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/adStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import { Button, Tooltip, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";


export function AdsTable(props) {
    const {
        classes,
        history,
        headers,
        adsData,
        pageNumber,
        index,
        endIndex,
        itemsPerPage,
        handlePageClick
    } = props;

    useEffect(() => {
        adsData.slice(index, endIndex);
    }, [index]);

    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th className={classes.tableHeaders} key={index}>
                            {key}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        return adsData
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <tr key={index} >
                        <td className={classes.tableCell} >{d.adName}</td>
                        <td
                            className={classes.tableCell}
                            style={d.status === "Active" ? { color: "#56CA93" } : {}}
                        >
                            {d.status}
                        </td>
                        <td className={classes.tableCell} >{d.adLocation === "Travel" ? "Travel Rewards Banner" : "Merchant Activity Dashboard"}</td>
                        <td className={classes.tableCell}>{moment.unix(d.timestamp.seconds).format("MM/DD/YYYY hh:mm a")}</td>
                        <td className={classes.tableCell}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Tooltip
                                    id="tooltip-top"
                                    title="View"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        onClick={() => history.push(
                                            `/admin/ads/preview/${d.id}`,
                                            {
                                                adData: d
                                            }
                                        )}
                                        style={{ background: "#FECD2C" }}
                                        className={classes.actionButton}
                                    >
                                        <ViewIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Edit"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        onClick={() => history.push(`/admin/ads/edit/${d.id}`)}
                                        className={classes.actionButton}
                                        style={{ background: "#E2A400" }}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Delete"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#56CA93" }}
                                    >
                                        <TrashIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                )
            })
    }

    return (
        <GridContainer >
            <GridItem xs={12}>
                <table className={classes.adsTable} style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <tbody>
                        {renderTableHeader()}
                        {renderTableData()}
                    </tbody>
                </table>
                <GridContainer>
                    <GridItem xs={11} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                        <h5
                            className={classes.indexes}
                        >
                            {index + 1} - {endIndex} of{" "}
                            {Math.ceil(adsData.length)}
                        </h5>
                    </GridItem>
                    <GridItem xs={1}>
                        <ReactPaginate
                            forcePage={pageNumber}
                            paginationSize={0}
                            onPageChange={handlePageClick}
                            pageCount={Math.ceil(adsData.length / itemsPerPage)}
                            nextLabel={
                                <ArrowRight
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            previousLabel={
                                <ArrowLeft
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    )
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(AdsTable));