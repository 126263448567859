import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import compose from 'recompose/compose';

// styling
import { Button, Checkbox, FormControlLabel, Radio, TextField, withStyles } from "@material-ui/core";
import Style from "assets/jss/material-dashboard-pro-react/views/cartStyle";

// core components
import { Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';

export function RedeemPoints(props) {
    const {
        classes,
        totalAmount,
        numberFormatterDecimals,
        numberFormatter,
        user,
        option,
        setOption,
        redeemPoints,
        setRedeemPoints,
        updatePts,
        originalAmount
    } = props

    return (
        <Card className={classes.card}>
            <GridContainer>
                <GridItem xs={12}>
                    <Typography className={classes.totalCost}>
                        Use Your Rewards
                    </Typography>
                </GridItem>
            </GridContainer>

            <div className={classes.borderDiv}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Typography className={classes.usePointsText}>
                            Use total points ({user.approvedPts? numberFormatter.format(Number(user.approvedPts)): 0}) available
                        </Typography>
                    </GridItem>

                    <GridItem xs={12} style={{ display: "flex", marginTop: "15px", justifyContent: "space-between" }}>
                        <FormControlLabel
                            className={classes.checkboxes}
                            control={
                                <Radio
                                    style={{ color: "#56CA93", marginTop: "-15px", marginRight: "-25px" }}
                                    checked={option === "Use"}
                                    onClick={() => setOption("Use")}
                                    value="Use"
                                />
                            }
                        />

                        <Typography className={classes.usePointsText} style={{ lineHeight: "16px" }}>
                            Points redeemed
                        </Typography>

                        <TextField
                            disabled={option !== "Use"? true : false}
                            placeholder=''
                            type="number"
                            className={classes.ptsTextField}
                            value={redeemPoints}
                            onChange={(e) => 
                                {
                                    if( Number(e.target.value) > (totalAmount *  100) && (user.approvedPts && (user.approvedPts) > (totalAmount*100))){
                                        setRedeemPoints(totalAmount*100)

                                    }
                                    else if(Number(e.target.value) > user.tuzoPoints && (user.approvedPts && user.approvedPts < (totalAmount*100))){
                                        setRedeemPoints(Number(user.approvedPts))
                                    }
                                    else{
                                        setRedeemPoints(e.target.value)
                                    }
                                
                            }}
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    adornedEnd: classes.adournedEnd,
                                    input: classes.input
                                },
                                inputProps: {
                                    min: 0,
                                    max: user.tuzoPoints
                                }
                            }}
                        />
                        <Button
                            className={classes.updateButton}
                            onClick={() => updatePts()}
                        >
                            Update
                        </Button>
                    </GridItem>
                </GridContainer>
            </div>

            {/* <div className={classes.borderDiv}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Typography className={classes.usePointsText}>
                            Use Transferred points  (0) available
                        </Typography>
                    </GridItem>

                    <GridItem xs={12} style={{ display: "flex", marginTop: "15px", justifyContent: "space-between" }}>
                        <FormControlLabel
                            className={classes.checkboxes}
                            control={
                                <Checkbox
                                    style={{ color: "#56CA93", marginTop: "-15px", marginRight: "-25px" }}
                                // checked={option === "Use"}
                                // onClick={() => setOption("Use")}
                                />
                            }
                        // value={data.rewardName}
                        />

                        <Typography className={classes.usePointsText} style={{ lineHeight: "16px" }}>
                            Points redeemed
                        </Typography>

                        <TextField
                            className={classes.ptsTextField}
                            // value={firstNameState}
                            // onChange={(e) => setFirstNameState(e.target.value)}
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    adornedEnd: classes.adournedEnd,
                                    input: classes.input
                                },
                            }}
                        />
                        <Button
                            className={classes.updateButton}
                        >
                            Update
                        </Button>
                    </GridItem>
                </GridContainer>
            </div> */}

            <div className={classes.borderDiv}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Typography className={classes.usePointsText}>
                            Balance due: <span style={{ fontWeight: 700 }}>${numberFormatterDecimals.format(totalAmount)}</span>
                        </Typography>
                    </GridItem>

                    <GridItem xs={12}>
                        <Typography className={classes.usePointsText}>
                            Remaining Points:  <span style={{ fontWeight: 700 }}>{user.approvedPts? numberFormatter.format((Number(user.approvedPts) - redeemPoints)): 0}</span>
                        </Typography>
                    </GridItem>
                </GridContainer>
            </div>

            <div style={{ display: "flex", marginTop: "10px" }}>
                <FormControlLabel
                    className={classes.checkboxes}
                    control={
                        <Radio
                            checked={option === "Keep"}
                            onClick={() => {
                                setOption("Keep")
                            }}
                            value="Keep"
                            style={{ color: "#56CA93", marginRight: "-15px" }}
                        />
                    }
                />

                <Typography className={classes.usePointsText} style={{ marginTop: "10px" }}>
                    Keep my points and pay ${numberFormatterDecimals.format(originalAmount)}
                </Typography>
            </div>


        </Card>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RedeemPoints);