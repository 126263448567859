import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Editor } from "react-draft-wysiwyg";

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Card } from '@material-ui/core';

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/adStyle';

export function DescriptionPage(props) {
    const {
        classes,
        description,
        setDescription,
        errors
    } = props;

    return (
        <Card className={classes.card}>
            <GridContainer>
                <GridItem xs={12}>
                    <h5 className={classes.cardHeader}>Description</h5>
                </GridItem>
                <GridItem xs={12}>
                    <div style={{ border: "2px solid #56CA93" }}>
                        <Editor
                            editorState={description}
                            onEditorStateChange={(state) => setDescription(state)}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                        />
                    </div>
                    {errors.link ?
                        <p className={classes.errText}>Input cannot be empty</p>
                        : null
                    }
                </GridItem>
            </GridContainer>
        </Card>
    )
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(DescriptionPage));