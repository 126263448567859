/*eslint-disable*/
import React, { useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Datetime from "react-datetime";
import moment from 'moment'

// mui core
import { Button, Card, Checkbox, InputAdornment, TextField, Typography } from "@material-ui/core";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";


// icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { CheckCircle } from '@material-ui/icons';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';
//styling
import withStyles from '@material-ui/core/styles/withStyles';
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";



export function BillingInfo(props) {
    const {
        classes,
        history,
        cardNumber,
        setCardNumber,
        expDate,
        setExpDate,
        nameOnCard,
        setNameOnCard,
        billingAddress,
        setBillingAddress,
        billingEdit,
        setBillingEdit,
        asDefault,
        setAsDefault,
        newPayment,
        setNewPayment,
        typeOfPayment,
        setTypeOfPayment,
        globalEdit
    } = props;

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                    <Card className={classes.profileCardStyling}>
                        <GridContainer>
                            <GridItem xs={12} style={{ display: "flex" }}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Billing Information
                                </Typography>

                                {billingEdit || newPayment ?
                                    <div>
                                        <Button
                                            className={classes.editBillingButton}
                                            style={{ background: "#1A405F", width: "128px" }}
                                        // onClick={updateUserInfo}
                                        >
                                            <SaveIcon style={{ marginRight: "10px" }} /> Save
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setBillingEdit(false)
                                                setNewPayment(false)
                                            }}
                                            className={classes.editBillingButton}
                                            style={{ background: "#E2A400", width: "143px" }}
                                        >
                                            <Delete style={{ marginRight: "10px", }} /> Cancel
                                        </Button>
                                    </div>
                                    :
                                    globalEdit ? null
                                        :
                                        <div>
                                            <Button
                                                className={classes.editBillingButton}
                                                onClick={() => setBillingEdit(true)}
                                            >
                                                <Edit style={{ marginRight: "5px", width: "17px" }} /> Edit
                                            </Button>

                                            <Button
                                                onClick={() => setNewPayment(true)}
                                                className={classes.editBillingButton}
                                                style={{ width: "248px", background: "#56CA93" }}
                                            >
                                                <Add style={{ marginRight: "5px" }} /> Add Payment Method
                                            </Button>
                                        </div>
                                }


                            </GridItem>

                            <GridItem xs={12} style={{ marginTop: "25px" }}>
                                <Typography variant="h2" className={classes.cardHeader} style={{ fontSize: "18px", lineHeight: "18px" }}>
                                    Primary (Default)
                                </Typography>
                            </GridItem>

                            <GridItem xs={3}>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="h2" className={classes.cardDescBilling}>
                                        Card Number
                                    </Typography>

                                    {billingEdit ?
                                        <TextField
                                            className={classes.billingInfoTextField}
                                            value={cardNumber}
                                            onChange={(e) => setCardNumber(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />

                                        :
                                        <Typography variant="h2" className={classes.cardDescLessWeightPaddingLeft} >
                                            {cardNumber}
                                        </Typography>
                                    }

                                </div>

                            </GridItem>

                            <GridItem xs={5} >

                                <div style={{ display: "flex", justifyContent: "space-around", overflow: "visible" }}>
                                    <Typography variant="h2" className={classes.cardDescBilling}>
                                        Expiration Date
                                    </Typography>

                                    {billingEdit ?
                                        <Datetime
                                            selected={expDate}
                                            dateFormat="MM/DD/YY"
                                            onChange={(date, e) => {
                                                setExpDate(date.format("MM/DD/YY"));
                                            }}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            closeOnClickOutside
                                            renderInput={(props, openCalendar, closeCalendar) => (
                                                <div onClick={openCalendar}>
                                                    <TextField
                                                        style={{ paddingLeft: "0px", width: "80%" }}
                                                        className={classes.billingInfoTextField}
                                                        value={expDate}
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes: {
                                                                adornedEnd: classes.adournedEnd,
                                                                input: classes.inputDatetime
                                                            },
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    className={classes.adournedEnd}
                                                                    position="end"
                                                                >
                                                                    <Button className={classes.datetimeButton}>
                                                                        <CalendarIcon
                                                                            className={classes.calendarIcon}
                                                                            onClick={openCalendar}
                                                                            style={{ width: "15px", height: "15px" }}
                                                                        />
                                                                    </Button>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                        :
                                        <Typography variant="h2" className={classes.cardDescLessWeightPaddingLeft} >
                                            {expDate ? moment(expDate).format("MM/YY") : ""}
                                        </Typography>
                                    }



                                    <Typography variant="h2" className={classes.cardDescBilling} style={{ whiteSpace: "nowrap" }}>
                                        Name on Card
                                    </Typography>

                                    {billingEdit ?
                                        <TextField
                                            style={{ width: "40%" }}
                                            className={classes.billingInfoTextField}
                                            value={nameOnCard}
                                            onChange={(e) => setNameOnCard(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                        :
                                        <Typography variant="h2" className={classes.cardDescLessWeightPaddingLeft} >
                                            {nameOnCard}
                                        </Typography>
                                    }


                                </div>

                            </GridItem>

                            <GridItem xs={4}>

                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    <Typography variant="h2" className={classes.cardDescBilling}>
                                        Billing Address
                                    </Typography>

                                    {billingEdit ?
                                        <TextField
                                            style={{ height: "60px" }}
                                            className={classes.billingInfoTextField}
                                            value={billingAddress}
                                            onChange={(e) => setBillingAddress(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                        :
                                        <Typography variant="h2" className={classes.cardDescLessWeightPaddingLeft} style={{ lineHeight: "24px", marginTop: "15px" }}>
                                            {billingAddress}
                                        </Typography>
                                    }
                                </div>

                            </GridItem>

                            {billingEdit ?
                                <>
                                    <GridItem xs={12} style={{ display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            <Checkbox
                                                icon={<MdOutlineRadioButtonUnchecked
                                                    style={{
                                                        background: "#FFFFFF",
                                                        color: "#56CA93",
                                                        ".MuiCheckbox-colorSecondary.Mui-checked ": {
                                                            color: "white"
                                                        }
                                                    }}
                                                />}
                                                checkedIcon={<CheckCircle
                                                    style={{
                                                        background: "#FFFFFF",
                                                        color: "#56CA93",
                                                    }}
                                                />}
                                                checked={asDefault}
                                                onClick={() => setAsDefault(!asDefault)}
                                            />
                                            <Typography className={classes.checkboxLabel}>
                                                Set as Default
                                            </Typography>

                                        </div>

                                        <Button
                                            className={classes.deleteButton}
                                        >
                                            <TrashIcon style={{ marginTop: "-5px", marginRight: "10px", height: "17px" }} /> Delete
                                        </Button>
                                    </GridItem>
                                </>
                                : null
                            }



                            <GridItem xs={12} style={{ borderBottom: "1px solid #56CA93", margin: "25px 5px" }} />

                            <GridItem xs={12}>
                                <Typography variant="h2" className={classes.cardHeader} style={{ fontSize: "18px", lineHeight: "18px" }}>
                                    Alternate Payment Method
                                </Typography>
                            </GridItem>

                            <GridItem xs={3}>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="h2" className={classes.cardDescBilling}>
                                        Bank Name
                                    </Typography>

                                    <Typography variant="h2" className={classes.cardDescLessWeight} >
                                        
                                    </Typography>
                                </div>

                            </GridItem>

                            <GridItem xs={billingEdit ? 6 : 3}>

                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    <Typography variant="h2" className={classes.cardDescBilling}>
                                        Account Number
                                    </Typography>

                                    <Typography variant="h2" className={classes.cardDescLessWeight} >
                                        
                                    </Typography>

                                    {billingEdit ?
                                        <div style={{ display: "flex", marginTop: "5px" }}>
                                            <Checkbox
                                                icon={<MdOutlineRadioButtonUnchecked
                                                    style={{
                                                        background: "#FFFFFF",
                                                        color: "#56CA93",
                                                        ".MuiCheckbox-colorSecondary.Mui-checked ": {
                                                            color: "white"
                                                        }
                                                    }}
                                                />}
                                                checkedIcon={<CheckCircle
                                                    style={{
                                                        background: "#FFFFFF",
                                                        color: "#56CA93",
                                                    }}
                                                />}
                                            // checked={asDefault}
                                            // onClick={() => setAsDefault(!asDefault)}
                                            />
                                            <Typography className={classes.checkboxLabel}>
                                                Set as Default
                                            </Typography>

                                            <Button
                                                className={classes.deleteButton}
                                            >
                                                <TrashIcon style={{ marginTop: "-5px", marginRight: "10px", height: "17px" }} /> Delete
                                            </Button>

                                        </div>
                                        : null}
                                </div>

                            </GridItem>

                            {newPayment ?
                                <>

                                    <GridItem xs={12} style={{ borderBottom: "1px solid #56CA93", margin: "25px 5px" }} />

                                    <GridItem xs={12}>
                                        <Typography variant="h2" className={classes.cardHeader} style={{ fontSize: "18px", lineHeight: "18px" }}>
                                            Alternate Payment Method
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={12} style={{ marginLeft: "-12px", marginTop: "10px" }}>
                                        <div style={{ display: "flex" }}>

                                            <Checkbox
                                                icon={<MdOutlineRadioButtonUnchecked
                                                    style={{
                                                        background: "#FFFFFF",
                                                        color: "#56CA93",
                                                        ".MuiCheckbox-colorSecondary.Mui-checked ": {
                                                            color: "white"
                                                        }
                                                    }}
                                                />}
                                                checkedIcon={<CheckCircle
                                                    style={{
                                                        background: "#FFFFFF",
                                                        color: "#56CA93",
                                                    }}
                                                />}
                                                checked={typeOfPayment.card}
                                                onClick={() => setTypeOfPayment({ card: true, ach: false })}
                                            />
                                            <Typography className={classes.checkboxLabel}>
                                                Debit/Credit Card
                                            </Typography>

                                            {/* <Checkbox
                                                icon={<MdOutlineRadioButtonUnchecked
                                                    style={{
                                                        background: "#FFFFFF",
                                                        color: "#56CA93",
                                                        ".MuiCheckbox-colorSecondary.Mui-checked ": {
                                                            color: "white"
                                                        }
                                                    }}
                                                />}
                                                checkedIcon={<CheckCircle
                                                    style={{
                                                        background: "#FFFFFF",
                                                        color: "#56CA93",
                                                    }}
                                                />}
                                                checked={typeOfPayment.ach}
                                                onClick={() => setTypeOfPayment({ card: false, ach: true })}
                                            />
                                            <Typography className={classes.checkboxLabel}>
                                                ACH Account
                                            </Typography> */}

                                        </div>
                                    </GridItem>

                                    <GridItem xs={3}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h2" className={classes.cardDescBilling}>
                                                Card Number
                                            </Typography>
                                            <TextField
                                                className={classes.billingInfoTextField}
                                                value={cardNumber}
                                                onChange={(e) => setCardNumber(e.target.value)}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        adornedEnd: classes.adournedEnd,
                                                        input: classes.input
                                                    },
                                                }}
                                            />
                                        </div>
                                    </GridItem>

                                    <GridItem xs={5}>
                                        <div style={{ display: "flex", justifyContent: "space-around", overflow: "visible" }}>

                                            <Typography variant="h2" className={classes.cardDescBilling}>
                                                Expiration Date
                                            </Typography>

                                            <Datetime
                                                selected={expDate}
                                                dateFormat="MM/DD/YY"
                                                onChange={(date, e) => {
                                                    setExpDate(date.format("MM/DD/YY"));
                                                }}
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                closeOnClickOutside
                                                renderInput={(props, openCalendar, closeCalendar) => (
                                                    <div onClick={openCalendar}>
                                                        <TextField
                                                            style={{ paddingLeft: "0px", width: "80%" }}
                                                            className={classes.billingInfoTextField}
                                                            value={expDate}
                                                            variant="outlined"
                                                            InputProps={{
                                                                classes: {
                                                                    adornedEnd: classes.adournedEnd,
                                                                    input: classes.inputDatetime
                                                                },
                                                                endAdornment: (
                                                                    <InputAdornment
                                                                        className={classes.adournedEnd}
                                                                        position="end"
                                                                    >
                                                                        <Button className={classes.datetimeButton}>
                                                                            <CalendarIcon
                                                                                className={classes.calendarIcon}
                                                                                onClick={openCalendar}
                                                                                style={{ width: "15px", height: "15px" }}
                                                                            />
                                                                        </Button>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            />

                                            <Typography variant="h2" className={classes.cardDescBilling} style={{ whiteSpace: "nowrap" }}>
                                                Name on Card
                                            </Typography>

                                            <TextField
                                                style={{ width: "40%" }}
                                                className={classes.billingInfoTextField}
                                                value={nameOnCard}
                                                onChange={(e) => setNameOnCard(e.target.value)}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        adornedEnd: classes.adournedEnd,
                                                        input: classes.input
                                                    },
                                                }}
                                            />
                                        </div>

                                    </GridItem>

                                    <GridItem xs={4}>
                                        <div style={{ display: "flex", justifyContent: "space-around" }}>

                                            <Typography variant="h2" className={classes.cardDescBilling}>
                                                Billing Address
                                            </Typography>

                                            <TextField
                                                style={{ height: "60px" }}
                                                className={classes.billingInfoTextField}
                                                value={billingAddress}
                                                onChange={(e) => setBillingAddress(e.target.value)}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        adornedEnd: classes.adournedEnd,
                                                        input: classes.input
                                                    },
                                                }}
                                            />

                                        </div>

                                    </GridItem>

                                </>
                                : null}

                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(BillingInfo));