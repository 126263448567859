import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Hidden, MenuItem, Select, TextField, TextareaAutosize } from '@material-ui/core';

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';

// icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";

export function Denominations(props) {
    const {
        classes,
        cardInfo,
        setCardInfo,
        addDenom,
        deleteDenom
    } = props;

    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card} style={{ marginTop: "15px" }}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                            <h5 className={classes.cardHeader}>Denominations</h5>
                            <Button
                                className={classes.addRoomButton}
                                onClick={() => addDenom()}
                            >
                                <Add style={{ marginRight: "10px" }} /> Add Denominations
                            </Button>
                        </GridItem>
                    </GridContainer>

                    {cardInfo.map((data, index) => (
                        <GridContainer key={data.id} style={{ marginTop: "15px" }}>
                            <GridItem xs={3} >
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <p className={classes.generalLabel} style={{ marginTop: "5px" }}>
                                            Price ($)
                                        </p>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <TextField
                                            type="number"
                                            className={classes.textField}
                                            value={data.price}
                                            onChange={(e) => {
                                                e.persist();
                                                setCardInfo((prevValue) => {
                                                    return prevValue.map((item) => {
                                                        if (item.id === index) {
                                                            return { ...item, price: e.target.value };
                                                        } else {
                                                            return item;
                                                        }
                                                    });
                                                });
                                            }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                inputProps: {
                                                    min: 0,
                                                }
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={4} >
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <p className={classes.generalLabel} style={{ marginTop: "5px" }}>
                                            Units Available
                                        </p>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <TextField
                                            placeholder='Leave blank for unlimited'
                                            type="number"
                                            className={classes.textField}
                                            value={data.units}
                                            onChange={(e) => {
                                                e.persist();
                                                setCardInfo((prev) => {
                                                    return prev.map((item) => {
                                                        if (item.id === index) {
                                                            return { ...item, denomUnits: e.target.value }
                                                        } else {
                                                            return item;
                                                        }
                                                    })
                                                })
                                            }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                inputProps: {
                                                    min: 0,
                                                }
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={3} >
                                <GridContainer>
                                    <GridItem xs={3}>
                                        <p className={classes.generalLabel} style={{ marginTop: "5px" }}>
                                            SKU
                                        </p>
                                    </GridItem>

                                    <GridItem xs={9}>
                                        <TextField
                                            type="number"
                                            className={classes.textField}
                                            value={data.sku}
                                            onChange={(e) => {
                                                e.persist();
                                                setCardInfo((prev) => {
                                                    return prev.map((item) => {
                                                        if (item.id === index) {
                                                            return { ...item, sku: e.target.value }
                                                        } else {
                                                            return item;
                                                        }
                                                    })
                                                })
                                            }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                inputProps: {
                                                    min: 0,
                                                }
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    className={classes.deleteDenomButton}
                                    onClick={() => deleteDenom(index)}
                                >
                                    Delete
                                </Button>
                            </GridItem>
                        </GridContainer>
                    ))}
                </Card>
            </Hidden>
        </div>

    )
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Denominations);