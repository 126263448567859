import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import avatar from "assets/img/faces/avatar.jpg";
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

class UserInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
        };
    }

    openCollapse(collapse) {
        var st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }    

    render() {
        const { classes, firstName, lastName, miniActive, rtlActive, bgColor } = this.props;

        const itemText =
            classes.itemText +
            " " +
            cx({
                [classes.itemTextMini]: miniActive,
                [classes.itemTextMiniRTL]:
                    rtlActive && miniActive,
                [classes.itemTextRTL]: rtlActive
            });
        const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
                [classes.collapseItemTextMini]:
                    miniActive,
                [classes.collapseItemTextMiniRTL]:
                    rtlActive && miniActive,
                [classes.collapseItemTextRTL]: rtlActive
            });
        const userWrapperClass =
            classes.user +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });
        const caret =
            classes.caret +
            " " +
            cx({
                [classes.caretRTL]: rtlActive
            });
        const collapseItemMini =
            classes.collapseItemMini +
            " " +
            cx({
                [classes.collapseItemMiniRTL]: rtlActive
            });
        const photo =
            classes.photo +
            " " +
            cx({
                [classes.photoRTL]: rtlActive
            });

        return (
            <div className={userWrapperClass}>
                <div className={photo}>
                    <img src={avatar} className={classes.avatarImg} alt="..." />
                </div>
                <List className={classes.list}>
                    <ListItem className={classes.item + " " + classes.userItem}>
                        <NavLink
                            to={"#"}
                            className={classes.itemLink + " " + classes.userCollapseButton}
                            onClick={() => this.openCollapse("openAvatar")}
                        >
                            <ListItemText
                                primary={firstName + ' ' + lastName}
                                secondary={
                                    <b
                                        className={
                                            caret +
                                            " " +
                                            classes.userCaret +
                                            " " +
                                            (this.state.openAvatar ? classes.caretActive : "")
                                        }
                                    />
                                }
                                disableTypography={true}
                                className={itemText + " " + classes.userItemText}
                            />
                        </NavLink>
                        <Collapse in={this.state.openAvatar} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="/admin/user-page"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                                        <span className={collapseItemMini}>
                                            {rtlActive ? "مع" : "MP"}
                                        </span>
                                        <ListItemText
                                            primary={rtlActive ? "ملفي" : "My Profile"}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="user/editor"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                                        <span className={collapseItemMini}>
                                            {rtlActive ? "هوع" : "EP"}
                                        </span>
                                        <ListItemText
                                            primary={
                                                rtlActive ? "تعديل الملف الشخصي" : "Edit Profile"
                                            }
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="#"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                                        <span className={collapseItemMini}>
                                            {rtlActive ? "و" : "S"}
                                        </span>
                                        <ListItemText
                                            primary={rtlActive ? "إعدادات" : "Settings"}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>
                    </ListItem>
                </List>
            </div>
        );
    }
}

UserInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    rtlActive: PropTypes.bool,
    miniActive: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string
};

function mapStateToProps(state) {
    return {
        firstName: state.auth.user && state.auth.user.firstName,
        lastName: state.auth.user && state.auth.user.lastName
    };
}

export default compose(
    connect(mapStateToProps),
    withStyles(sidebarStyle)
)(UserInfo);