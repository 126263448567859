import {
  cardTitle,
  successColor,
  dangerColor
} from "assets/jss/material-dashboard-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const explorerStyle = (theme) => ({
  ...customCheckboxRadioSwitch,
  cardContainer: {
    height: "20%"
  },
  mainContainer: {
    // ...container,
    zIndex: "4",
    display: "flex",
    width: "99%",
    flexDirection: "column",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "10px",
    marginBottom: "0px"
  },
  cardBody: {
    fontSize: "medium",
    fontWeight: "800",
    display: "block",
    backgroundColor: "#f2f2f2"
  },
  filterCardBody: {
    marginLeft: "13px",
    background: "rgba(255, 255, 255, 1)",
    [theme.breakpoints.down('sm')]: {
      height: "100px"
    }
  },
  customerCardBody: {
    marginLeft: "13px",
    minHeight: "600px",
    overflow: "scroll",
    background: "rgba(255, 255, 255, 1)",
  },
  newRecord: {
    "&:hover": {
      backgroundColor: "#f3684e",
    },
    backgroundColor: "#f3684e",
    borderRadius: "50%",
    color: "#fff",
    position: "fixed",
    bottom: 20,
    right: 40,
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
      height: "50px",
      width: "50px",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  advanceStatusButton: {
    backgroundColor: "#18528c",
    width: "40%",
  },
  notesButton: {
    backgroundColor: "#05669b",
    width: "40%"
  },
  remind: {
    backgroundColor: "#18528c",
  },
  staticFormGroup: {
    marginLeft: "0",
    marginRight: "0",
    paddingBottom: "10px",
    margin: "8px 0 0 0",
    position: "relative",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    }
  },
  staticFormControl: {
    marginBottom: "0",
    paddingTop: "8px",
    paddingBottom: "8px",
    minHeight: "34px"
  },
  inputAdornment: {
    marginRight: "8px",
    position: "relative"
  },
  inputAdornmentIconSuccess: {
    color: successColor[0] + "!important"
  },
  inputAdornmentIconError: {
    color: dangerColor[0] + "!important"
  },
  attentionFlag: {
    color: dangerColor[0] + "!important",
    display: "flex",
    justifyContent: "flex-end",
    margin: "0 30px 0 0"
  },
  tableHeader: {
    width: "20%"
  },
  tableCell: {
    width: "20%",
    fontSize: "18px",
    textAlign: "center"
  },
  statusTitle: {
    color: "#e87402",
    fontWeight: 600
  },
  viewDetailsBtn: {
    display: "flex",
    width: "93% !important",
    justifyContent: "space-evenly",
    alignItems: "center",
    "&:hover": {
      background: "inherit"
    }
  }

})

export default explorerStyle;
