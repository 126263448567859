/*eslint-disable*/
import React, { useParams, useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from 'assets/img/market-me/settingsLogo.png'
import SelectedSettingsLogo from 'assets/img/market-me/selectedSettingsLogo.png'



// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.js";
import GlobalSearchBox from "components/SearchBox/GlobalSearchBox";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import AuthActions from 'store/actions/auth';

import { setPathname } from 'store/actions/windowActions'
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";


import tuzoLogo from "assets/img/tuzo/tuzoHorizontal.png"
import { ReactComponent as Calculator } from "assets/img/tuzo/icons/calculator.svg";
import { ReactComponent as TransferPoints } from "assets/img/tuzo/icons/TransferPoints.svg";
import { ReactComponent as CartIcon } from "assets/img/tuzo/icons/CartIcon.svg";
import { ReactComponent as Notifications } from "assets/img/tuzo/icons/Notifications.svg";

import RewardNavLinkDropdown from "components/CustomDropdown/RewardNavLinkDropdown";


const headerRoutes = [
  { name: "Dashboard", link: "/admin/dashboard" },
  { name: "Rewards", link: "/admin/rewards", },
  { name: "Orders", link: "/admin/orders", },
  { name: "Reports", link: "/admin/reports/sales-by-merchant", },
  { name: "Profile", link: "/admin/profile" },
  { name: "Ads", link: "/admin/ads" }
];

const useStyles = makeStyles(styles);

export function AdminNavbar(props) {
  const classes = useStyles();
  const {
    color,
    rtlActive,
    brandText,
    routes,
    history,
    user,
    globalCalulcatorOpen,
    setGlobalCalulcator,
    globalArrowOpen,
    setGlobalArrow,
    notifications
  } = props;
  const [clickDropdown, setClickDropdown] = useState(false);

  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });

  const [pathName, setPathName] = useState(window.location.pathname)
  const path = window.location.pathname

  useEffect(() => {
    setPathName(path)
  }, [path])


  return (
    <Hidden className={classes.hiddenSmall} smDown implementation="css">
      <AppBar id="admin-navbar" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <GridContainer className={classes.sixtyPixels}>
            <GridItem className={classes.gridItemHover} xs={2}>
              <img
                src={tuzoLogo}
                style={{ height: "35px" }}
                onClick={() => {
                  history.push('/admin')
                  setGlobalCalulcator(false)
                }}
              />
              <span
                onClick={() => {
                  history.push('/admin/notifications')
                  setGlobalCalulcator(false)
                }}
                className={notifications.length > 0 ? classes.notificationsCount : classes.notificationsCountZero}>
                {notifications.length > 0 ? " " + notifications.length
                  : <Notifications />}
              </span>
            </GridItem>
            <GridItem className={classes.gridItem} xs={6}>
              <GridContainer className={classes.routesGridContainer}>
                <GridItem xs={12} className={classes.gridItem}>
                  {
                    headerRoutes.map((r) => {
                      return (
                        <GridItem
                          onClick={() => {
                            history.push(r.link)
                            setGlobalCalulcator(false)
                          }}
                          className={
                            window.location.pathname === r.link
                              ? classes.routesGridItemUnderline
                              : classes.routesGridItem
                          }
                          key={r.name}
                          xs={2}
                        >
                          {r.name === "Rewards" ?
                            <>
                              <span className={classes.headerLinksText} >
                                {r.name}
                              </span>
                              <RewardNavLinkDropdown
                                setClickDropdown={setClickDropdown}
                                clickDropdown={clickDropdown}
                                linkName={window.location.pathname}
                                adminNavbar
                                history={history}
                                dropdownList={[
                                  "Luxury Goods",
                                  "Electronics",
                                  "Travel",
                                  "Gift Cards",
                                  "Raffle"
                                ]}
                              />
                            </>
                            :
                            <span className={classes.headerLinksText} >
                              {r.name}
                            </span>
                          }
                        </GridItem>
                      );
                    })
                  }
                </GridItem>
              </GridContainer>
            </GridItem>


            <GridItem xs={2} className={classes.gridItemSpaceBetween} style={{ background: "#06667C", }}>
              <div>
                <span className={classes.name}>
                  {user.firstName ? user.firstName + " " + user.lastName : ""}
                </span>
              </div>
              <CustomDropdown
                setClickDropdown={setClickDropdown}
                clickDropdown={clickDropdown}
                adminNavbar
                history={history}
                dropdownList={[
                  "Profile",
                  { divider: true },
                  "Log Out",
                ]}
              />
            </GridItem>

            <GridItem xs={2} className={classes.gridItemNoPadding}>
              <GridContainer className={classes.iconsGridContainer} >
                <GridItem xs={6}
                  onClick={() => setGlobalArrow(!globalArrowOpen)}
                  className={
                    !globalArrowOpen
                      ? classes.iconGridItem
                      : classes.iconGridItemTrue
                  }
                >
                  <TransferPoints />
                </GridItem>
                <GridItem xs={6}
                  onClick={() => {
                    history.push('/auth/calculator')
                    setGlobalCalulcator(!globalCalulcatorOpen)
                  }}
                  className={
                    !globalCalulcatorOpen
                      ? classes.iconGridItem
                      : classes.iconGridItemTrue
                  }
                >
                  <Calculator style={{ marginRight: "15px" }} />
                </GridItem>
              </GridContainer>
            </GridItem>

          </GridContainer>
        </Toolbar>
      </AppBar>
    </Hidden>


    // <Hidden
    //   smDown
    //   className={classes.settingBtn} implementation="css">
    //   <h1 style={{ fontSize: "20px", margin: "0px 80px", fontWeight: 500, color: "white" }}>
    //     {typeof props.brandText === 'string' ? props.brandText : props.brandText[0]}
    //   </h1>
    // </Hidden>


  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};


function mapStateToProps(state) {
  return {
    user: state.auth.user,
    notifications: state.notifications.notifications
  };
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.startLogout()),
  setPathname: (path) => dispatch(setPathname(path))
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AdminNavbar));
