import {
  successColor,
  tooltip,
  cardTitle,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";

const dashboardStyle = {
  ...hoverCardStyle,
  tooltip,
  mainContainer: {
    // ...container,
    zIndex: "4",
    display: "flex",
    width: "99%",
    flexDirection: "column",
  },
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0]
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },
  dashboardTitle: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "22px",
    textTransform: "capitalize",
    color: "#1A405F"
  },
  addButton: {
    width: "157px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    background: "#E2A400",
    color: "#FFFFFF",
    textTransform: "capitalize",
    borderRadius: "7px",
    "&:hover": {
      background: "#E2A400",
    },
  },
  actionButton: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    background: "#E2A400",
    color: "#FFFFFF",
    textTransform: "capitalize",
    borderRadius: "7px",
    "&:hover": {
      background: "#E2A400",
    },
    marginRight: "10px"
  },
  card: {
    height: "102px",
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    textTransform: "capitalize",
    padding: "5px 10px 10px 10px",
    // width: "100%",
    whiteSpace: "noWrap",
    borderRadius: "7px",
    boxShadow: "0px 2px 2px 0px #DADADA",
    color: "#1A405F"
  },
  circleChartGridContainer: {
    padding: "0 5px !important"
  },
  circleChartDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "30px",
    whiteSpace: "pre",
    color: "#1A405F"
  },
  circleChartLabel: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "14px",
    // marginTop: "15px",
    whiteSpace: "pre",
    color: "#004253",
    marginLeft: "5px"
  },
  cardTable: {
    fontFamily: "Josefin Sans",
    padding: "25px",
    boxShadow: "0px 2px 2px 0px #DADADA",
    borderRadius: "5px",
  },
  selectText: {
    fontWeight: 500,
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F"
  },
  selectMenuItem: {
    fontWeight: 500,
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F"
  },
  selectMenuSort: {
    fontFamily: "Josefin Sans",
    color: "#1A405F",
    background: "#FFFFFF",
    border: "2px solid #1A405F",
    borderRadius: "7px",
    width: "30%",
    height: "40px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 10px",
  },
  selectAsc: {
    color: "#1A405F",
    fontFamily: "Josefin Sans",
    background: "#FFFFFF",
    borderRadius: "7px",
    border: "2px solid #1A405F",
    width: "10%",
    height: "40px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 10px",
  },
  toggleDiv: {
    display: "flex",
    width: "100%",
    border: "2px solid #1A405F",
    borderRadius: "5px",
  },
  toggleSelection: {
    height: "35px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "15px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#1A405F",
    padding: "10px",
    "&:hover": {
      cursor: "pointer",
    },
    whiteSpace: "nowrap"
  },
  searchBar: {
    width: "50%",
    background: "white",
    height: "40px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "2px solid #1A405F",
    paddingLeft: "10px",
    borderRadius: "7px"
  },
  orderSearchBar: {
    width: "100%",
    background: "white",
    height: "40px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "2px solid #1A405F",
    paddingLeft: "10px",
    borderRadius: "7px"
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height: "36px"
  },
  searchButton: {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "7px",
    backgroundColor: "#1A405F",
    // display: "block",
    margin: "0px -1px !important",
    padding: "8px 14px !important",
    height: "40px",
    width: "120px",
    color: "#FFFFFF",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    textTransform: "capitalize",
    "&:hover, &:active, &:focus": {
      background: "#1A405F",
    },
  },
  rewardsTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    padding: "0px 30px 10px 20px"
    // [theme.breakpoints.down("md")]: {
    //   tableLayout: "fixed",
    //   width: "100%",
    // },
  },
  tableRow: {
    height: "39px",
    background: "#FFFFFF !important"
  },
  tableRowOdd: {
    height: "39px",
    background: "#E8EBEF  !important"
  },
  tableHeaders: {
    border: "0.5px solid #1A405F",
    background: "#1A405F",
    color: "#FFFFFF",
    fontFamily: "Josefin Sans",
    fontSize: "17px",
    padding: "10px",
    textTransform: "uppercase",
  },
  tableCell: {
    textAlign: "center",
    border: "0.5px solid #1A405F",
    padding: "10px 20px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1A405F",
  },
  tableCellOnHover: {
    textAlign: "center",
    border: "0.5px solid #1A405F",
    padding: "10px 20px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1A405F",
    "&:hover": {
      cursor: "pointer",
    }
  },
  tableCellList: {
    textAlign: "center",
    border: "0.5px solid #1A405F",
    padding: "10px 20px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    color: "#1A405F",
  },
  indexes: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    color: "#1A405F"
  },
  commentDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    margin: "0px 10px",
    whiteSpace: "noWrap",
    textAlign: "center",
    justifyContent: "center"
  },
  tooltip: {
    fontWeight: "500",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    background: "#1A405F"
  },
  approvedModal: {
    padding: "15px",
    overflow: "hidden",
    width: "100%",
    position: "absolute",
    top: "0% !important",
    left: "5% !important",
  },
  modalCard: {
    padding: "20px 20px 30px 20px",
    boxShadow: "0px 1px 4px 0px #00000040",
    borderRadius: "7px",
    margin: "0px",
    width: "750px"
  },
  cardHeaderModal: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "19px",
    textTransform: "capitalize",
    color: "#1A405F",
    marginRight: "20px"
  },
  modalCardDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#1A405F",
    marginTop: "12px",
  },
  gridItemMarginTop: {
    marginTop: "15px"
  },
  modalTextField: {
    width: "100%",
    background: "white",
    height: "30px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    border: "1px solid #1A405F",
    paddingLeft: "5px",
    borderRadius: "7px",
    marginTop: "5px"
  },
  modalButton: {
    borderRadius: "7px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    color: "white",
    background: "#E2A400",
    textTransform: "capitalize",
    height: "42px",
    width: "125px",
    "&:hover, &:focus": {
      backgroundColor: "#E2A400",
    },
    marginLeft: "10px"
  },
  modalButtonUserType: {
    borderRadius: "7px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    color: "white",
    background: "#1A405F",
    textTransform: "capitalize",
    height: "35px",
    width: "125px",
    "&:hover, &:focus": {
      backgroundColor: "#1A405F",
    },
    marginTop: "15px"
  },
  input: {
    color: "#1A405F",
    fontWeight: 400,
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    '&::placeholder': {
      opacity: 0.5,
      fontFamily: "Josefin Sans",
      fontWeight: 400,
      color: "#1A405F",
    },
  },
  emptyMerch: {
    objectFit: "contain",
    marginTop: "10px",
    marginLeft: "10px"
  },
  invMerchHeader: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "22px",
    color: "#1A405F"
  },
  invMerchDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "18px",
    color: "#1A405F",
    marginTop: "20px"
  },
  checkboxes: {
    fontFamily: "Josefin Sans !important",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1A405F"
  },
  selectPlaceHolder: {
    fontWeight: 500,
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    paddingTop: "5px",
    opacity: "1 !important",
  },
  
};

export default dashboardStyle;
