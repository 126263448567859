/*eslint-disable*/
// const wishlist = []
// const initDataRetrieved = false

const initState = {
    wishlist: [],
    initDataRetrieved: false
}


export default function (state = initState, action) {
    switch (action.type) {
        case "SET_WISHLIST":
            var toReturn = { ...state }
            toReturn.wishlist = action.wishlist
            toReturn.initDataRetrieved = true
            return toReturn
        case "ADD_WISHLIST":
            const newState = [...state.wishlist];
            const newObj = {
                ...action.product,
                wishlist: true
            }
            newState.push(newObj);
            return {
                ...state,
                wishlist: newState,
            };
        case "REMOVE_WISHLIST":
            const newWishlist = [...state.wishlist]
            newWishlist.splice(action.index,1)
            return {
                ...state,
                wishlist: newWishlist
            }
        default:
            return state
    }
}