import React, { useEffect } from 'react';
import { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment"
import Filter from './components/Filter';
import Table from './components/Table';

// mui
import { Card, Hidden, Typography } from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';
import withStyles from '@material-ui/core/styles/withStyles';


import { reportSelections } from '../reportSelections';

const headers = [
    { label: "Date", key: "date" },
    { label: "# of Batches", key: "numBatches" },
    { label: "Total ($)", key: "total" },
    { label: "Fees ($)", key: "fees" },
    { label: "Reference", key: "referenceNum" },
    { label: "Points", key: "pts" },
];


const mockData = [
    {
        date: "08/04/2023 8:00 AM",
        numBatches: 3,
        total: "24,532",
        fees: "10.90",
        referenceNum: "13214112311",
        pts: "246"
    },
    {
        date: "08/04/2023 8:00 AM",
        numBatches: 3,
        total: "24,532",
        fees: "10.90",
        referenceNum: "13214112311",
        pts: "246"
    },
    {
        date: "08/04/2023 8:00 AM",
        numBatches: 3,
        total: "24,532",
        fees: "10.90",
        referenceNum: "13214112311",
        pts: "246"
    },
    {
        date: "08/04/2023 8:00 AM",
        numBatches: 3,
        total: "24,532",
        fees: "10.90",
        referenceNum: "13214112311",
        pts: "246"
    },
    {
        date: "08/04/2023 8:00 AM",
        numBatches: 3,
        total: "24,532",
        fees: "10.90",
        referenceNum: "13214112311",
        pts: "246"
    },
    {
        date: "08/04/2023 8:00 AM",
        numBatches: 3,
        total: "24,532",
        fees: "10.90",
        referenceNum: "13214112311",
        pts: "246"
    },
    {
        date: "08/04/2023 8:00 AM",
        numBatches: 3,
        total: "24,532",
        fees: "10.90",
        referenceNum: "13214112311",
        pts: "246"
    },
    {
        date: "08/04/2023 8:00 AM",
        numBatches: 3,
        total: "24,532",
        fees: "10.90",
        referenceNum: "13214112311",
        pts: "246"
    }
]

export function ReportsPage(props) {
    const { classes, history, userType } = props;
    const [reportArr, setReportArr] = useState([])
    const [sortValue, setSortValue] = useState("")
    const [reportType, setReportType] = useState("Daily")
    const [fromDate, setFromDate] = useState(moment().format("MMMM DD YYYY"))
    const [toDate, setToDate] = useState(moment().format("MMMM DD YYYY"))
    const itemsPerPage = 9;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    const handleReportToggle = (value) => {
        setReportType(value)
    }

    useEffect(() => {
        reportSelections.map((data) => {
            if (data.type === "Merchants" && userType === "Merchants") {
                setReportArr(prevArr => [...prevArr, data])
            }
        })
    }, [])


    return (
        <div>
            <Hidden mdUp implementation='css'>
                <div>
                    <Typography variant="h2" className={classes.title} style={{color: "#1A405F"}}>
                        Please View Page on Desktop / Laptop
                    </Typography >
                </div>
            </Hidden>

            <Hidden smDown implementation="css">
                <GridContainer>
                    <GridItem xs={12} >
                        <Typography variant="h2" className={classes.title}>
                            Reports
                        </Typography>
                    </GridItem>

                    <GridItem xs={12} style={{ marginTop: "20px" }}>
                        <Card style={{ padding: "25px" }}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Filter
                                        reportSelections={reportArr}
                                        sortValue={sortValue}
                                        setSortValue={setSortValue}
                                        handleReportToggle={handleReportToggle}
                                        reportType={reportType}
                                        setReportType={setReportType}
                                        toDate={toDate}
                                        setToDate={setToDate}
                                        fromDate={fromDate}
                                        setFromDate={setFromDate}
                                        csvData={mockData}
                                        headers={headers}
                                    />
                                </GridItem>

                                <GridItem xs={12}>
                                    <Table
                                        mockData={mockData}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                        index={index}
                                        setIndex={setIndex}
                                        endIndex={endIndex}
                                        setEndIndex={setEndIndex}
                                        itemsPerPage={itemsPerPage}
                                        handlePageClick={handlePageClick}
                                        headers={headers}
                                    />
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>

                </GridContainer>
            </Hidden>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userId: state.auth.user.uid,
        userType: state.auth.user.userType
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ReportsPage));