const calculatorStyle = (theme) => ({
    calculatorCardIsoAdmin: {
        background: "#1A405F",
        marginTop:"20px",
        width:"100vw",
        marginLeft:"-30px"
    },
    calculatorCardIsoAdminMobile: {
        background: "#1A405F",
        width:"100vw",
        height:"100%",
        marginLeft:"-30px",
    },
    calculatorCard: {
        display: "flex",
        justifyContent: "center",
        position: "relative",
        margin: "0",
        border: "0",
        zIndex: 1,
        // backgroundColor: "#1A405F",
        height: "100%",
    },
    calculatorCardMobile: {
        display: "flex",
        justifyContent: "center",
        padding: "60px",
        position: "relative",
        margin: "0",
        border: "0",
        zIndex: 1,
        backgroundColor: "#1A405F",
        height: "100%",
        width: "85%"
    },
    calculatorTitle: {
        fontSize: "3.7em",
        fontWeight: 600,
        fontFamily: "Josefin Sans",
        textTransform: "uppercase",
        textAlign: "center",
        color: "#FFFFFF",
        padding: "25px 0px"
    },
    calculatorTitleMobile: {
        fontSize: "2.7em",
        fontWeight: 600,
        fontFamily: "Josefin Sans",
        textTransform: "uppercase",
        textAlign: "center",
        color: "#FFFFFF",
        padding: "25px 0px"
    },
    calculatorSubtitle: {
        fontSize: "19px",
        fontWeight: 500,
        fontFamily: "Josefin Sans",
        textAlign: "center",
        color: "#FFFFFF",
        paddingBottom: "50px"
    },
    calculatorSubtitleMobile: {
        fontSize: "19.5px",
        fontWeight: 500,
        fontFamily: "Josefin Sans",
        textAlign: "center",
        color: "#FFFFFF",
        paddingBottom: "50px"
    },
    cardHeaderTitle: {
        fontSize: "19px",
        fontWeight: 500,
        fontFamily: "Josefin Sans",
        color: "#FFFFFF"
    },
    cardHeaderTitleMobile: {
        fontSize: "19.5px",
        fontWeight: 500,
        fontFamily: "Josefin Sans",
        color: "#FFFFFF",
        textAlign: "center"
    },
    textField: {
        width: "100%",
        background: "white",
        height: "35px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        border: "1px solid #929292",
        paddingLeft: "10px",
        borderRadius: "7px",
        "& .Mui-error": {
            fontFamily: "Josefin Sans",
            fontWeight: 600,
        },
        marginTop: "15px"
    },
    textFieldMobile: {
        width: "90%",
        background: "white",
        height: "35px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        border: "1px solid #929292",
        paddingLeft: "10px",
        borderRadius: "7px",
        "& .Mui-error": {
            fontFamily: "Josefin Sans",
            fontWeight: 600,
        },
        margin: "20px",
        [theme.breakpoints.up("sm")]: {
            width: "95%"
        },
    },
    input: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        '&::placeholder': {
            opacity: 1,
            fontFamily: "Josefin Sans",
            fontWeight: 400,
            color: "#1A405F",
        },
    },
    submitButton: {
        background: "#56CA93",
        color: "#FFFFFF",
        borderRadius: "8px",
        fontFamily: "Josefin Sans",
        fontSize: "19px",
        fontWeight: 500,
        lineHeight: "19px",
        textTransform: "capitalize",
        marginTop: "20px",
        marginBottom: "10px",
        height: "50px",
        width: "174px",
        "&:hover": {
            background: "#56CA93",
            color: "#FFFFFF",
        },
    },
    submitButtonMobile: {
        background: "#56CA93",
        color: "#FFFFFF",
        borderRadius: "8px",
        fontFamily: "Josefin Sans",
        fontSize: "19px",
        fontWeight: 500,
        lineHeight: "19px",
        textTransform: "capitalize",
        marginTop: "20px",
        marginBottom: "10px",
        height: "40px",
        width: "160px",
        "&:hover": {
            background: "#56CA93",
            color: "#FFFFFF",
        },
    },
    bannerPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "20px",
        textTransform: "capitalize",
        color: "#56CA93",
        lineHeight: "28px",
    },
    bannerPtsMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19.5px",
        textTransform: "capitalize",
        color: "#56CA93",
        lineHeight: "51px",
        textAlign: "center"
    },
    bannerDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "20px",
        color: "#FFFFFF",
        lineHeight: "41px",
        margin: "5px 0px 0px 10px",
        whiteSpace: "noWrap"
    },
    bannerDescTop: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19.5px",
        color: "#FFFFFF",
        lineHeight: "19px",
        margin: "5px 0px 0px 0px",
        whiteSpace: "noWrap"
    },
    bannerDescTopMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19.5px",
        color: "#FFFFFF",
        lineHeight: "39px",
        margin: "5px 0px 0px 0px",
    },
    gridBanner: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    gridBannerMobile: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    rewardsCardListStyling: {
        height: "280px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        width: "325px",
        [theme.breakpoints.down("lg")]: {
            width: "80%"
        },
    },
    rewardsCardListStylingMobile: {
        height: "auto",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px 5px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        width: "90%",
    },
    cardListTitleNoOverflow: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "20px",
        textTransform: "uppercase",
        marginTop: "10px",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    cardListTitleNoOverflowMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        textTransform: "uppercase",
        marginTop: "10px",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    cardListDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "capitalize",
        marginTop: "10px",
        paddingLeft: "10px"
    },
    cardListPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "17px",
        marginTop: "10px",
        paddingLeft: "10px"
    },
    addIdButton: {
        background: "#06667C",
        color: "#FFFFFF",
        borderRadius: "8px",
        fontFamily: "Josefin Sans",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "16px",
        textTransform: "capitalize",
        marginTop: "20px",
        height: "44px",
        "&:hover": {
            background: "#06667C",
            color: "#FFFFFF",
        },
    },
    previewPageButtonMobile: {
        background: "#06667C",
        color: "#FFFFFF",
        borderRadius: "8px",
        fontFamily: "Josefin Sans",
        fontSize: "15px",
        fontWeight: 600,
        lineHeight: "15px",
        textTransform: "capitalize",
        marginTop: "20px",
        height: "44px",
        "&:hover": {
            background: "#06667C",
            color: "#FFFFFF",
        },
        width: "100%",
        padding: "5px"
    },
    cardImg: {
        maxHeight: "450px",
        paddingTop: "5%"
    },
    goBackButton: {
        background: "#E2A400",
        color: "#FFFFFF",
        borderRadius: "8px",
        fontFamily: "Josefin Sans",
        fontSize: "19px",
        fontWeight: 500,
        lineHeight: "19px",
        textTransform: "capitalize",
        marginTop: "20px",
        marginBottom: "10px",
        height: "50px",
        width: "174px",
        "&:hover": {
            background: "#E2A400",
            color: "#FFFFFF",
        },
    },
    goBackButtonMobile: {
        background: "#E2A400",
        color: "#FFFFFF",
        borderRadius: "8px",
        fontFamily: "Josefin Sans",
        fontSize: "19px",
        fontWeight: 500,
        lineHeight: "19px",
        textTransform: "capitalize",
        marginTop: "20px",
        marginBottom: "10px",
        height: "40px",
        width: "160px",
        "&:hover": {
            background: "#E2A400",
            color: "#FFFFFF",
        },
    },
    gridPadding: {
        padding: "0px 35px !important"
    },
    mainImg: {
        width: "130px",
        height:"150px",
        objectFit: "contain"
    },
    rightArrow: {
        background: "transparent",
        border: 0,
        color: "#FECD2C",
        fontSize: "20px",
        position: "absolute",
        right: window.innerWidth <= 1280 && window.innerWidth > 1024 ? "33px" : "25px",
        [theme.breakpoints.up("xl")]: {
            right: "42px",
        },
        [theme.breakpoints.down("md")]: {
            right: "20px",
        },
        [theme.breakpoints.down("sm")]: {
            right: "10px",
        },
    },
    leftArrow: {
        background: "transparent",
        border: 0,
        color: "#FECD2C",
        fontSize: "20px",
        position: "absolute",
        left: "15px",
        [theme.breakpoints.up("xl")]: {
            left: "33px",
        },
        [theme.breakpoints.down("sm")]: {
            left: "10px",
        },
    },
    headerTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "24px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "24px"
    },
    editButton: {
        width: "120px",
        borderRadius: "5px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19px",
        lineHeight: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        background: "#FECD2C",
        height: "42px",
        "&:hover, &:focus": {
            backgroundColor: "#FECD2C",
        },
        marginLeft: "15px",
    },
    alert: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "20px",
        color: "#1A405F"
    }
})

export default calculatorStyle;