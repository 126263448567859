import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";

// core components
import { Button, Hidden, InputAdornment, TextField, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import Autocomplete from "@material-ui/lab/Autocomplete";

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/assignRewardStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { Search } from '@material-ui/icons';
import ItemInfo from './ItemInfo';
import { RewardsDetail } from 'views/Pages/rewardsdetail/RewardsDetail';



export function SearchReward(props) {
    const {
        classes,
        rewards,
        rewardData,
        setRewardData,
        setTotal,
    } = props;

    return (
        <div>
            <Card className={classes.card}>
                <GridContainer style={{ alignItems: "center" }}>
                    {!rewardData ?
                        <>
                            <GridItem xs={12}>
                                <Typography className={classes.cardTitle}>
                                    Reward
                                </Typography>
                            </GridItem>
                            <GridItem xs={12}>
                                <Autocomplete
                                    id="country-select-demo"
                                    options={rewards.map((d) => ({
                                        label: d.rewardName, id: d.rewardId
                                    }))}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    onChange={(e, value) => {
                                        rewards.map((data) => {
                                            if (data.rewardId === value.id) {
                                                setRewardData(data)
                                                setTotal((Number(data.salePrice) * 100))
                                            }
                                        })
                                    }}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={classes.searchBar}
                                            placeholder='Search Reward'
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "off", // disable autocomplete and autofill
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                endAdornment: (
                                                    <InputAdornment
                                                        className={classes.adournedEnd}
                                                        position="end"
                                                    >
                                                        <Button
                                                            className={classes.searchButton}>
                                                            <Search style={{ marginRight: "5px" }} />
                                                            Search
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </GridItem>
                        </>
                        :
                        <GridItem xs={12}>
                            <ItemInfo
                                data={rewardData}
                            />
                        </GridItem>

                    }
                </GridContainer>
            </Card>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        rewards: state.rewards.rewards
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(SearchReward);
