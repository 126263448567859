import { connect } from 'react-redux';
import FormFields from 'components/Form/FormFields';
import BoundQueryField from './BoundQueryField';


function mapStateToProps(actions, accessors) {
    const Input = BoundQueryField(actions, accessors);
    return (_state, _ownProps) => ({ 
        FieldFactory: Input
    })
}

function mapDispatchToProps(_actions) {
    return (_dispatch, _ownProps) => ({
    });
}

export default (actions, accessors) => connect(mapStateToProps(actions, accessors), mapDispatchToProps(actions))(FormFields);
