import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Datetime from "react-datetime";

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/cartStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { ReactComponent as CardIcon } from "assets/img/tuzo/icons/CardIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";

// core components
import { Button, Dialog, FormControlLabel, Hidden, InputAdornment, MenuItem, Radio, Select, TextField, TextareaAutosize, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';

export function PaymentInfo(props) {
    const {
        classes,
        history,
        paymentArr,
        paymentType,
        setPaymentType,
        user,
        addPayment,
        setAddPayment,
        addPaymentMobile,
        setAddPaymentMobile,
        saveCard,
        setSaveCard,
        cardNumber,
        setCardNumber,
        name,
        setName,
        expDate,
        setExpDate,
        billingAddress,
        setBillingAddress,
        securityCode,
        setSecurityCode,
        phoneNumber,
        setPhoneNumber,
        zipcode,
        setZipcode,
    } = props;



    return (
        <div>
            <Hidden mdUp implementation='css'>
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Typography className={classes.totalCost}>
                                Payment Information
                            </Typography>
                        </GridItem>

                        <GridItem xs={12}>
                            <Typography className={classes.cardDescriptionCheckout}>
                                By placing your order, you agree to Tuzo’s{" "}
                                <span
                                    onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
                                    className={classes.cardSubtitle}>Privacy Policy</span>
                                {" "}and{" "}
                                <span
                                    onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}
                                    className={classes.cardSubtitle}>Terms of Use.</span>
                            </Typography >
                        </GridItem>

                        <GridItem xs={12}>
                            <Typography className={classes.cardDescription}>
                                Credit Card
                            </Typography >
                        </GridItem>

                        <GridItem xs={12}>
                            <Select
                                style={{ width: "100%" }}
                                className={classes.selectMenuSort}
                                value={paymentType}
                                disableUnderline
                                onChange={(e) => setPaymentType(e.target.value)}
                            >
                                {paymentArr.map((o, i) => {
                                    return (
                                        <MenuItem
                                            key={i}
                                            value={o}
                                            classes={{
                                                root: classes.selectMenuItem,
                                            }}
                                        >
                                            {o}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </GridItem>

                        <GridItem xs={12}>
                            <Button
                                className={classes.editButton}
                                onClick={() => history.push('/merchant/profile')}
                                style={{ height: "35px" }}
                            >
                                <Edit style={{ marginRight: "5px", width: "17px" }} /> Edit
                            </Button>

                            <Button
                                onClick={() => setAddPaymentMobile(true)}
                                className={classes.editButton}
                                style={{ background: "#1A405F", height: "35px", }}

                            >
                                <Add style={{ marginTop: "-3px", marginRight: "5px", width: "17px" }} /> Add Payment Method
                            </Button>
                        </GridItem>

                        <GridItem xs={12} style={{ margin: "1% 0%" }}>
                            <GridContainer>
                                <GridItem xs={5}>
                                    <Typography variant="h2" className={classes.cardDescBilling}>
                                        {/* <CardIcon />  */}
                                        Card Number
                                    </Typography>
                                </GridItem>

                                <GridItem xs={7}>
                                    <Typography variant="h2" className={classes.cardDescLessWeight} >
                                        VISA ending in 3423
                                    </Typography>
                                </GridItem>

                                <GridItem xs={5}>
                                    <Typography variant="h2" className={classes.cardDescBilling}>
                                        Expiration Date
                                    </Typography>
                                </GridItem>

                                <GridItem xs={7}>
                                    <Typography variant="h2" className={classes.cardDescLessWeight} >
                                        03/29
                                    </Typography>
                                </GridItem>

                                <GridItem xs={5}>
                                    <Typography variant="h2" className={classes.cardDescBilling}>
                                        Name on Card
                                    </Typography>
                                </GridItem>

                                <GridItem xs={7}>
                                    <Typography variant="h2" className={classes.cardDescLessWeight} >
                                        {user.firstName + " " + user.lastName}
                                    </Typography>
                                </GridItem>

                                <GridItem xs={5}>
                                    <Typography variant="h2" className={classes.cardDescBilling} style={{ lineHeight: "24px" }}>
                                        Billing Address
                                    </Typography>
                                </GridItem>

                                <GridItem xs={7}>
                                    <Typography variant="h2" className={classes.cardDescLessWeight} style={{ lineHeight: "24px" }}>
                                        24113 Hooker St Westminster, CO 90234
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </Card>

                <Dialog
                    open={addPaymentMobile}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xl"
                    onClose={() => setAddPaymentMobile(false)}
                >
                    <Card className={classes.modalCard}>
                        <GridContainer>
                            <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                                <Typography className={classes.totalCost}>Add Payment Method</Typography>
                            </GridItem>

                            <GridItem xs={12} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Card Number*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            className={classes.modalTextField}
                                            value={cardNumber}
                                            onChange={(e) => setCardNumber(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Name on Card*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            className={classes.modalTextField}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Expiration Date*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Datetime
                                            selected={expDate}
                                            dateFormat="MM/YY"
                                            onChange={(date, e) => {
                                                setExpDate(date.format("MM/YY"));
                                            }}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            closeOnClickOutside
                                            renderInput={(props, openCalendar, closeCalendar) => (
                                                <div onClick={openCalendar}>
                                                    <TextField
                                                        style={{ paddingLeft: "0px" }}
                                                        className={classes.modalTextField}
                                                        value={expDate}
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes: {
                                                                adornedEnd: classes.adournedEnd,
                                                                input: classes.inputDatetime
                                                            },
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    className={classes.adournedEnd}
                                                                    position="end"
                                                                >
                                                                    <Button className={classes.datetimeButton}>
                                                                        <CalendarIcon
                                                                            className={classes.calendarIcon}
                                                                            onClick={openCalendar}
                                                                            style={{ width: "15px", height: "15px" }}
                                                                        />
                                                                    </Button>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Billing Address*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextareaAutosize
                                            style={{ height: "80px" }}
                                            className={classes.modalTextField}
                                            value={billingAddress}
                                            onChange={(e) => setBillingAddress(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>


                            <GridItem xs={12} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Security Code*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            className={classes.modalTextField}
                                            value={securityCode}
                                            onChange={(e) => setSecurityCode(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Phone Number
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            className={classes.modalTextField}
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Zip Code*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            className={classes.modalTextField}
                                            value={zipcode}
                                            onChange={(e) => setZipcode}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} className={classes.modalGridItem} style={{ display: "flex" }}>
                                <FormControlLabel
                                    className={classes.checkboxes}
                                    control={
                                        <Radio
                                            style={{ color: "#56CA93", marginTop: "-15px", marginRight: "-15px" }}
                                            checked={saveCard}
                                            onClick={() => setSaveCard(!saveCard)}
                                            value="Use"
                                        />
                                    }
                                // value={data.rewardName}
                                />

                                <Typography className={classes.usePointsText} style={{ lineHeight: "16px" }}>
                                    Save to My Profile
                                </Typography>
                            </GridItem>

                            <GridItem xs={12}>
                                <Button
                                    className={classes.saveButton}
                                // onClick={updateUserInfo}
                                >
                                    <SaveIcon style={{ marginRight: "10px", marginTop: "-3px" }} /> Save
                                </Button>
                            </GridItem>
                            <GridItem xs={12}>
                                <Button
                                    onClick={() => setAddPaymentMobile(false)}
                                    className={classes.saveButton}
                                    style={{ background: "#E2A400" }}
                                >
                                    <Delete style={{ marginRight: "10px", marginTop: "-3px" }} /> Cancel
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </Dialog>
            </Hidden>

            <Hidden smDown implementation='css'>
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex" }}>
                            <Typography className={classes.totalCost}>
                                Payment Information
                            </Typography>

                        </GridItem>

                        <GridItem xs={12}>
                            <Typography className={classes.cardDescriptionCheckout}>
                                By placing your order, you agree to Tuzo’s{" "}
                                <span
                                    onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
                                    className={classes.cardSubtitle}>Privacy Policy</span>
                                {" "}and{" "}
                                <span
                                    onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}
                                    className={classes.cardSubtitle}>Terms of Use.</span>
                            </Typography >
                        </GridItem>

                        <GridItem xs={12} style={{ display: "flex" }}>
                            <Typography className={classes.cardDescription}>
                                Credit Card
                            </Typography >

                            <Select
                                className={classes.selectMenuSort}
                                value={paymentType}
                                disableUnderline
                                onChange={(e) => setPaymentType(e.target.value)}
                            >
                                {paymentArr.map((o, i) => {
                                    return (
                                        <MenuItem
                                            key={i}
                                            value={o}
                                            classes={{
                                                root: classes.selectMenuItem,
                                            }}
                                        >
                                            {o}
                                        </MenuItem>
                                    );
                                })}
                            </Select>

                            <Button
                                className={classes.editButton}
                                onClick={() => history.push('/merchant/profile')}
                                style={{ height: "35px" }}
                            >
                                <Edit style={{ marginRight: "5px", width: "17px" }} /> Edit
                            </Button>

                            <Button
                                onClick={() => setAddPayment(true)}
                                className={classes.editButton}
                                style={{ width: "220px", background: "#1A405F", height: "35px" }}

                            >
                                <Add style={{ marginTop: "-3px", marginRight: "5px", width: "17px" }} /> Add Payment Method
                            </Button>
                        </GridItem>

                        <GridItem xs={12} style={{ margin: "1% 0%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                <Typography variant="h2" className={classes.cardDescBilling}>
                                    <CardIcon /> Card Number
                                </Typography>

                                <Typography variant="h2" className={classes.cardDescLessWeight} >
                                    VISA ending in 3423
                                </Typography>

                                <Typography variant="h2" className={classes.cardDescBilling}>
                                    Expiration Date
                                </Typography>

                                <Typography variant="h2" className={classes.cardDescLessWeight} >
                                    03/29
                                </Typography>

                                <Typography variant="h2" className={classes.cardDescBilling}>
                                    Name on Card
                                </Typography>

                                <Typography variant="h2" className={classes.cardDescLessWeight} >
                                    {user.firstName + " " + user.lastName}
                                </Typography>
                            </div>
                        </GridItem>

                        <GridItem xs={5}>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <Typography variant="h2" className={classes.cardDescBilling} style={{ lineHeight: "24px" }}>
                                        Billing Address
                                    </Typography>
                                </GridItem>

                                <GridItem xs={6} className={classes.gridItemNoPadding}>
                                    <Typography variant="h2" className={classes.cardDescLessWeight} style={{ lineHeight: "24px" }}>
                                        24113 Hooker St Westminster, CO 90234
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </Card>

                <Dialog
                    open={addPayment}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xl"
                    onClose={() => setAddPayment(false)}
                >
                    <Card className={classes.modalCard}>
                        <GridContainer>
                            <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                                <Typography className={classes.totalCost}>Add Payment Method</Typography>

                                <Button
                                    className={classes.saveButton}
                                // onClick={updateUserInfo}
                                >
                                    <SaveIcon style={{ marginRight: "10px", marginTop: "-3px" }} /> Save
                                </Button>
                                <Button
                                    onClick={() => setAddPayment(false)}
                                    className={classes.saveButton}
                                    style={{ background: "#E2A400" }}
                                >
                                    <Delete style={{ marginRight: "10px", marginTop: "-3px" }} /> Cancel
                                </Button>
                            </GridItem>

                            <GridItem xs={6} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Card Number*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            className={classes.modalTextField}
                                            value={cardNumber}
                                            onChange={(e) => setCardNumber(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={6} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Name on Card*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            className={classes.modalTextField}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={6} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Expiration Date*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Datetime
                                            selected={expDate}
                                            dateFormat="MM/YY"
                                            onChange={(date, e) => {
                                                setExpDate(date.format("MM/YY"));
                                            }}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            closeOnClickOutside
                                            renderInput={(props, openCalendar, closeCalendar) => (
                                                <div onClick={openCalendar}>
                                                    <TextField
                                                        style={{ paddingLeft: "0px" }}
                                                        className={classes.modalTextField}
                                                        value={expDate}
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes: {
                                                                adornedEnd: classes.adournedEnd,
                                                                input: classes.inputDatetime
                                                            },
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    className={classes.adournedEnd}
                                                                    position="end"
                                                                >
                                                                    <Button className={classes.datetimeButton}>
                                                                        <CalendarIcon
                                                                            className={classes.calendarIcon}
                                                                            onClick={openCalendar}
                                                                            style={{ width: "15px", height: "15px" }}
                                                                        />
                                                                    </Button>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={6} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Billing Address*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextareaAutosize
                                            style={{ height: "80px" }}
                                            className={classes.modalTextField}
                                            value={billingAddress}
                                            onChange={(e) => setBillingAddress(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>


                            <GridItem xs={9} style={{ marginTop: "-20px" }}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Security Code*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            style={{ marginLeft: "-136px", width: "64%" }}
                                            className={classes.modalTextField}
                                            value={securityCode}
                                            onChange={(e) => setSecurityCode(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={6} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Phone Number
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            className={classes.modalTextField}
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={6} className={classes.modalGridItem}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography className={classes.modalSubtitle}>
                                            Zip Code*
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            className={classes.modalTextField}
                                            value={zipcode}
                                            onChange={(e) => setZipcode}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} className={classes.modalGridItem} style={{ display: "flex" }}>
                                <FormControlLabel
                                    className={classes.checkboxes}
                                    control={
                                        <Radio
                                            style={{ color: "#56CA93", marginTop: "-15px", marginRight: "-15px" }}
                                            checked={saveCard}
                                            onClick={() => setSaveCard(!saveCard)}
                                            value="Use"
                                        />
                                    }
                                // value={data.rewardName}
                                />

                                <Typography className={classes.usePointsText} style={{ lineHeight: "16px" }}>
                                    Save to My Profile
                                </Typography>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </Dialog>
            </Hidden>
        </div>
    )

}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

const mapDispatchToProps = (dispatch) => ({

})


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(PaymentInfo);