import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Carousel from "react-multi-carousel";
import 'views/Pages/rewards/carousel.css';
// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/activityStyle';
import withStyles from '@material-ui/core/styles/withStyles';
// import tuzoLogo from "assets/img/tuzo/tuzoRing.png"
import tuzoLogo from "assets/img/tuzo/electronicsAd.jpg"
// components
import { Button, Hidden, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1900 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 1900, min: 1366 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1350, min: 1024 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 1000, min: 0 },
        items: 1,
    },
};


export function LuxuryRewards(props) {
    const { classes, history, adData, user } = props;

    const getBgImage = (image) => {
        return image;
    }

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <Carousel
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    infinite={true}
                    swipeable={true}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    style={{ display: "flex" }}
                    arrows={false}
                >
                    {adData.map((data, index) => {
                        return (
                            <div
                                style={{
                                    backgroundImage: "linear-gradient(rgba(0,0,0,.75), rgba(0,0,0,.75)), url(" + getBgImage(data.backgroundImage) + ")",
                                    padding: "10px",
                                    minHeight: "250px",
                                    backgroundColor: "#06667C",
                                    borderRadius: "7px",
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundPhoto: "no-repeat",
                                    backgroundSize: "cover",
                                    textAlign: "center",
                                    marginTop: "5px"
                                }}
                            >
                                <GridContainer>
                                    <GridItem xs={12} style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-evenly",
                                        minHeight: "350px"
                                    }}>
                                        <Typography
                                            style={{ fontSize: "28px", lineHeight: "40px", marginBottom: "10px" }}
                                            className={classes.bannerTitle}
                                        >
                                            {data.adName}
                                        </Typography>


                                        <Typography className={classes.picDesc}>
                                            {data.description.length > 8 ? <span dangerouslySetInnerHTML={{ __html: data.description }}></span> : "No Description"}
                                        </Typography>
                                        <Button
                                            style={{ width: "90%", height: "40px", alignSelf: "center", marginTop: "10px" }}
                                            className={classes.transferButton}
                                            onClick={() => {
                                                if (user.IsoAdminCompanyName) {
                                                    history.push(
                                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/rewards`,
                                                        {

                                                            filterName: data.link

                                                        }
                                                    )
                                                }
                                                else {
                                                    history.push(
                                                        '/merchant/rewards',
                                                        {

                                                            filterName: data.link

                                                        }
                                                    )
                                                }
                                            }}
                                        >
                                            {data.buttonText}
                                        </Button>

                                    </GridItem>
                                </GridContainer>
                            </div>
                        )
                    })}
                </Carousel>
            </Hidden>

            <Hidden smDown implementation="css">
                <Carousel
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    infinite={true}
                    swipeable={true}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    style={{ display: "flex" }}
                    arrows={false}
                >
                    {adData.map((data, index) => {
                        return (
                            <div
                                style={{
                                    backgroundImage: "linear-gradient(rgba(0,0,0,.75), rgba(0,0,0,.75)), url(" + getBgImage(data.backgroundImage) + ")",
                                    padding: "10px",
                                    minHeight: "250px",
                                    backgroundColor: "#06667C",
                                    borderRadius: "7px",
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundPhoto: "no-repeat",
                                    backgroundSize: "cover",
                                    textAlign: "center",
                                    marginTop: "0px"
                                }}
                            >
                                <GridContainer>
                                    <GridItem xs={12} style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-evenly",
                                        minHeight: "350px"
                                    }}>
                                        <Typography
                                            style={{ fontSize: "28px", lineHeight: "28px", marginBottom: "10px" }}
                                            className={classes.bannerTitle}
                                        >
                                            {data.adName}
                                        </Typography>


                                        <Typography className={classes.picDesc}>
                                            {data.description.length > 8 ? <span dangerouslySetInnerHTML={{ __html: data.description }}></span> : "No Description"}
                                        </Typography>
                                        <Button
                                            style={{ width: "90%", height: "40px", alignSelf: "center", marginTop: "10px" }}
                                            className={classes.transferButton}
                                            onClick={() => {
                                                if (user.IsoAdminCompanyName) {
                                                    history.push(
                                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/rewards`,
                                                        {

                                                            filterName: data.link

                                                        }
                                                    )
                                                }
                                                else {
                                                    history.push(
                                                        '/merchant/rewards',
                                                        {

                                                            filterName: data.link

                                                        }
                                                    )
                                                }
                                            }}
                                        >
                                            {data.buttonText}
                                        </Button>

                                    </GridItem>
                                </GridContainer>
                            </div>
                        )
                    })}
                </Carousel>

            </Hidden>
        </div>

    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(LuxuryRewards));