/*eslint-disable*/
// @material-ui/icons
import Person from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';

// routes
import SettingsPage from 'views/Pages/SettingsPage.js';
import Dashboard from 'views/Pages/dashboard/Dashboard.js';
import Rewards from 'views/Pages/rewards/Rewards';
import Travel from 'views/Pages/travel/Travel';
import Profile from 'views/Pages/userprofile/Profile';
import Order from 'views/Pages/orders/Order';
import DepositReports from 'views/Pages/reports/deposits/DepositReports';
import BatchReports from 'views/Pages/reports/batch/BatchReports';
import Activity from 'views/Pages/activity/Activity';
import BatchDetails from 'views/Pages/reports/batchDetails/batchDetails';
import Cart from 'views/Pages/cart/Cart';
import Checkout from 'views/Pages/checkout/Checkout';
import RewardsDetail from 'views/Pages/rewardsdetail/RewardsDetail';
import Wishlist from 'views/Pages/wishlist/Wishlist';
import Notifications from 'views/Pages/notifications/Notifications';
import OrdersDetail from 'views/Pages/ordersdetail/OrdersDetail';
import TravelDetails from 'views/Pages/travelDetails/TravelDetails';


var merchRoutes = [
  {
    path: '/activity',
    name: 'Activity',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Activity,
    layout: '/merchant',
  },
  {
    path: '/wish-list',
    name: 'Wish List',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Wishlist,
    layout: '/merchant',
  },
  {
    path: '/reward-detail/:id',
    name: 'Rewards Detail',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: RewardsDetail,
    layout: '/merchant',
  },
  {
    path: '/rewards',
    name: 'Rewards',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Rewards,
    layout: '/merchant',
  },
  {
    path: '/travel-detail/:id',
    name: 'Travel Detail',
    component: TravelDetails,
    layout: '/merchant',
  },
  {
    path: '/travel',
    name: 'Travel',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Travel,
    layout: '/merchant',
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    layout: '/merchant',
  },
  {
    path: '/orders-detail/:id',
    name: 'Orders Detail',
    component: OrdersDetail,
    layout: '/merchant',
  },
  {
    path: '/orders',
    name: 'Orders',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Order,
    layout: '/merchant',
  },
  {
    path: '/reports/batch/:id/:id',
    name: 'Batch Details',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: BatchDetails,
    layout: '/merchant',
  },
  {
    path: '/reports/batch',
    name: 'Reports',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: BatchReports,
    layout: '/merchant',
  },
  {
    path: '/reports/deposits',
    name: 'Reports',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: DepositReports,
    layout: '/merchant',
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Profile,
    layout: '/merchant',
  },
  {
    path: '/cart',
    name: 'Cart',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Cart,
    layout: '/merchant',
  },
  {
    path: '/checkout',
    name: 'Checkout',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Checkout,
    layout: '/merchant',
  },
  // {
  //   path: '/settings',
  //   name: 'Settings',
  //   icon: SettingsIcon,
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   rtlMini: 'شع',
  //   component: SettingsPage,
  //   layout: '/merchant',
  // },
];
export default merchRoutes;

