import { connect } from "react-redux";
import ChipArray from "./ChipArray";
import { OpenMode } from "variables/constants";


function mapStateToProps(accessors) {
    return (state, ownProps) => {
        return {
            id: `${accessors.getFormName()}-${accessors.getStepName()}-${ownProps.name}`,
            readOnly: ownProps.readOnly || accessors.getMode(state) === OpenMode.READ_ONLY,
            values: accessors.getField(state, ownProps.name)
        };
    };
}

function mapDispatchToProps(actions) {
    return (dispatch, ownProps) => ({
        onChange: (values) => dispatch(actions.editField(ownProps.name, values))
    });
}

export default (actions, accessors) =>
    connect(
        mapStateToProps(accessors),
        mapDispatchToProps(actions)
    )(ChipArray);
