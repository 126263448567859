import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import ReactPaginate from "react-paginate";
import "./pagination.css";
import moment from "moment"
// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/activityStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// icons
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

// core components
import { Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem';
import { zip } from 'lodash';
import { ptsFormula } from 'globalCalculationFunction/globalCalculationFunction';

export function HistoryTable(props) {
    const {
        classes,
        history,
        headers,
        batchData,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        handlePageClick,
        docId,
        user
    } = props;

    const [points, setPoints] = useState([])

    useEffect(() => {
        setPoints([])
        batchData.map((d) => {
            const floorDollarPerBassisPts = ptsFormula(d.netAmount, user.userBasisPts)
            setPoints(prevArr => [...prevArr, floorDollarPerBassisPts])
        })
    }, [batchData])

    useEffect(() => {
        batchData.slice(index, endIndex);
    }, [index]);

    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th className={classes.tableHeaders} key={index}>
                            {key.label}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        let data = zip(batchData, points)
        return data
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <tr className={index % 2 === 0 ? classes.tableRow : classes.tableRowOdd} key={index}>
                        <td
                            className={classes.tableCell}
                        >
                            <span className={classes.spanHover}
                                onClick={() => {
                                    if (user.IsoAdminCompanyName) {
                                        history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reports/batch/${docId}/${d[0].docId}`)
                                    } else {
                                        history.push(`/merchant/reports/batch/${docId}/${d[0].docId}`)
                                    }

                                }}
                            >
                                {moment(d[0].reportDate).format("MM/DD/YYYY")}
                            </span>
                        </td>
                        <td className={classes.tableCell}>{d[0].transactionCount}</td>
                        <td className={classes.tableCell}>{Number(d[0].netAmount).toFixed(2)}</td>
                        <td className={classes.tableCell}>{d[0].batchNumber}</td>
                        <td className={classes.tableCell} style={{ fontWeight: 700 }}>{d[1]} pts</td>
                    </tr>
                );
            });
    };

    return (
        <Card className={classes.card} style={{ marginTop: "0px", maxHeight: "370px" }}>
            <GridContainer>
                <GridItem xs={12} style={{ display: "flex" }}>
                    <Typography className={classes.cardDesc}>
                        Your Earning History
                    </Typography>

                    <Typography className={classes.cardDescSmall} style={{ marginLeft: "20px" }}>
                        Your points earned on everyday transactions will show here.
                    </Typography>
                </GridItem>

                <GridItem xs={12}>
                    <table className={classes.reportsTable} style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <tbody>
                            {renderTableHeader()}
                            {renderTableData()}
                        </tbody>
                    </table>
                    <GridContainer style={{ marginBottom: "-20px" }}>
                        <GridItem xs={10} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                            <h5
                                className={classes.indexes}
                            >
                                {index + 1} - {endIndex} of{" "}
                                {Math.ceil(batchData.length)}
                            </h5>
                        </GridItem>
                        <GridItem xs={2}>
                            <ReactPaginate
                                paginationSize={0}
                                onPageChange={handlePageClick}
                                pageCount={Math.ceil(batchData.length / itemsPerPage)}
                                nextLabel={
                                    <ArrowRight
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "white",
                                        }}
                                    />
                                }
                                previousLabel={
                                    <ArrowLeft
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "white",
                                        }}
                                    />
                                }
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </GridItem>
                    </GridContainer>

                </GridItem>
            </GridContainer>
        </Card>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        userId: state.auth.user.uid
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(HistoryTable));