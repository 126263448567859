import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Datetime from "react-datetime";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import moment from "moment";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import { FormControl, InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core';

// @material-ui/icons
import { ReactComponent as Plane } from "assets/img/tuzo/icons/Plane.svg";
import { ReactComponent as Experience } from "assets/img/tuzo/icons/Experience.svg";
import { ReactComponent as Cruise } from "assets/img/tuzo/icons/Cruise.svg";
import { ReactComponent as Hotel } from "assets/img/tuzo/icons/Hotel.svg";
import { ReactComponent as SearchIcon } from "assets/img/tuzo/icons/SearchIconGreen.svg";
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";
import { ReactComponent as Person } from "assets/img/tuzo/icons/Person.svg";

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/travelStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import "./calendar.css"

export function CarRentalFilter(props) {
    const {
        classes,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        searchCarRental,
        dataLoaded,
        handleSelect,
        address,
        setAddress,
        radius,
        setRadius,
        radiusArr,
        disablePastDt,
        disableFromDt,
        pickUpTime,
        setPickUpTime,
        dropOffTime,
        setDropOffTime,
        dropOffAddress,
        setDropOffAddress
    } = props;

    return (
        <div>
            <GridItem xs={12} style={{ marginTop: "20px" }}>
                <GridContainer>
                    <GridItem xs={3} className={classes.gridItemFlexCenter}>
                        <PlacesAutocomplete
                            value={address}
                            onChange={(e) => setAddress(e)}
                            onSelect={(e) => handleSelect(e, "Pick Up At")}
                            googleCallbackName={"initOne"}
                            searchOptions={{
                                componentRestrictions: { country: "us" },
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <FormControl>
                                        <TextField
                                            className={classes.searchBar}
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            InputProps={{
                                                autocomplete: "new-password",
                                                form: {
                                                    autocomplete: "off",
                                                },
                                                disableUnderline: true,
                                                classes: {
                                                    root: classes.searchBar,
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                startAdornment: (

                                                    <InputAdornment
                                                        position="start"
                                                    >
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...getInputProps({
                                                placeholder: "Pick Up At",
                                                className: "location-search-input",
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? {
                                                        backgroundColor: "#fafafa",
                                                        cursor: "pointer",
                                                        padding: "10px"
                                                    }
                                                    : {
                                                        backgroundColor: "#ffffff",
                                                        cursor: "pointer",
                                                        padding: "10px"
                                                    };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </FormControl>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </GridItem>
                    <GridItem xs={7} className={classes.gridItemFlexCenter}>
                        <Typography variant="h2" className={classes.travelFilterDesc} style={{ marginLeft: "0px" }}>
                            From
                        </Typography>

                        <Datetime
                            isValidDate={disablePastDt}
                            className='travel'
                            selected={fromDate}
                            dateFormat="MM/DD/YY"
                            onChange={(date, e) => {
                                setFromDate(date.format("MM/DD/YY"));
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                            closeOnClickOutside
                            renderInput={(props, openCalendar, closeCalendar) => (
                                <div onClick={openCalendar}>
                                    <TextField
                                        className={classes.datetime}
                                        value={fromDate}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                            endAdornment: (
                                                <InputAdornment
                                                    className={classes.adournedEnd}
                                                    position="end"
                                                >
                                                    <Button className={classes.datetimeButton}>
                                                        <CalendarIcon
                                                            className={classes.calendarIcon}
                                                            onClick={openCalendar}
                                                        />
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        />

                        <Typography variant="h2" className={classes.travelFilterDesc} >
                            Pick Up Time
                        </Typography>

                        <Datetime
                            isValidDate={disablePastDt}
                            className='travel'
                            selected={pickUpTime}
                            onChange={(time) => {
                                setPickUpTime(time.format("h:mm a"))
                            }}
                            timeFormat={true}
                            dateFormat={false}
                            closeOnSelect={true}
                            closeOnClickOutside
                            renderInput={(props, openCalendar, closeCalendar) => (
                                <div onClick={openCalendar}>
                                    <TextField
                                        className={classes.datetime}
                                        value={pickUpTime}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                            endAdornment: (
                                                <InputAdornment
                                                    className={classes.adournedEnd}
                                                    position="end"
                                                >
                                                    <Button className={classes.datetimeButton}>
                                                        <CalendarIcon
                                                            className={classes.calendarIcon}
                                                            onClick={openCalendar}
                                                        />
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        />


                        <Typography variant="h2" className={classes.travelFilterDesc}>
                            Radius(mi)
                        </Typography>

                        <Select
                            className={classes.selectMenuSortTravelPage}
                            value={radius}
                            disableUnderline
                            classes={{
                                root: classes.selectText,
                            }}
                            onChange={(e) => {
                                setRadius(e.target.value)
                            }}
                        >
                            {radiusArr.map((o, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={o}
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        {o}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </GridItem>

                    <GridItem xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                        {!dataLoaded ?
                            <Button
                                style={{ background: "#1A405F", marginTop: "-5px", width: "100%" }}
                                className={classes.travelFiltersButton}
                                onClick={() => searchCarRental()}
                            >
                                Search
                            </Button>
                            :
                            <Button
                                style={{ marginTop: "-5px", }}
                                className={classes.updateButton}
                                onClick={() => searchCarRental()}
                            >
                                Update
                            </Button>
                        }

                    </GridItem>
                </GridContainer>
            </GridItem>

            <GridItem xs={12} style={{ marginTop: "20px" }}>
                <GridContainer>
                    <GridItem xs={3} className={classes.gridItemFlexCenter}>
                        <PlacesAutocomplete
                            value={dropOffAddress}
                            onChange={(e) => setDropOffAddress(e)}
                            onSelect={(e) => handleSelect(e, "Drop Off At")}
                            googleCallbackName={"initOne"}
                            searchOptions={{
                                componentRestrictions: { country: "us" },
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <FormControl>
                                        <TextField
                                            className={classes.searchBar}
                                            value={dropOffAddress}
                                            onChange={(e) => setDropOffAddress(e.target.value)}
                                            InputProps={{
                                                autocomplete: "new-password",
                                                form: {
                                                    autocomplete: "off",
                                                },
                                                disableUnderline: true,
                                                classes: {
                                                    root: classes.searchBar,
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                startAdornment: (

                                                    <InputAdornment
                                                        position="start"
                                                    >
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...getInputProps({
                                                placeholder: "Drop Off At",
                                                className: "location-search-input",
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? {
                                                        backgroundColor: "#fafafa",
                                                        cursor: "pointer",
                                                        padding: "10px"
                                                    }
                                                    : {
                                                        backgroundColor: "#ffffff",
                                                        cursor: "pointer",
                                                        padding: "10px"
                                                    };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </FormControl>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </GridItem>
                    <GridItem xs={7} className={classes.gridItemFlexCenter}>
                        <Typography variant="h2" className={classes.travelFilterDesc} style={{ marginLeft: "0px" }}>
                            To
                        </Typography>

                        <Datetime
                            isValidDate={disableFromDt}
                            className='travel'
                            selected={toDate}
                            dateFormat="MM/DD/YY"
                            onChange={(date, e) => {
                                setToDate(date.format("MM/DD/YY"));
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                            closeOnClickOutside
                            renderInput={(props, openCalendar, closeCalendar) => (
                                <div onClick={openCalendar}>
                                    <TextField
                                        style={{ paddingLeft: "20px" }}
                                        className={classes.datetime}
                                        value={toDate}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                            endAdornment: (
                                                <InputAdornment
                                                    className={classes.adournedEnd}
                                                    position="end"
                                                >
                                                    <Button className={classes.datetimeButton}>
                                                        <CalendarIcon
                                                            className={classes.calendarIcon}
                                                            onClick={openCalendar}
                                                        />
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        />

                        <Typography variant="h2" className={classes.travelFilterDesc} >
                            Drop Off Time
                        </Typography>

                        <Datetime
                            isValidDate={disablePastDt}
                            className='travel'
                            selected={dropOffTime}
                            onChange={(time) => {
                                setDropOffTime(time.format("h:mm a"))
                            }}
                            timeFormat={true}
                            dateFormat={false}
                            closeOnSelect={true}
                            closeOnClickOutside
                            renderInput={(props, openCalendar, closeCalendar) => (
                                <div onClick={openCalendar}>
                                    <TextField
                                        className={classes.datetime}
                                        value={dropOffTime}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                            endAdornment: (
                                                <InputAdornment
                                                    className={classes.adournedEnd}
                                                    position="end"
                                                >
                                                    <Button className={classes.datetimeButton}>
                                                        <CalendarIcon
                                                            className={classes.calendarIcon}
                                                            onClick={openCalendar}
                                                        />
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        />

                    </GridItem>
                </GridContainer>
            </GridItem>
        </div>
    )

}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(CarRentalFilter));