import React from "react";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/cardFieldsStyle";

function findChild(children, name) {
    return children && children.find(child=>child.props.name === name);
}

function classesFor(classes, name) {
    return {
        label: classes[name+"_label"] || classes.label,
        value: classes[name+"_value"] || classes[name] || classes.value
    }
}

function Wrapper(props) {
    return (
        <GridItem xs={12} sm={props.width}>
            { props.children }
        </GridItem>
    );
}

export function CardFields(props) {
    const { classes, order, FieldFactory, children, key, index } = props;
    const widths = props.widths || {}

    return (
        <GridContainer className={classes.root}>
            {                
                order
                    ? order.map((name) => 
                        <Wrapper width={widths[name] || 6} key={name}>
                            { findChild(children, name) || <FieldFactory name={name} key={key} index={index} classes={classesFor(classes, name)}/> }
                        </Wrapper>
                    )
                    : children.map((child, index) => 
                        <Wrapper width={(child.props.name && widths[child.props.name]) || 6} key={index}>
                            {child}
                        </Wrapper>
                    )                
            }
        </GridContainer>
    )
}

export default withStyles(styles)(CardFields);

