import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = (theme) => ({
  container: {
    ...container,
    backgroundColor: "#fff",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
      margin: "0 0 0 -200px"
    },
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  img: {
    height: "20vh",
    margin: "20px 0 0 0",
  },
  largeImg: {
    height: "32vh",
    margin: "20px 0 0 0",
  },
  subdescription: {
    color: "#000",
  },
  signUpButton: {
    backgroundColor: "#f3684e",
    width: "100%",
    height: "8vh",
    fontSize: "1.3rem",
    marginBottom: "20px",
    "&:hover": {
      backgroundColor: "#f3684e",
    }
  },
  logInButton: {
    backgroundColor: "#05c6a3",
    width: "100%",
    height: "8vh",
    fontSize: "1.3rem",
    "&:hover": {
      backgroundColor: "#05c6a3",
    }
  },
  forgotPwGoBackButton : {
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: "10px",
    width: "48% !important",
    borderRadius: "8px",
    textTransform: "capitalize"
  },
  continueButton: {
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: "10px",
    width: "100% !important",
    borderRadius: "8px",
    textTransform: "capitalize"
  },
  continueButtonMobile: {
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: "10px",
    width: "48% !important",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "0.75rem 2.25rem"
  },
  goBackButtonMobile: {
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: "10px",
    width: "35% !important",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "0.75rem 2.25rem",
    backgroundColor: "#56CA93",
    color: "white !important",
  },
  formContainer: {
    display: "block",
    margin: "0 0 0 200px",
  },
  mainContainer: {
    width: "38%",
    maxWidth: "40rem",
    minHeight: "auto",
    maxHeight: "32rem",
    background: "rgba(249, 249, 249, 1)",
    position: "absolute",
    right: "8%",
    bottom: "30%",
    borderRadius: "10px",
    paddingBottom: "15px",
    paddingTop: "15px",
    [theme.breakpoints.down('md')]: {
      bottom: "3%",
    },
    [theme.breakpoints.up('lg')]: {
      bottom: "22%"
    },
    [theme.breakpoints.up('xl')]: {
      bottom: "20%"
    }
  },
  isoMainContainer: {
    width: "70%",
    minHeight: "auto",
    maxHeight: "32rem",
    background: "rgba(249, 249, 249, 1)",
    borderRadius: "10px",
    padding: "10px",
  },
  linkContainer: {
    width: "38%",
    maxWidth: "40rem",
    minHeight: "auto",
    maxHeight: "32rem",
    position: "absolute",
    right: "8%",
    bottom: "30%",
    paddingBottom: "15px",
    paddingTop: "15px",
    [theme.breakpoints.down('md')]: {
      bottom: "1%",
    },
    [theme.breakpoints.up('lg')]: {
      bottom: "10%"
    },
    // [theme.breakpoints.up('xl')]: {
    //   bottom: "20%"
    // }
  },
  linkContainerMobile: {
    width: "85%",
    maxWidth: "40rem",
    minHeight: "auto",
    maxHeight: "32rem",
    position: "absolute",
    right: "8%",
    bottom: "15%",
    paddingBottom: "15px",
    paddingTop: "15px",
    [theme.breakpoints.down('392')]: {
      bottom: "10%"
    },
    [theme.breakpoints.down('380')]: {
      bottom: "3%"
    }

  },
  linkContainerMobileErr: {
    width: "85%",
    maxWidth: "40rem",
    minHeight: "auto",
    maxHeight: "32rem",
    position: "absolute",
    right: "8%",
    bottom: "15%",
    paddingBottom: "15px",
    paddingTop: "15px",
    [theme.breakpoints.down('392')]: {
      bottom: "6%"
    },
    [theme.breakpoints.down('380')]: {
      bottom: "3%"
    }

  },
  mainContainerForgotPw: {
    width: "43%",
    maxWidth: "40rem",
    height: "34%",
    maxHeight: "32rem",
    background: "rgba(249, 249, 249, 1)",
    position: "absolute",
    right: "8%",
    bottom: "32%",
    borderRadius: "10px",
    // border: "3px solid rgba(15, 55, 87, 0.75)",
    [theme.breakpoints.up('1420')]: {
      height: "27%",
    },
    [theme.breakpoints.down('1396')]: {
      height: "32%",
    },
    [theme.breakpoints.down('1366')]: {
      height: "38%",
    },
    [theme.breakpoints.down('sm')]: {
      right: "6px",
      width: "380px",
      bottom: "135px",
    },
    
  },
  mobileForgotPwMainContainer: {
    width: "85%",
    height: "100%",
    background: "rgba(249, 249, 249, 1) !important",
    margin: "60px auto 0px auto",
    padding: "10px",
    top: "225px",
    [theme.breakpoints.down('380')]: {
      top: "108px",
    },
    [theme.breakpoints.down('365')]: {
      top: "150px",
    }
  },
  mobileMainContainer: {
    width: "85%",
    height: "100%",
    background: "rgba(249, 249, 249, 1) !important",
    margin: "60px auto 0px auto",
    padding: "10px",
    top: "225px",
    [theme.breakpoints.down('380')]: {
      top: "108px",
    },
    [theme.breakpoints.down('365')]: {
      top: "150px",
    }
  },
  mobileIsoMainContainer: {
    width: "85%",
    height: "100%",
    background: "rgba(249, 249, 249, 1) !important",
    margin: "30px auto 0px auto",
    padding: "10px",
    paddingBottom: "30px"
  },
  cardHeaderTitle: {
    color: "#004253",
    fontSize: "25px",
    fontWeight: 700,
    fontFamily: "Josefin Sans",
    marginBottom: "-25px"
  },
  cardHeaderTitleLineHeight: {
    color: "#004253",
    fontSize: "25px",
    fontWeight: 700,
    fontFamily: "Josefin Sans",
    marginBottom: "-25px",
    lineHeight: "30px"
  },
  cardBody: {
    padding: "30px 20px 0"
  },
  cardBodyForgotPw: {
    padding: "15px 20px 0"
  },
  cardBodyMobile: {
    padding: "10px 20px 0"
  },
  authBtn: {
    margin: "0px 10px",
    position: "absolute",
    top: "-45px",
    height: "80px",
    width: "100px"
  },
  clickHere: {
    color: "rgba(94, 177, 227, 1)",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  textDanger: {
    fontFamily: "Josefin Sans",
    // paddingLeft: "20px",
    margin: "6% 0% -6% 0%",
    fontSize: "16px",
    color: "#004253",
  },
  googleLogo: {
    background: whiteColor,
    margin: "0px 10px",
    position: "absolute",
    top: "-10%",
    height: "80px",
    width: "80px",
    borderRadius: "10px",
    border: "2px solid rgba(15, 55, 87, 0.75)",
  },
  microsoftLogo: {
    background: whiteColor,
    margin: "0px 10px",
    position: "absolute",
    top: "-10%",
    height: "80px",
    width: "80px",
    borderRadius: "10px",
    border: "2px solid rgba(15, 55, 87, 0.75)",
    left: "20%",
    position: "absolute",
    [theme.breakpoints.down('sm')]: {
      right: "260px",
    }
  },
  fbLogo: {
    background: whiteColor,
    margin: "0px 10px",
    position: "absolute",
    top: "-10%",
    height: "80px",
    width: "80px",
    borderRadius: "10px",
    border: "2px solid rgba(15, 55, 87, 0.75)",
    right: "20%",
    [theme.breakpoints.down('sm')]: {
      right: "15px",
    }
  },
  loginCardHeader: {
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "24px",
    marginTop: "1rem",
    textAlign: "left",
    justifyContent: "left",
    color: "#004253",
  },
  forgotYourPassword: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "#929292",
    "&:hover": {
      cursor: "pointer",
    },
  },
  forgotYourPasswordMobile: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "#929292",
    // marginLeft: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rememberMe: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "#004253",
  },
  rememberMeMobile: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "#004253",
    whiteSpace: "nowrap",
    marginTop: "10px"
  },
  resetPwButton: {
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: "10px",
    width: "48%",
    borderRadius: "8px",
    textTransform: "capitalize",
    backgroundColor: "#1A405F !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: "#1A405F",
    }
  },
  resetPwButtonMobile: {
    fontFamily: "Josefin Sans",
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "10px",
    width: "48%",
    height: "42px",
    borderRadius: "8px",
    textTransform: "capitalize",
    backgroundColor: "#1A405F !important",
    color: "white !important",
    whiteSpace: "break-spaces",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "#1A405F",
    },
  },
  termsStyle: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "white",
    "&:hover": {
      cursor: "pointer",
    },
    textDecoration: "underline"
  },
  logoImg: {
    width: "90%",
    height: "200px",
    objectFit: "contain",
    paddingTop:"15px"
  },
  gridItemFlexCenter: {
    display: "flex",
    justifyContent: "center"
  },
  isoTermsStyle: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "#1A405F",
    "&:hover": {
      cursor: "pointer",
    },
    textDecoration: "underline"
  },

});
export default loginPageStyle;
