import React from "react";
import Chip from "@material-ui/core/Chip";
import { startCase } from "lodash";
import { Sorts } from "variables/constants";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

//import withStyles from "@material-ui/core/styles/withStyles";
//import Style from "assets/jss/material-dashboard-pro-react/components/searchDialogStyle"

function getIcon(order) {
    switch (order) {
        // Yes, I know, odd that an upward arrow represents a descending sort.
        // It makes sense as you think of the arrow as pointing in the 'natural direction'
        // of the data. 
        case Sorts.DESCENDING: return <ArrowUpward/>;
        default: return <ArrowDownward/>;
    }
}

/**
 * 
 * A dialog box which contains all the fields specified in a config parameter.
 * 
 */
export default function SortChip(props) {
    const { getLabel = ()=>undefined, field, order, ...rest } = props;

    let label = getLabel(props.field);
    if (label === undefined) label = startCase(props.field)

    const icon = getIcon(order);

    return (
        <Chip {...rest} 
            label={label}
            icon={icon}
        />
    );
}

//export default withStyles(Style)(SearchDialog);