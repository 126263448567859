import React, { useEffect, useState } from "react";
import DonutChart from "react-donut-chart";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Filter from "./components/Filter";
import Table from "./components/Table";
//core components
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Hidden from "@material-ui/core/Hidden";
import { Typography } from "@material-ui/core";
import Loader from "components/Loader/Loader";

// styling
import withStyles from "@material-ui/core/styles/withStyles";
import Style from "assets/jss/material-dashboard-pro-react/views/isoAdminDashboardStyle";

// @material-ui/icons
import { AiOutlineDollar } from "react-icons/ai";
import { Add } from "@material-ui/icons";
import { db } from "firebase/client";
import { sendIrisGetFunction } from "firebase/client";
import { filter } from "lodash";
import DateRange from "./components/DateRange";

const statusArr = ["Total Points", "Name", "Joined"];

const ascArr = ["Asc", "Desc"];

const entriesArr = [1, 2, 3, 4, 5, 6];

const userTypeArr = ["Sub ISO", "Staff", "Merchants"];

const headersISO = [
  { label: "Points", key: "pts" },
  { label: "Name", key: "name" },
  { label: "Company Name", key: "companyName" },
  { label: "Email", key: "email" },
  { label: "Status", key: "status" },
];

const userHeaders = [
  { label: "Points", key: "pts" },
  { label: "Pending", key: "pending" },
  { label: "Name", key: "name" },
  { label: "Company Name", key: "companyName" },
  { label: "Email", key: "email" },
  { label: "Type", key: "type" },
  { label: "Status", key: "status" },
  { label: "Joined", key: "joined" },
];

export function Dashboard(props) {
  const { classes, history, userCompany } = props;

  const [statusDropdown, setStatusDropdown] = useState("Total Points");
  const [ascDropdown, setAscDropdown] = useState("Asc");
  const [searchString, setSearchString] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [data, setData] = useState(false);
  const [copyArr, setCopyArr] = useState(false);
  const [transactionLen, setTransactionLen] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [userTypeArrFilter, setUserTypeArrFilter] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isoAdminReportData, setIsoAdminReportData] = useState(false);
  const csvLinkRef = React.createRef();

  const numberFormatter = new Intl.NumberFormat("en-US", {});

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const handleSearch = () => {
    if (searchString) {
      const filtered = copyArr.filter((f) => {
        if (
          (f.firstName &&
            f.firstName.toLowerCase().includes(searchString.toLowerCase())) ||
          (f.lastName &&
            f.lastName.toLowerCase().includes(searchString.toLowerCase())) ||
          (f.companyName &&
            f.companyName.toLowerCase().includes(searchString.toLowerCase())) ||
          (f.email &&
            f.email.toLowerCase().includes(searchString.toLowerCase())) ||
          (f.merchantId &&
            f.merchantId.toLowerCase().includes(searchString.toLowerCase()))
        ) {
          return true;
        }
      });
      if (filtered.length === 0) {
        setEmptyData(true);
      }
      setData(filtered);
    } else {
      setData(copyArr);
    }
  };

  const resetEmptyData = () => {
    setEmptyData(false);
    setSearchString("");
    setData(copyArr);
  };

  const resetPageFilter = (filterArr) => {
    // setZippedArr([...filterArr])
    setData([...filterArr]);
    setIndex(0);
    setEndIndex(itemsPerPage);
    setPageNumber(0);
  };

  const resetPageFilterISO = () => {
    setIndex(0);
    setEndIndex(itemsPerPage);
    setPageNumber(0);
  };

  const handleFilter = (ascValue, sortValue) => {
    if (ascValue === "Asc") {
      if (sortValue === "Total Points") {
        const filtered = data.sort(
          (a, b) =>
            (b.approvedPts != null ? b.approvedPts : -Infinity) -
            (a.approvedPts != null ? a.approvedPts : -Infinity)
        );
        resetPageFilter(filtered);
      }
      if (sortValue === "Name") {
        const filtered = data.sort(function (a, b) {
          if (a.contact) {
            if (a.contact < b.contact) {
              return -1;
            }
            if (a.contact > b.contact) {
              return 1;
            }
            return 0;
          } else {
            if (a.lastName < b.lastName) {
              return -1;
            }
            if (a.lastName > b.lastName) {
              return 1;
            }
            return 0;
          }
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "Joined") {
        const filtered = data.sort(function (a, b) {
          return (
            (a.timestamp != null ? a.timestamp.seconds : -Infinity) -
            (b.timestamp != null ? b.timestamp.seconds : -Infinity)
          );
        });
        resetPageFilter(filtered);
      }
    }
    if (ascValue === "Desc") {
      if (sortValue === "Total Points") {
        const filtered = data.sort(
          (a, b) =>
            (a.approvedPts != null ? a.approvedPts : -Infinity) -
            (b.approvedPts != null ? b.approvedPts : -Infinity)
        );
        resetPageFilter(filtered);
      }
      if (sortValue === "Name") {
        const filtered = data.sort(function (a, b) {
          if (a.contact) {
            if (a.contact > b.contact) {
              return -1;
            }
            if (a.contact < b.contact) {
              return 1;
            }
            return 0;
          } else {
            if (a.lastName > b.lastName) {
              return -1;
            }
            if (a.lastName < b.lastName) {
              return 1;
            }
            return 0;
          }
        });
        resetPageFilter(filtered);
      }
      if (sortValue === "Joined") {
        const filtered = data.sort(function (a, b) {
          return (
            (b.timestamp != null ? b.timestamp.seconds : -Infinity) -
            (a.timestamp != null ? a.timestamp.seconds : -Infinity)
          );
        });
        resetPageFilter(filtered);
      }
    }
  };

  const handleUserTypeFilter = (event) => {
    let userArr = [];
    const {
      target: { value },
    } = event;
    setUserTypeArrFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    if (value.length === 0) {
      setData(copyArr);
    } else {
      copyArr.map((data) => {
        if (value.includes("Staff") && data.userType === "Staff") {
          userArr.push(data);
        } else if (
          data.userType.includes("Merchant") &&
          value.includes("Merchants")
        ) {
          userArr.push(data);
        } else if (
          data.userType.includes("SubIso") &&
          value.includes("Sub ISO")
        ) {
          userArr.push(data);
        } else {
          if (value.includes(data.userType)) {
            userArr.push(data);
          }
        }
      });
      if (ascDropdown === "Asc") {
        userArr.sort(function (a, b) {
          if (a.userType < b.userType) {
            return -1;
          }
          if (a.userType > b.userType) {
            return 1;
          }
          return 0;
        });
        resetPageFilterISO();
        setData(userArr);
      }
      if (ascDropdown === "Desc") {
        userArr.sort(function (a, b) {
          if (a[0].userType > b[0].userType) {
            return -1;
          }
          if (a[0].userType < b[0].userType) {
            return 1;
          }
          return 0;
        });
        resetPageFilterISO();
        setData(userArr);
      }
    }
  };

  const getIsoAdminReport = () => {
    let arr = [];
    let pendArr = [];
    let combineArr = [];
    let docArr = [];
    let filtered = [];

    return db
      .collection("users")
      .where("IsoAdminCompanyName", "==", userCompany)
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            s = s.data();
            arr.push(s);
          });
          // setData(arr)
        } else {
          // setData([])
        }
        // db
        //     .collection('iso-temp-user')
        //     .where("IsoAdminCompanyName", "==", userCompany)
        //     .get()
        //     .then((snapshots) => {
        //         if (!snapshots.empty) {
        //             snapshots.forEach((s) => {
        //                 s = s.data()
        //                 pendArr.push(s)
        //             })
        //             combineArr = [...arr, ...pendArr]
        //             // setData(combineArr)
        //         }
        //         else {
        //             combineArr = [...arr, ...pendArr]
        //             // setData(combineArr)
        //         }
        for (let i = 0; i < combineArr.length; i++) {
          if (combineArr[i].merchantId) {
            db.collection("merchants-live")
              .where("merchantNumber", "==", combineArr[i].merchantId)
              .get()
              .then((snapshots) => {
                if (!snapshots.empty) {
                  snapshots.docs.forEach((doc) => {
                    db.collection("merchants-live")
                      .doc(doc.data().docId)
                      .collection("batch-summary")
                      .get()
                      .then((subSnapshot) => {
                        if (!subSnapshot.empty) {
                          subSnapshot.docs.forEach((subDoc) => {
                            subDoc = subDoc.data();
                            let merchantName = combineArr[i].companyName;
                            let email = combineArr[i].email;
                            let userBasisPts = combineArr[i].userBasisPts;
                            let newObj = {
                              ...subDoc,
                              merchantName,
                              email,
                              parentDocId: doc.data().docId,
                              userBasisPts,
                            };
                            docArr.push(newObj);
                          });
                          setIsoAdminReportData(docArr);
                        } else {
                          setIsoAdminReportData([]);
                        }
                      });
                  });
                } else {
                  setIsoAdminReportData([]);
                }
              });
          }
        }

        // })
      });
  };

  const getData = () => {
    let arr = [];
    let pendArr = [];
    let combineArr = [];

    let count = 0;
    return db
      .collection("users")
      .where("IsoAdminCompanyName", "==", userCompany)
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            s = s.data();
            arr.push(s);
          });
          setData(arr);
          setCopyArr(arr);
        } else {
          setData(arr);
          setCopyArr(arr);
        }
        db.collection("temp-user")
          .where("IsoAdminCompanyName", "==", userCompany)
          .get()
          .then((snapshots) => {
            if (!snapshots.empty) {
              snapshots.forEach((s) => {
                s = s.data();
                let newObj = {
                  ...s,
                  status: "Pending",
                };
                pendArr.push(newObj);
              });
              combineArr = [...arr, ...pendArr];
              setData(combineArr);
              setCopyArr(combineArr);
            } else {
              combineArr = [...arr, ...pendArr];
              setData(combineArr);
              setCopyArr(combineArr);
            }
          });
        // db.collection('iso-temp-user')
        //     .where("IsoAdminCompanyName", "==", userCompany)
        //     .get()
        //     .then((snapshots) => {
        //         if (!snapshots.empty) {
        //             snapshots.forEach((s) => {
        //                 s = s.data()
        //                 let newObj = {
        //                     ...s,
        //                     status: "Pending"
        //                 }
        //                 pendArr.push(newObj)
        //             })
        //             combineArr = [...arr, ...pendArr]
        //             setData(combineArr)
        //             setCopyArr(combineArr)
        //         }
        //         else {
        //             combineArr = [...arr, ...pendArr]
        //             setData(combineArr)
        //             setCopyArr(combineArr)
        //         }
        for (let i = 0; i < combineArr.length; i++) {
          if (combineArr[i].merchantId) {
            db.collection("merchants-live")
              .where("merchantNumber", "==", combineArr[i].merchantId)
              .get()
              .then((snapshots) => {
                if (!snapshots.empty) {
                  snapshots.docs.forEach((doc) => {
                    let docId = doc.data().docId;
                    db.collection("merchants-live")
                      .doc(docId)
                      .collection("batch-summary")
                      .get()
                      .then((subSnapshot) => {
                        subSnapshot.docs.forEach((subDoc) => {
                          subDoc = subDoc.data();
                          count += Number(subDoc.transactionCount);
                        });
                        setTransactionLen(count);
                      });
                  });
                } else {
                  setTransactionLen("0");
                }
              });
          }
        }

        // })
      });
  };

  useEffect(() => {
    if (!data) {
      getData();
    }
  }, []);

  if (!data) return <Loader />;

  return (
    <div>
      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={2}>
            <Typography
              variant="h2"
              className={classes.dashboardTitle}
              style={{ paddingBottom: "10%" }}
            >
              Users
            </Typography>

            <Button
              className={classes.addButton}
              onClick={() => {
                history.push(
                  `/iso-admin/${userCompany
                    .replace(/ /g, "")
                    .toLowerCase()}/add-merchant`
                );
              }}
            >
              <Add /> Invite User
            </Button>
          </GridItem>

          <GridItem xs={3}>
            <Card className={classes.card}>
              <GridContainer style={{ marginBottom: "15px" }}>
                <GridItem xs={2}>
                  <AiOutlineDollar style={{ marginTop: "5px" }} />
                </GridItem>
                <GridItem xs={5}>Total Users</GridItem>
                <GridItem
                  xs={5}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: 600,
                    fontSize: "21px",
                  }}
                >
                  {numberFormatter.format(copyArr.length)}
                </GridItem>
              </GridContainer>
              <div style={{ borderTop: "2px solid #FECD2C" }} />
              <GridContainer style={{ marginTop: "15px" }}>
                <GridItem xs={2}>
                  <AiOutlineDollar style={{ marginTop: "5px" }} />
                </GridItem>
                <GridItem xs={5}>Total Transactions</GridItem>
                <GridItem
                  xs={5}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: 600,
                    fontSize: "21px",
                  }}
                >
                  {numberFormatter.format(transactionLen)}
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={7}>
            <Card className={classes.card}>
              <GridContainer style={{ marginTop: "20px" }}>
                <GridItem xs={2}>
                  <p className={classes.circleChartDesc}>
                    Wish List Items{"\n"}by Category
                  </p>
                </GridItem>
                <GridItem xs={10}>
                  <GridContainer>
                    <GridItem xs={3}>
                      <div style={{ display: "flex" }}>
                        <DonutChart
                          data={[
                            {
                              // label: 'Luxury Goods',
                              value: 0,
                            },
                            {
                              label: "",
                              value: 0,
                              isEmpty: true,
                            },
                          ]}
                          innerRadius={0.5}
                          outerRadius={0.9}
                          legend={false}
                          interactive={false}
                          height={100}
                          width={50}
                          formatValues={() =>
                            `${((50 / 100) * 100).toFixed(2)}%`
                          }
                          colors={["#E2A400"]}
                          strokeColor="#E2A400"
                          colorFunction={(colors, index) =>
                            colors[index % colors.length]
                          }
                          emptyColor={"#FECD2C"}
                        />
                        <p className={classes.circleChartLabel}>
                          Luxury{"\n"}Goods
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={3}>
                      <div style={{ display: "flex" }}>
                        <DonutChart
                          data={[
                            {
                              // label: 'Luxury Goods',
                              value: 0,
                            },
                            {
                              value: 0,
                              isEmpty: true,
                            },
                          ]}
                          innerRadius={0.5}
                          outerRadius={0.9}
                          legend={false}
                          interactive={false}
                          height={100}
                          width={50}
                          formatValues={() =>
                            `${((50 / 100) * 100).toFixed(2)}%`
                          }
                          colors={["#E2A400"]}
                          strokeColor="#E2A400"
                          colorFunction={(colors, index) =>
                            colors[index % colors.length]
                          }
                          emptyColor={"#FECD2C"}
                        />
                        <p className={classes.circleChartLabel}>Electronics</p>
                      </div>
                    </GridItem>
                    <GridItem xs={3}>
                      <div style={{ display: "flex" }}>
                        <DonutChart
                          data={[
                            {
                              // label: 'Luxury Goods',
                              value: 0,
                            },
                            {
                              value: 0,
                              isEmpty: true,
                            },
                          ]}
                          innerRadius={0.5}
                          outerRadius={0.9}
                          legend={false}
                          interactive={false}
                          height={100}
                          width={50}
                          formatValues={() =>
                            `${((50 / 100) * 100).toFixed(2)}%`
                          }
                          colors={["#E2A400"]}
                          strokeColor="#E2A400"
                          colorFunction={(colors, index) =>
                            colors[index % colors.length]
                          }
                          emptyColor={"#FECD2C"}
                        />
                        <p className={classes.circleChartLabel}>Travel</p>
                      </div>
                    </GridItem>
                    <GridItem xs={3}>
                      <div style={{ display: "flex" }}>
                        <DonutChart
                          data={[
                            {
                              // label: 'Luxury Goods',
                              value: 0,
                            },
                            // {
                            //     label: '',
                            //     value: 100 - 10,
                            //     isEmpty: true,
                            // },
                          ]}
                          innerRadius={0.5}
                          outerRadius={0.9}
                          legend={false}
                          interactive={false}
                          height={100}
                          width={50}
                          formatValues={() =>
                            `${((50 / 100) * 100).toFixed(2)}%`
                          }
                          colors={["#E2A400"]}
                          strokeColor="#E2A400"
                          colorFunction={(colors, index) =>
                            colors[index % colors.length]
                          }
                          emptyColor={"#FECD2C"}
                        />
                        <p className={classes.circleChartLabel}>
                          Gift{"\n"}Cards
                        </p>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card className={classes.cardTable}>
              <GridContainer>
                {data.length > 0 || copyArr.length > 0 ? (
                  <>
                    <GridItem
                      xs={4}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="h2"
                        className={classes.dashboardTitle}
                      >
                        User Table
                      </Typography>

                      {userTypeArrFilter.length > 0 ? (
                        <Typography
                          className={classes.dashboardTitle}
                          style={{ marginLeft: "10px" }}
                        >
                          {userTypeArrFilter.length < 4 && data.length > 0
                            ? " " + data.length + " " + userTypeArrFilter
                            : null}
                        </Typography>
                      ) : null}
                    </GridItem>

                    <GridItem xs={8}>
                      <DateRange
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        getIsoAdminReport={getIsoAdminReport}
                        csvData={isoAdminReportData}
                        csvLinkRef={csvLinkRef}
                      />
                    </GridItem>

                    <GridItem xs={12}>
                      <Filter
                        statusDropdown={statusDropdown}
                        setStatusDropdown={setStatusDropdown}
                        ascDropdown={ascDropdown}
                        setAscDropdown={setAscDropdown}
                        searchString={searchString}
                        setSearchString={setSearchString}
                        statusArr={statusArr}
                        ascArr={ascArr}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        entriesArr={entriesArr}
                        handleSearch={handleSearch}
                        csvData={data}
                        header={headersISO}
                        setEndIndex={setEndIndex}
                        handleFilter={handleFilter}
                        emptyData={emptyData}
                        resetEmptyData={resetEmptyData}
                        userTypeArrFilter={userTypeArrFilter}
                        handleUserTypeFilter={handleUserTypeFilter}
                        userTypeArr={userTypeArr}
                      />
                    </GridItem>
                  </>
                ) : null}

                <GridItem xs={12}>
                  <Table
                    copyArr={copyArr}
                    data={data}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    index={index}
                    setIndex={setIndex}
                    endIndex={endIndex}
                    setEndIndex={setEndIndex}
                    itemsPerPage={itemsPerPage}
                    handlePageClick={handlePageClick}
                    history={history}
                  />
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userCompany: state.auth.user.companyName,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(Dashboard)
);
