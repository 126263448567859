const reportsStyle = (theme) => ({
    title: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "30px",
        color: "#1A405F"
    },
    titleMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "30px",
        color: "#1A405F",
        padding: "0px !important"
    },
    toggleDiv: {
        display: "flex",
        width: "200px",
        border: "2px solid #1A405F",
        // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        borderRadius: "5px",
        alignItems: 'center',
        justifyContent: 'center',
        height: "42px"
    },
    toggleDivMobile: {
        display: "flex",
        width: "100%",
        border: "2px solid #1A405F",
        // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        borderRadius: "5px",
        alignItems: 'center',
        justifyContent: 'center',
        height: "42px"
    },
    toggleSelection: {
        height: "33px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "15px",
        textAlign: "center",
        textTransform: "capitalize",
        color: "#1A405F",
        padding: "10px",
        "&:hover": {
            cursor: "pointer",
        },
        textShadow: "none",
        boxShadow: "none"
    },
    selectMenuSortReportsPage: {
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        color: "#1A405F",
        background: "#FFFFFF",
        border: "2px solid #1A405F",
        borderRadius: "7px",
        width: "200px",
        height: "33px",
        textAlign: "center",
        margin: "0px 0px 0px 20px",
    },
    input: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        '&::placeholder': {
            opacity: 1,
            fontFamily: "Josefin Sans",
            fontWeight: 400,
            color: "#1A405F",
        },
    },
    filterDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "17px",
        textTransform: "capitalize",
        color: "#004253",
        lineHeight: "17px",
        marginTop: "10px",
    },
    csvButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        background: "#1A405F",
        color: "#FFFFFF",
        textTransform: "capitalize",
        borderRadius: "7px",
        "&:hover": {
            cursor: "pointer",
            background: "#1A405F",
            color: "#FFFFFF",
        },
        height: "42px",
        marginTop: "-5px"
    },
    csvButtonMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        background: "#1A405F",
        color: "#FFFFFF",
        textTransform: "capitalize",
        borderRadius: "5px",
        "&:hover": {
            cursor: "pointer",
            background: "#1A405F",
            color: "#FFFFFF",
        },
        height: "42px",
        marginTop: "0px",
        width: "100%",
    },
    date: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "20px",
        align: "center",
        color: "#1A405F",
        marginTop: "15px"
    },
    dateDeposit: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "20px",
        align: "center",
        color: "#1A405F",
        marginTop: "10px"
    },
    arrows: {
        transform: "scale(0.8)",
        background: "#56CA93",
        "&:hover": {
            cursor: "pointer",
            background: "#56CA93",
            color: "#FFFFFF",
        },
    },
    reportsTable: {
        width: "100% !important",
        borderCollapse: "collapse",
        padding: "0px 30px 10px 20px",
    },
    tableRow: {
        height: "39px",
        background: "#FFFFFF !important",
    },
    tableRowOdd: {
        height: "39px",
        background: "#E8EBEF !important",
    },
    tableHeaders: {
        border: "0.5px solid #1A405F",
        background: "#1A405F",
        color: "#FFFFFF",
        fontFamily: "Josefin Sans",
        fontSize: "17px",
        padding: "10px",
        textTransform: "uppercase",
    },
    tableCell: {
        textAlign: "center",
        border: "0.5px solid #1A405F !important",
        padding: "10px 20px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        color: "#1A405F",

    },
    indexes: {
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        color: "#1A405F"
    },
    modalFilterDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "17px",
        textTransform: "capitalize",
        color: "#004253",
        lineHeight: "17px",
        marginTop: "10px",
    },
    datetime: {
        width: "200px",
        height: "30px !important",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        textTransform: "capitalize",
        borderRadius: "7px",
        background: "white",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#56CA93',
            },
            '&:hover fieldset': {
                borderColor: '#56CA93',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#56CA93',
            },
        }
    },
    adournedEnd: {
        paddingRight: "0px !important",
        margin: "0px !important",
        maxHeight: "none !important",
        height: "30px"
    },
    datetimeButton: {
        background: "#56CA93",
        display: "block",
        margin: "0px !important",
        padding: "7px 11px !important",
        height: "30px",
        color: "white",
        width: "40px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 500,
        "&:hover": {
            background: "#56CA93",
        },
    },
    changeDateModal: {
        padding: "10px",
        "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                overflow: "visible !important",
                height: "20%",
                width: "31%",
                maxWidth: "800px",  // Set your width here
            },
        },
    },
    card: {
        height: "150px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        textTransform: "capitalize",
        padding: "10px 15px",
        // width: "100%",
        // whiteSpace: "noWrap",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F"
    },
    cardInfoBoldTop: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "30px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "30px",
        marginTop: "25px"
    },
    cardInfoBoldTopMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "24px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "30px",
        marginTop: "25px"
    },
    cardInfoBold: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "30px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "30px",
        marginTop: "18px"
    },
    cardInfoBoldMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "24px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "30px",
        marginTop: "18px"
    },
    cardInfoTop: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "19px",
        marginTop: "15px"
    },
    cardInfo: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "19px",
        marginTop: "30px"
    },
    cardInfoMobileLineheight: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "22px",
        marginTop: "30px"
    },
    cardInfoMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "22px",
        marginTop: "30px"
    },
    columnGrid: {
        display: "flex",
        flexDirection: "column",
    },
    spanHover: {
        "&:hover": {
            cursor: "pointer ",
        },
    },
    searchButton: {
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "7px",
        background: "#1A405F",
        // display: "block",
        margin: "-4px -1px 0px !important",
        padding: "8px 14px !important",
        height: "33px",
        width: "120px",
        color: "#FFFFFF",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        textTransform: "capitalize",
        "&:hover, &:active, &:focus": {
            background: "#1A405F",
        },
    },
    searchBar: {
        width: "80%",
        background: "white",
        height: "33px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        border: "2px solid #1A405F",
        paddingLeft: "10px",
        borderRadius: "7px",
        color: "#1A405F"
    },
    adournedEnd: {
        paddingRight: "0px !important",
        margin: "0px !important",
        maxHeight: "none !important",
        height: "33px"
    },
    color: "#1A405F",
    fontWeight: 400,
    fontFamily: "Josefin Sans",
    '&::placeholder': {
        opacity: 1,
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        color: "#1A405F",
    },
    selectMenuItem: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        color: "#1A405F",
        fontSize: "16px",
    },
    marginTopGrid: {
        marginTop: "20px"
    },
    noPaddingGrid: {
        marginTop: "20px",
        padding: "0px !important"
    },
    backButton:{
        width: "200px",
        borderRadius: "7px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19px",
        lineHeight: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        background: "#56CA93",
        height: "42px",
        "&:hover, &:focus": {
            backgroundColor: "#56CA93",
        },
        marginLeft: "15px",
        padding: "15px 8px",
        marginTop: "0px",
        [theme.breakpoints.down('1000')]: {
            marginLeft:"0px",
            marginTop:"15px",
            width:"100%",
        },
    },
    emptyMerch: {
        objectFit: "contain",
        marginTop: "10px",
        marginLeft: "10px"
      },
      invMerchHeader: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "22px",
        color: "#1A405F"
      },
      invMerchDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "18px",
        color: "#1A405F",
        marginTop: "20px"
      },
})
export default reportsStyle;