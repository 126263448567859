const initState = {
  cart: [],
};

export default function reduce(state = initState, action) {
  switch (action.type) {
    case "REMOVE_ALL":
      let currCart = [...state.cart];
      currCart.splice(0, (action.arr).length)
      return {
        ...state,
        cart: currCart
      };
    case "REMOVE_PRODUCT":
      const newCart = [...state.cart];
      newCart.splice(action.index, 1)
      return {
        ...state,
        cart: newCart
      };
    case "ADD_PRODUCT":
      const newState = [...state.cart];
      const newObj = {
        ...action.product,
      }
      newState.push(newObj);
      return {
        ...state,
        cart: newState,
      };
    default:
      return state;
  }
}
