const ordersStyle = (theme) => ({
    ordersTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "30px",
        color: "#1A405F"
    },
    ordersTitleMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "30px",
        color: "#1A405F"
    },
    addToCartButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "19px",
        background: "#1A405F",
        height: "36px",
        width: "136px",
        margin: "10px 10px 0px 0px",
        "&:hover, &:focus": {
            backgroundColor: "#1A405F",
        },
        borderRadius: "5px",
    },
    cardListStyling: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        width: "319px"
    },
    rewardsCardListStyling: {
        height: "335px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        justifyContent: "center",
        width: "420px",
        [theme.breakpoints.down("lg")]: {
            width: "400px"
        },
    },
    rewardsCardListStyling2: {
        height: "280px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        width: "420px",
        [theme.breakpoints.down("lg")]: {
            width: "380px"
        },
    },
    cardListStyling2: {
        height: "335px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        justifyContent: "center",
        width: "420px",
        [theme.breakpoints.down("lg")]: {
            width: "400px"
        },
    },
    cardListImg: {
        width: "180px",
        height: "150px",
        objectFit: "contain"
    },
    cardListTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "20px",
        textTransform: "uppercase",
        marginTop: "10px",
        paddingBottom: "10px"
    },
    cardListTitleNoOverflow: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "20px",
        textTransform: "uppercase",
        marginTop: "10px",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    cardListDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "capitalize",
        marginTop: "10px",
        paddingLeft: "10px"
    },
    cardListPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "17px",
        marginTop: "10px",
        paddingLeft: "10px"
    },
    toggleDiv: {
        display: "flex",
        width: "100%",
        border: "2px solid #1A405F",
        // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        borderRadius: "5px",
        [theme.breakpoints.down("1000")]: {
            display:"block"
        },
    },
    toggleSelection: {
        height: "40px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "15px",
        textAlign: "center",
        textTransform: "capitalize",
        color: "#1A405F",
        padding: "10px",
        "&:hover": {
            cursor: "pointer",
        },
        textTransform: "uppercase",
        textShadow: "none"
    },
    selectMenuSortOrdersPage: {
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        color: "#1A405F",
        background: "#FFFFFF",
        border: "2px solid #56CA93",
        borderRadius: "7px",
        width: "200px",
        height: "40px",
        textAlign: "center",
        margin: "0px 0px 0px 20px",
    },
    selectMenuSortOrdersPageMobile: {
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        color: "#1A405F",
        background: "#FFFFFF",
        border: "2px solid #56CA93",
        borderRadius: "7px",
        width: "100%",
        height: "40px",
        textAlign: "center",
        margin: "15px 0px 0px 0px",
    },
    datetime: {
        marginLeft: "20px",
        width: "200px",
        height: "40px !important",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        textTransform: "capitalize",
        borderRadius: "7px",
        background: "white",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#56CA93',
            },
            '&:hover fieldset': {
                borderColor: '#56CA93',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#56CA93',
            },
        }
    },
    datetimeMobile: {
        marginTop: "15px",
        width: "100%",
        height: "40px !important",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        textTransform: "capitalize",
        borderRadius: "7px",
        background: "white",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#56CA93',
            },
            '&:hover fieldset': {
                borderColor: '#56CA93',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#56CA93',
            },
        }
    },
    adournedEnd: {
        paddingRight: "0px !important",
        margin: "0px !important",
        maxHeight: "none !important",
        height: "40px"
    },
    datetimeButton: {
        background: "#56CA93",
        display: "block",
        margin: "0px !important",
        padding: "7px 11px !important",
        height: "40px",
        color: "white",
        width: "40px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 500,
        "&:hover": {
            background: "#56CA93",
        },
    },
    input: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        '&::placeholder': {
            opacity: 1,
            fontFamily: "Josefin Sans",
            fontWeight: 400,
            color: "#1A405F",
        },
    },
    divFilter: {
        marginLeft: "10px",
        marginTop: "10px",
        [theme.breakpoints.down("lg")]: {
            marginLeft: "0px"
        },
    },
    cardForFilterList: {
        padding: "10px 20px",
        border: "0.5px solid #56CA93",
        [theme.breakpoints.down("1000")]: {
            marginTop: "20px"
        },
    },
    indexes: {
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        color: "#1A405F"
    },
    selectMenuItem: {
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        color: "#1A405F"
    },
    selectText: {
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        color: "#1A405F"
    },
    noOrdersCard: {
        border: "0.5px solid #56CA93",
        alignItems: "center",
        padding: "5% 0%",
    },
    noOrdersTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "21px",
        lineHeight: "21px",
        textAlign: "center",
        color: "#1A405F",
        marginTop: "30px",
        // [theme.breakpoints.down("sm")]: {
        //     fontSize: "24px",
        // },
    },
    noOrdersNotBold: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "21px",
        textAlign: "center",
        color: "#1A405F",
        marginTop: "20px",
        padding:"0% 10%"
    },
    noOrdersButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        lineHeight: "17px",
        color: "#FFFFFF",
        background: "#56CA93",
        textTransform: "capitalize",
        height: "42px",
        padding: "15px",
        borderRadius: "7px",
        marginTop: "20px",
        "&:hover": {
            background: "#56CA93",
        },
    },
    iconButton: {
        "&:hover": {
            background: "transparent",
        },
        color: "#56CA93" 
    },
    divHover: {
        textAlign: "center",
        borderBottom: "1px solid #56CA93",
        "&:hover ": {
            cursor: "pointer"
        }
    },
})
export default ordersStyle;