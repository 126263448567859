const settingsPageStyle = (theme) =>  ({
    cardHeader: {
        letterSpacing: '2px !important'
    },
    cardLink: {
        display: "flex",
        padding: "10px",
        alignItems: "center",
    },
    linkName: {
        margin: "0 0 0 10px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    logOutButton: {
        width: "100%",
        backgroundColor: "#f3684e",
        borderRadius: "5px",
        height: "50px"
    },
    mainContainer: {
        // ...container,
        zIndex: "4",
        display: "flex",
        width: "99%",
        flexDirection: "column",
      },
      subTitle: {
          fontSize: "19px"
      }
})

export default settingsPageStyle;