import {
  container,
} from "assets/jss/material-dashboard-pro-react.js";

const merchantSignUpStyle = (theme) => ({
  // container: {
  //     ...container,
  //     // position: "relative",
  //     zIndex: "3",
  // },
  mainContainer: {
    width: "52%",
    maxWidth: "40rem",
    minHeight: "auto",
    maxHeight: "32rem",
    background: "rgba(249, 249, 249, 1)",
    position: "absolute",
    right: "3%",
    bottom: "30%",
    borderRadius: "10px",
    padding: "10px",
    [theme.breakpoints.down('md')]: {
      bottom: "10%",
    },
    [theme.breakpoints.up('lg')]: {
      bottom: "25%"
    },
    [theme.breakpoints.up('xl')]: {
      bottom: "20%"
    }
  },
  isoMainContainer: {
    width: "52%",
    minHeight: "auto",
    maxHeight: "32rem",
    background: "rgba(249, 249, 249, 1)",
    borderRadius: "10px",
    padding: "10px",
  },
  mobileMainContainer: {
    position: "relative",
    width: "85%",
    height: "100%",
    background: "rgba(249, 249, 249, 1) !important",
    margin: "60px auto 0px auto",
    padding: "10px",
    top: "225px",
    [theme.breakpoints.down('380')]: {
      top: "108px",

    }
  },
  
  mobileIsoMainContainer: {
    width: "85%",
    height: "100%",
    background: "rgba(249, 249, 249, 1) !important",
    margin: "0px auto 0px auto",
    padding: "10px",
    paddingBottom: "30px"
  },
  mainContainer2: {
    padding: "10px",
    width: "100%",
    maxWidth: "40rem",
    height: "58%",
    maxHeight: "32rem",
    background: "rgba(249, 249, 249, 1)",
    // position: "absolute",
    // right: "8%",
    // bottom: "15%",
    borderRadius: "10px",
    // border: "3px solid rgba(15, 55, 87, 0.75)",
    [theme.breakpoints.down('sm')]: {
      right: "6px",
      width: "380px",
      bottom: "135px",
    },
    backgroundColor: "#1A405F"
  },
  cardHeaderTitle: {
    fontSize: "25px",
    fontWeight: 700,
    fontFamily: "Josefin Sans",
    marginBottom: "-25px",
    color: "#004253"
  },
  cardHeaderTitle2: {
    fontSize: "19px",
    fontWeight: 500,
    fontFamily: "Josefin Sans",
    color: "#FFFFFF"
  },
  cardHeaderTitle2Mobile: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Josefin Sans",
    color: "#FFFFFF",
    textAlign: "center"
  },
  textFieldLabel: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Josefin Sans",
    lineHeight: "16px",
    color: "#004253"
  },
  addIdButton: {
    background: "#06667C",
    color: "#FFFFFF",
    borderRadius: "8px",
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "capitalize",
    marginTop: "20px",
    height: "44px",
    "&:hover": {
      background: "#06667C",
      color: "#FFFFFF",
    },
    width: "220px",
    [theme.breakpoints.down('md')]: {
      width: "165px",
    },
  },
  addIdButtonMobile: {
    background: "#06667C",
    color: "#FFFFFF",
    borderRadius: "8px",
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "capitalize",
    marginTop: "15px",
    height: "44px",
    width: "150px",
    "&:hover": {
      background: "#06667C",
      color: "#FFFFFF",
    }
  },
  previewPageButtonMobile: {
    background: "#06667C",
    color: "#FFFFFF",
    borderRadius: "8px",
    fontFamily: "Josefin Sans",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "15px",
    textTransform: "capitalize",
    marginTop: "20px",
    height: "44px",
    "&:hover": {
      background: "#06667C",
      color: "#FFFFFF",
    }
  },
  submitButton: {
    background: "#56CA93",
    color: "#FFFFFF",
    borderRadius: "8px",
    fontFamily: "Josefin Sans",
    fontSize: "19px",
    fontWeight: 500,
    lineHeight: "19px",
    textTransform: "capitalize",
    marginTop: "20px",
    marginBottom: "10px",
    height: "50px",
    width: "174px",
    "&:hover": {
      background: "#56CA93",
      color: "#FFFFFF",
    },

  },
  textField: {
    width: "100%",
    background: "white",
    height: "35px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "1px solid #929292",
    paddingLeft: "10px",
    borderRadius: "4px",
    marginTop: "-10px",
    "& .Mui-error": {
      fontFamily: "Josefin Sans",
      fontWeight: 600,
      // marginLeft: "-11px"
    },
  },
  textField2: {
    width: "100%",
    background: "white",
    height: "35px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "1px solid #929292",
    paddingLeft: "10px",
    borderRadius: "7px",
    "& .Mui-error": {
      fontFamily: "Josefin Sans",
      fontWeight: 600,
    },
  },
  textFieldMobile: {
    width: "100%",
    background: "white",
    height: "35px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "1px solid #929292",
    paddingLeft: "10px",
    borderRadius: "4px",
    marginTop: "0px",
    "& .Mui-error": {
      fontFamily: "Josefin Sans",
      fontWeight: 600,
      // marginLeft: "-11px"
    },
  },
  input: {
    color: "#1A405F",
    fontWeight: 400,
    fontFamily: "Josefin Sans",
    '&::placeholder': {
      opacity: 1,
      fontFamily: "Josefin Sans",
      fontWeight: 400,
      color: "#1A405F",
    },
  },
  resendEmail: {
    fontFamily: "Josefin Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#1A405F"
  },
  underlineEmail: {
    fontFamily: "Josefin Sans",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  checkBoxValidation: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "0.75rem",
    color: "#f44336"
  },
  bannerPts: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "20px",
    textTransform: "capitalize",
    color: "#56CA93",
    lineHeight: "28px",
  },
  bannerPtsMobile: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "capitalize",
    color: "#56CA93",
    lineHeight: "20px",
    textAlign: "center"
  },
  bannerDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "20px",
    color: "#FFFFFF",
    lineHeight: "41px",
    margin: "5px 0px 0px 10px",
    whiteSpace: "noWrap"
  },
  bannerDescTop: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    color: "#FFFFFF",
    lineHeight: "19px",
    margin: "5px 0px 0px 0px",
    whiteSpace: "noWrap"
  },
  bannerDescTopMobile: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    color: "#FFFFFF",
    lineHeight: "19px",
    margin: "5px 0px 0px 0px",
  },
  gridBanner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  calculatorCard: {
    display: "flex",
    justifyContent: "center",
    padding: "120px",
    position: "relative",
    margin: "0",
    border: "0",
    zIndex: 1,
    backgroundColor: "#1A405F",
    height: "100%",
  },
  calculatorCardMobile: {
    display: "flex",
    justifyContent: "center",
    padding: "60px",
    position: "relative",
    margin: "0",
    border: "0",
    zIndex: 1,
    backgroundColor: "#1A405F",
    height: "100%",
    width: "85%"
  },
  calculatorTitle: {
    fontSize: "30px",
    fontWeight: 600,
    fontFamily: "Josefin Sans",
    textTransform: "uppercase",
    textAlign: "center",
    color: "#FFFFFF",
    padding: "25px 0px"
  },
  calculatorTitleMobile: {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Josefin Sans",
    textTransform: "uppercase",
    textAlign: "center",
    color: "#FFFFFF",
    padding: "25px 0px"
  },
  calculatorSubtitle: {
    fontSize: "19px",
    fontWeight: 500,
    fontFamily: "Josefin Sans",
    textAlign: "center",
    color: "#FFFFFF",
    paddingBottom: "50px"
  },
  calculatorSubtitleMobile: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Josefin Sans",
    textAlign: "center",
    color: "#FFFFFF",
    paddingBottom: "50px"
  },
  gridItemMobileMarginTop: {
    marginTop: "15px"
  },
  captchaDiv: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('425')]: {
      transform: "scale(0.97)",
    },
    [theme.breakpoints.down('380')]: {
      transform: "scale(0.85)",
    },
  },
  linkContainerMobile: {
    width: "85%",
    maxWidth: "40rem",
    minHeight: "auto",
    maxHeight: "32rem",
    position: "absolute",
    right: "8%",
    bottom: "4%",
    paddingBottom: "15px",
    paddingTop: "15px",
    [theme.breakpoints.down('392')]: {
      bottom: "0%"
    },
  },
  termsStyle: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "white",
    "&:hover": {
      cursor: "pointer",
    },
    textDecoration: "underline"
  },
  isoTermsStyle: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "#1A405F",
    "&:hover": {
      cursor: "pointer",
    },
    textDecoration: "underline"
  },
  logoImg: {
    width: "90%",
    height: "200px",
    objectFit: "contain",
    paddingTop:"15px"
  },
  logoImgMerchant: {
    width: "100%",
    height: "320px",
    objectFit: "contain",
  },
  gridItemFlexCenter: {
    display: "flex",
    justifyContent: "center"
  },
  negMarginTop:{
    marginTop:"-60px"
  },
  gridMarginTop: {
    marginTop:"30px"
  }
})

export default merchantSignUpStyle;
