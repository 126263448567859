import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/activityStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from "assets/img/tuzo/iconNoText.png"
// core components
import { Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem';

export function Earnings(props) {
    const { classes, history, userPts, lastMonthPts, lastYrPts } = props;

    return (
        <Card className={classes.earningsCard}>
            <GridContainer>
                <GridItem xs={12}>
                    <Typography className={classes.cardDescMobile}>
                        Your Earnings
                    </Typography>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                    <img src={Icon}
                        style={{
                            width: "75px",
                            height: "75px"
                        }} />
                </GridItem>

                <GridItem xs={4} className={classes.noPaddingGridItem}>
                    <Typography
                        className={classes.cardDescBold}
                        style={{ fontSize: "28px" }}
                    >
                        {userPts ? userPts.toLocaleString() : "0"}
                    </Typography>
                </GridItem>

                <GridItem xs={8} style={{ marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Typography
                        className={classes.cardDesc}
                        style={{ fontWeight: 400, fontSize: "19px", lineHeight: "19px" }}
                    >
                        Points
                    </Typography>
                </GridItem>

                <GridItem xs={4} >
                    <div className={classes.divColumnFlex}>
                        <Typography className={classes.cardDescSmall} style={{ whiteSpace: "nowrap" }}>
                            Last Month
                        </Typography>

                        <Typography className={classes.cardDescSmall}>
                            Last Year
                        </Typography>
                    </div>
                </GridItem>

                <GridItem xs={8}
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                    <div className={classes.divColumnFlex} >
                        <Typography className={classes.cardDescSmall}>
                            {lastMonthPts.toLocaleString()} points
                        </Typography>

                        <Typography className={classes.cardDescSmall}>
                            {lastYrPts.toLocaleString()} points
                        </Typography>
                    </div>
                </GridItem>
            </GridContainer>
        </Card>
    )
}

function mapStateToProps(state) {
    return {
        userPts: state.auth.user?.approvedPts
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Earnings));