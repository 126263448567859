import NewProfile from "views/Pages/isoAdminNewProfile/NewProfile";
import Dashboard from "views/Pages/isoadmindashboard/Dashboard";
import MerchantProfile from "views/Pages/isoadminmerchantprofile/MerchantProfile";
import Profile from "views/Pages/isoadminprofile/Profile";
import Rewards from "views/Pages/isoadminreward/Rewards";
import RewardsDetail from "views/Pages/rewardsdetail/RewardsDetail";
import Wishlist from "views/Pages/wishlist/Wishlist";
import MerchantWishlistfrom from "views/Pages/merchantwishlist/Wishlist";
import EditMerchantProfile from "views/Pages/isoadminmerchantprofile/EditMerchantProfile";
import Calculator from "views/Pages/isoadmincalculator/Calculator";
import SalesByMerchant from "views/Pages/isoadminreports/salesByMerchant/SalesByMerchant";
import IsoAdminBatchDetails from "views/Pages/isoadminreports/batchdetails/IsoAdminBatchDetails";
import TempMerchantProfile from "views/Pages/isoadmintempprofile/TempMerchantProfile";
import EditTempMerchantProfile from "views/Pages/isoadmintempprofile/EditTempMerchantProfile";


var isoAdminRoutes = [
  {
    path: '/:iso/reports/batch/:id/:id',
    name: 'Batch Details',
    component: IsoAdminBatchDetails,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/reports/sales-by-merchant',
    name: 'Reports',
    component: SalesByMerchant,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/merchants',
    name: 'Merchants',
    component: Dashboard,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/rewards',
    name: 'Merchants',
    component: Rewards,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/reward-detail/:id',
    name: 'Rewards Detail',
    component: RewardsDetail,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/wish-list',
    name: 'Wish List',
    component: Wishlist,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/merchant-wish-list/:id',
    name: 'Wish List',
    component: MerchantWishlistfrom,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/user-profile/:id',
    name: 'Merchant Profile',
    component: MerchantProfile,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/temp-user-profile/:id',
    name: 'Merchant Profile',
    component: TempMerchantProfile,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/edit-temp-user-profile/:id',
    name: 'Merchant Profile',
    component: EditTempMerchantProfile,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/edit-user-profile/:id',
    name: 'Merchant Profile',
    component: EditMerchantProfile,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/profile',
    name: 'Profile',
    component: Profile,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/add-merchant',
    name: 'Add Merchant',
    component: NewProfile,
    layout: '/iso-admin',
  },
  {
    path: '/:iso/calculator',
    name: 'Add Merchant',
    component: Calculator,
    layout: '/iso-admin',
  },
]

export default isoAdminRoutes;