/*eslint-disable*/
import React, { useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment"
import Gateway from "views/Pages/isoadminmerchantprofile/components/Gateway.js"
// mui core
import { Button, Card, Typography, TextField, MenuItem, Select, Switch } from "@material-ui/core";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";



//styling
import withStyles from '@material-ui/core/styles/withStyles';
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import WishListTable from './WishListTable';


export function EditMerchantInfo(props) {
    const {
        classes,
        history,
        formatPhoneNumber,
        merchantData,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        handlePageClick,
        rewardsData,
        userCompany,
        merchantId,
        edit,
        setEdit,
        username,
        setUsername,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        companyName,
        setCompanyName,
        contactFirstName,
        setContactFirstName,
        contactLastName,
        setContactLastName,
        cellPhone,
        setCellPhone,
        secondaryPhone,
        setSecondaryPhone,
        shippingAddress,
        setShippingAddress,
        userBasisPts,
        setUserBasisPts,
        signUpBonus,
        setSignUpBonus,
        autoApprove,
        setAutoApprove,
        interval,
        setInterval,
        intervalArr,
        userLock,
        gateway,
        setGateway,
        apiInfo,
        setApiInfo
    } = props;

    return (
        <div>
            <GridContainer>
                <GridItem xs={4} className={classes.tighterGrid}>
                    <Card className={classes.profileCardStyling2} style={{ overflow: "visible" }}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Personal Details
                                </Typography>
                            </GridItem>

                            <GridItem xs={4} style={{ marginTop: "20px" }}>
                                {merchantData.profilePic ?
                                    <div>
                                        <img
                                            className={classes.img}
                                            src={merchantData.profilePic}
                                        />
                                    </div>
                                    :
                                    <div className={classes.noProfilePic}>
                                        {merchantData.firstName[0] + merchantData.lastName[0]}
                                    </div>
                                }
                            </GridItem>

                            <GridItem xs={8}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Username
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                         {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {username ? username : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            First Name
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {merchantData.firstName ? merchantData.firstName : "N/A"}
                                                </Typography>
                                            </>
                                        }

                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Last Name
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {merchantData.lastName ? merchantData.lastName : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Email (required)
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                    {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeightEmail}>
                                                    {email ? email : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Company
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={companyName}
                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {merchantData.companyName ? merchantData.companyName : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>

                <GridItem xs={4}>
                    <Card className={classes.profileCardStyling2}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Contact Information
                                </Typography>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Contact First Name
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={contactFirstName}
                                                    onChange={(e) => setContactFirstName(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {merchantData.contactFirstName ? merchantData.contactFirstName : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Contact Last Name
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={contactLastName}
                                                    onChange={(e) => setContactLastName(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {merchantData.contactLastName ? merchantData.contactLastName : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Phone Number
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={cellPhone}
                                                    onChange={(e) => setCellPhone(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {merchantData.cellPhone ? formatPhoneNumber(merchantData.cellPhone) : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Secondary Phone
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={secondaryPhone}
                                                    onChange={(e) => setSecondaryPhone(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {merchantData.secondaryPhone ? formatPhoneNumber(merchantData.secondaryPhone) : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Shipping Address
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={shippingAddress}
                                                    onChange={(e) => setShippingAddress(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {merchantData.shippingAddress ? merchantData.shippingAddress : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>

                <GridItem xs={4}>
                    <Card className={classes.profileCardStyling2}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Platform Metrics
                                </Typography>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Creation Date
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.timestamp ? moment.unix(merchantData.timestamp.seconds).format("MM/DD/YYYY") : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Last Logged In
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.lastLoggedIn ? moment.unix(merchantData.lastLoggedIn.seconds).format("MM/DD/YYYY hh:mm a") : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Login Count
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.loginCount ? merchantData.loginCount : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Eligible Rewards
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {rewardsData.length}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Rewards Redeemed
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.rewardsRedeemed ? merchantData.rewardsRedeemed : "N/A"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>

                <GridItem xs={4}>
                  <Gateway
                    gateway={gateway}
                    setGateway={setGateway}
                    apiInfo={apiInfo}
                    setApiInfo={setApiInfo}
                    userData={merchantData}
                    globalEdit={edit}
                  />  
                </GridItem>

                <GridItem xs={8} style={{ marginTop: "20px" }}>
                    <Card className={classes.profileCardStyling2}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Typography variant="h2" className={classes.cardHeader}>
                                    Contract Details123
                                </Typography>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Basis Points Set Aside
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={userBasisPts}
                                                    onChange={(e) => setUserBasisPts(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {userBasisPts ? userBasisPts : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Sign-Up Bonus
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        {edit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={signUpBonus}
                                                    onChange={(e) => setSignUpBonus(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <>
                                                <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                    {signUpBonus ? signUpBonus : "N/A"}
                                                </Typography>
                                            </>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} >
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Points Release Schedule
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        {edit && !userLock ?
                                            <>
                                                <Select
                                                    className={classes.selectMenuSortProfile}
                                                    value={interval}
                                                    disableUnderline
                                                    classes={{
                                                        root: classes.selectText,
                                                    }}
                                                    onChange={(e) => setInterval(e.target.value)}

                                                >
                                                    {intervalArr.map((o, i) => {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={o}
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                }}
                                                            >
                                                                {o} Days
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </>
                                            :
                                            <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                {merchantData.ptsReleaseDay ? merchantData.ptsReleaseDay + " Days" : "N/A"}
                                            </Typography>
                                        }

                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={5}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            Auto Approve Points
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={7}>
                                        <Typography variant="h2" className={classes.cardDescLessWeight}>
                                            {merchantData.autoApprove ? "Enabled" : "Disabled"}
                                        </Typography>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userCompany: state.auth.user.companyName,
        userLock: state.auth.user.lockSchedule,
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(EditMerchantInfo));