import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import ReactPaginate from "react-paginate";
import "./pagination.css";

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/isoMerchantProfileTableStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// icons
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem';
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

const headers = [
    "Name",
    "Cost",
    ""
]

export function WishListTable(props) {
    const {
        classes,
        history,
        wishListData,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        handlePageClick,
        docId,
        userCompany
    } = props;

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    const numberFormatterDecimals = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });


    useEffect(() => {
        wishListData.slice(index, endIndex);
    }, [index]);

    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "30px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th className={classes.tableHeaders} key={index}>
                            {key}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        return wishListData
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <tr className={index % 2 === 0 ? classes.tableRow : classes.tableRowOdd} key={index}>
                        <td className={classes.tableCell}>{d.rewardName}</td>
                        <td className={classes.tableCell}>{numberFormatter.format(d.salePricePoints)} Points / ${numberFormatterDecimals.format(d.salePrice)}</td>
                        <td className={classes.tableCell}>
                            <Tooltip
                                id="tooltip-top"
                                title="View"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <Button
                                    style={{ background: "#FECD2C" }}
                                    className={classes.actionButton}
                                    onClick={() => history.push(
                                        `/iso-admin/${userCompany}/reward-detail/${d.rewardId}`,
                                        {
                                            rewardData: d
                                        }
                                    )}                                >
                                    <ViewIcon />
                                </Button>
                            </Tooltip>
                        </td>

                    </tr>
                );
            });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <table className={classes.rewardsTable} style={{ marginTop: "10px" }}>
                    <tbody>
                        {renderTableHeader()}
                        {renderTableData()}
                    </tbody>
                </table>
                <GridContainer style={{ marginBottom: "-15px" }}>
                    <GridItem xs={10} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                        <h5
                            className={classes.indexes}
                        >
                            {index + 1} - {endIndex} of{" "}
                            {Math.ceil(wishListData.length)}
                        </h5>
                    </GridItem>
                    <GridItem xs={2}>
                        <ReactPaginate
                            paginationSize={0}
                            onPageChange={handlePageClick}
                            pageCount={Math.ceil(wishListData.length / itemsPerPage)}
                            nextLabel={
                                <ArrowRight
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            previousLabel={
                                <ArrowLeft
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    )
}

function mapStateToProps(state) {
    return {
        userCompany: state.auth.user.companyName,
        userId: state.auth.user.uid
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(WishListTable));