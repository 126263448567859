import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import { connect } from "react-redux";
import compose from "recompose/compose";
import AuthActions from "store/actions/auth";
import styles from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.js";
import { changeValue } from "store/actions/dropdown";

const useStyles = makeStyles(styles);

function RewardNavLinkDropdown(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const handleClick = (event) => {
        setClickDropdown(!clickDropdown)
        if (anchorEl && anchorEl.contains(event.target)) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = (event) => {
        if (anchorEl.contains(event.target)) {
            return;
        }
        setClickDropdown(false)
        setAnchorEl(null);
    };
    const handleCloseMenu = (param) => {
        setAnchorEl(null);
        if (props && props.onClick) {
            props.onClick(param);
        }
    };

    const handleDropdown = (value) => {
        changeValue(value)
    }
    const {
        buttonText,
        buttonIcon,
        dropdownList,
        buttonProps,
        dropup,
        dropdownHeader,
        caret,
        hoverColor,
        dropPlacement,
        rtlActive,
        noLiPadding,
        innerDropDown,
        navDropdown,
        adminNavbar,
        history,
        clickDropdown,
        setClickDropdown,
        linkName,
        changeValue,
        dropdown
    } = props;

    const caretClasses = classNames({
        [classes.rewardsCaret]: true,
        [classes.caretDropup]: dropup && !anchorEl,
        [classes.caretActive]: Boolean(anchorEl) && !dropup,
        [classes.caretRTL]: rtlActive,
    });

    const caretClassesNotActive = classNames({
        [classes.rewardsCaret2]: true,
        [classes.caretDropup]: dropup && !anchorEl,
        [classes.caretActive]: Boolean(anchorEl) && !dropup,
        [classes.caretRTL]: rtlActive,
    });
    const dropdownItem = classNames({
        [classes.dropdownItem]: true,
        [classes[hoverColor + "Hover"]]: true,
        [classes.noLiPadding]: noLiPadding,
        [classes.dropdownItemRTL]: rtlActive,
    });
    const dropDownMenu = (
        <MenuList role="menu" className={classes.menuList}>
            {dropdownHeader !== undefined ? (
                <MenuItem
                    style={
                        adminNavbar
                            ? {
                                "&:hover": {
                                    cursor: "pointer",
                                    background: "#FFFFFF",
                                },
                            }
                            : {}
                    }
                    onClick={() => handleCloseMenu(dropdownHeader)}
                    className={classes.dropdownHeader}
                >
                    {dropdownHeader}
                </MenuItem>
            ) : null}
            {dropdownList.map((prop, key) => {
                if (prop.divider) {
                    return (
                        <Divider
                            key={key}
                            onClick={() => handleCloseMenu("divider")}
                            className={classes.dropdownDividerItem}
                        />
                    );
                } else if (
                    prop.props !== undefined &&
                    prop.props["data-ref"] === "multi"
                ) {
                    return (
                        <MenuItem
                            key={key}
                            style={
                                adminNavbar
                                    ? {
                                        "&:hover": {
                                            cursor: "pointer",
                                            background: "#FFFFFF",
                                        },
                                    }
                                    : {}
                            }
                            className={adminNavbar ? classes.menuItem : dropdownItem}
                        >
                            {prop}
                        </MenuItem>
                    );
                }
                return (
                    <MenuItem
                        key={key}
                        value={prop}
                        className={adminNavbar && prop === dropdown? classes.menuItemActive : classes.menuItem}
                        style={
                            adminNavbar
                                ? {
                                    "&:hover": {
                                        cursor: "pointer",
                                        background: "#FFFFFF",
                                    },
                                }
                                : {}
                        }
                        onClick={(e) => {
                            handleDropdown(prop)
                            handleCloseMenu(prop);
                        }}
                    >
                        {prop}
                    </MenuItem>
                );
            })}
        </MenuList>
    );
    return (
        <div
            style={adminNavbar ? { width: "1px" } : {}}
            className={innerDropDown ? classes.innerManager : classes.manager}
        >
            <div className={buttonText !== undefined ? "" : classes.target}>
                <Button
                    style={
                        adminNavbar
                            ? { color: "#323232", background: "transparent", boxShadow: "none", marginLeft: "-20px" }
                            : {}
                    }
                    aria-label="Notifications"
                    aria-owns={anchorEl ? "menu-list" : null}
                    aria-haspopup="true"
                    {...buttonProps}
                    onClick={handleClick}
                >
                    {buttonIcon !== undefined ? (
                        <props.buttonIcon className={classes.buttonIcon} />
                    ) : null}
                    {buttonText !== undefined ? buttonText : null}
                    {caret && linkName === "/admin/rewards" ? <b className={caretClasses} /> : <b className={caretClassesNotActive} />}
                </Button>
            </div>
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                transition
                disablePortal
                placement={dropPlacement}
                className={classNames({
                    [classes.popperClose]: !anchorEl,
                    [classes.popperResponsive]: true,
                    [classes.popperNav]: Boolean(anchorEl) && navDropdown,
                })}
            >
                {() => (
                    <Grow
                        in={Boolean(anchorEl)}
                        id="menu-list"
                        style={
                            dropup
                                ? { transformOrigin: "0 100% 0" }
                                : { transformOrigin: "0 0 0" }
                        }
                    >
                        <Paper className={adminNavbar ? classes.dropdownAdmin : classes.dropdown}>
                            {innerDropDown ? (
                                dropDownMenu
                            ) : (
                                <ClickAwayListener onClickAway={handleClose}>
                                    {dropDownMenu}
                                </ClickAwayListener>
                            )}
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

RewardNavLinkDropdown.defaultProps = {
    caret: true,
    dropup: false,
    hoverColor: "#FFC720",
};

RewardNavLinkDropdown.propTypes = {
    hoverColor: PropTypes.oneOf([
        "dark",
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose",
    ]),
    buttonText: PropTypes.node,
    buttonIcon: PropTypes.object,
    dropdownList: PropTypes.array,
    buttonProps: PropTypes.object,
    dropup: PropTypes.bool,
    dropdownHeader: PropTypes.node,
    rtlActive: PropTypes.bool,
    caret: PropTypes.bool,
    dropPlacement: PropTypes.oneOf([
        "bottom",
        "top",
        "right",
        "left",
        "bottom-start",
        "bottom-end",
        "top-start",
        "top-end",
        "right-start",
        "right-end",
        "left-start",
        "left-end",
    ]),
    noLiPadding: PropTypes.bool,
    innerDropDown: PropTypes.bool,
    navDropdown: PropTypes.bool,
    // This is a function that returns the clicked menu item
    onClick: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        dropdown: state.dropdown.dropdown
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (dropdown) => dispatch(changeValue(dropdown))
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(RewardNavLinkDropdown);
