import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from "assets/jss/material-dashboard-pro-react.js";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `100%`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    // marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "70px",
    }
  },
  container: { ...containerFluid },
  map: {
    marginTop: "70px"
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
  backButton: {
    color: 'black', 
    backgroundColor: '#69a6db',
    fontWeight: '700', 
    width: '155px',
    //maxWidth: '155px'
  },
  logout: {
    backgroundColor: 'white',
    border: '1px solid #48b4f7',
    color: '#48b4f7',
    fontWeight: 'normal',
    marginLeft: '23px',
    //maxWidth: '230px',
    width: '230px',
    textTransform: 'none',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%',
    [theme.breakpoints.down("700")]: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: '10px'
    },
  },
  headerButtons: {
    display: 'flex',
    justifyContent:'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    }
  }
});

export default appStyle;
