/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";


import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import BoundSearchControl from "../../../components/Explorer/BoundSearchControl";
// import BoundSearchDialog from "../../../components/Explorer/BoundSearchDialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import { connect } from "react-redux";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";


import GoogleLogo from 'assets/img/market-me/googleLogo.png'
import EmailLogo from 'assets/img/market-me/Login-Sign Up/email-logo-grey 1.png'
import FacebookLogo from 'assets/img/market-me/Login-Sign Up/Facebook.png'
import { Checkbox, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { RegisterPage } from "./merchantSignUp/RegisterPage";





export function ForgetPassword(props) {
    const { classes, authError, history } = props
    const [emailInput, setEmailInput] = useState('')
    const [passwordInput, setPasswordInput] = useState('')
    const [passwordNotice, setPasswordNotice] = useState(null)
    const [checked, setChecked] = useState(false)
    const [showPw, setShowPw] = useState(false)

    const handlePasswordReset = (e) => {
        e.preventDefault()
        if (!emailInput) {
            setPasswordNotice("Please add your email above")
        } else {
            props.passwordReset(emailInput)
            setPasswordNotice("Password reset link sent. Please check your email.")
        }
    }


    return (
        <div>
            <Hidden mdUp implementation='css'>
                <Card className={classes.mobileForgotPwMainContainer}>
                    <CardHeader className={classes.cardHeaderTitleLineHeight}>
                        Forgot Your Password? Enter Your Email Below.
                    </CardHeader>
                    <CardBody className={classes.cardBodyForgotPw}>
                        <CustomInput
                            needWhite
                            labelText="Email"
                            id="email"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: (e) => setEmailInput(e.target.value),
                                disableUnderline: true,
                                form: {
                                    autocomplete: 'off',
                                },
                                style: {
                                    border: "2px solid #929292",
                                    borderRadius: "5px",
                                    height: "50px",
                                }
                                // endAdornment: (
                                //   <InputAdornment position="end">
                                //     <Email />
                                //   </InputAdornment>
                                // )
                            }}
                            labelProps={{
                                style: {
                                    fontWeight: 600,
                                    size: "17px",
                                    color: "#004253",
                                    fontFamily: "Josefin Sans",
                                }
                            }}
                        />
                        <div className={classes.justifyContentCenter}>

                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button
                                className={classes.resetPwButton}
                                type="button"
                                onClick={handlePasswordReset}
                                style={{ width: "60%" }}
                            // color="danger"
                            // simple size="lg"
                            // block
                            >
                                Reset Password
                            </Button>

                            <Button
                                onClick={() => history.goBack()}
                                className={classes.goBackButtonMobile}
                                type="submit"
                                color="primary"
                                simple size="lg"
                                block>
                                Back
                            </Button>
                        </div>
                        {passwordNotice ? <p style={{ paddingTop: "5px" }} className={classes.textDanger}>{passwordNotice}</p> : null}
                    </CardBody>
                </Card>
                <div className={classes.linkContainerMobile}>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <span className={classes.termsStyle}
                            // onClick={handlePasswordReset}
                            onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

                        >
                            Terms of Use
                        </span>
                        <span className={classes.termsStyle}
                            // onClick={handlePasswordReset}
                            onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
                        >
                            Privacy Policy
                        </span>
                    </div>
                </div>
            </Hidden>


            <Hidden smDown implementation='css'>
                <Card className={classes.mainContainerForgotPw}>
                    <CardHeader className={classes.cardHeaderTitle}>
                        Forgot Your Password? Enter Your Email Below.
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                        <CustomInput
                            needWhite
                            labelText="Email"
                            id="email"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: (e) => setEmailInput(e.target.value),
                                disableUnderline: true,
                                form: {
                                    autocomplete: 'off',
                                },
                                style: {
                                    border: "2px solid #929292",
                                    borderRadius: "5px",
                                    height: "50px",
                                }
                                // endAdornment: (
                                //   <InputAdornment position="end">
                                //     <Email />
                                //   </InputAdornment>
                                // )
                            }}
                            labelProps={{
                                style: {
                                    fontWeight: 600,
                                    size: "17px",
                                    color: "#004253"
                                }
                            }}
                        />
                        <div className={classes.justifyContentCenter}>

                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button
                                className={classes.resetPwButton}
                                type="button"
                                onClick={handlePasswordReset}
                            // color="danger"
                            // simple size="lg"
                            // block
                            >
                                Reset Password
                            </Button>

                            <Button
                                onClick={() => history.goBack()}
                                className={classes.forgotPwGoBackButton}
                                style={{ backgroundColor: "#56CA93", color: "white" }}
                                type="submit"
                                color="primary"
                                simple size="lg"
                                block>
                                Go Back
                            </Button>
                        </div>
                        {passwordNotice ? <p style={{ paddingTop: "5px" }} className={classes.textDanger}>{passwordNotice}</p> : null}
                    </CardBody>
                </Card>
                <div className={classes.linkContainerMobile}>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <span className={classes.termsStyle}
                            // onClick={handlePasswordReset}
                            onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

                        >
                            Terms of Use
                        </span>
                        <span className={classes.termsStyle}
                            // onClick={handlePasswordReset}
                            onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
                        >
                            Privacy Policy
                        </span>
                    </div>
                </div>
            </Hidden>
        </div>
    );
}


function mapStateToProps(state) {
    return {
        authError: state.auth.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        startGoogleLogin: () => dispatch(AuthActions.startGoogleLogin()),
        emailSignIn: (credentials) => dispatch(handleError(chain(AuthActions.startEmailLogin(credentials), push('/admin/user-page')), AuthActions.setError)),
        passwordReset: (email) => dispatch(AuthActions.startPasswordReset(email)),
        startMicrosoftLogin: () => dispatch(AuthActions.startMicrosoftLogin()),
        startFacebookLogin: () => dispatch(AuthActions.startFacebookLogin)
    }
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(loginPageStyle)
)(ForgetPassword));
