import {
    successColor,
    tooltip,
    cardTitle,
    grayColor
} from "assets/jss/material-dashboard-pro-react.js";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";

const rewardsStyle = (theme) => ({
    ...hoverCardStyle,
    tooltip,
    saveButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        background: "#E2A400",
        color: "#FFFFFF",
        textTransform: "capitalize",
        borderRadius: "7px",
        "&:hover": {
            background: "#E2A400",
        },
        width: "35%",
        height: "42px",
        marginRight: "10px"
    },
    deleteDenomButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19px",
        lineHeight: "19px",
        color: "#FFFFFF",
        background: "#56CA93",
        textTransform: "capitalize",
        borderRadius: "7px",
        "&:hover": {
            background: "#56CA93",
        },
        height: "35px",
        width: "80%"
    },
    addRoomButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19px",
        lineHeight: "19px",
        color: "#FFFFFF",
        background: "#1A405F",
        textTransform: "capitalize",
        borderRadius: "7px",
        "&:hover": {
            background: "#06667C",
        },
        height: "35px",
        marginLeft: "20px"
    },
    icon: {
        marginRight: "5px",
        height: "22px",
        width: "22px"
    },
    selectStatus: {
        fontFamily: "Josefin Sans",
        fontSize: "16px",
        color: "#E2A400",
        borderRadius: "7px",
        lineHeight: "16px",
        width: "100%",
        height: "45px",
        textAlign: "center",
        fontWeight: "500",
    },
    textField: {
        width: "90%",
        background: "white",
        height: "35px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        border: "1px solid #1A405F",
        paddingLeft: "10px",
        borderRadius: "7px"
    },
    roomOptionBorder: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "15px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        align: "center",
        color: "#1A405F",
        border: "1px solid #1A405F",
        marginTop: "15px"
    },
    roomOptionHeader: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "capitalize",
        color: "#1A405F"
    },
    card: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "15px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F"
    },
    cardHeader: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#1A405F"
    },
    generalLabel: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "16px",
        marginTop: "35px",
        whiteSpace: "noWrap",
    },
    generalLabelNotes: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "16px",
        marginTop: "20px",
        whiteSpace: "noWrap",
    },
    generalTextField: {
        width: "90%",
        background: "white",
        height: "35px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        border: "1px solid #1A405F",
        paddingLeft: "10px",
        borderRadius: "7px",
        marginTop: "10px"
    },
    amenitiesTextField: {
        width: "52%",
        background: "white",
        height: "35px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        borderBottom: "1px solid #1A405F",
        marginLeft: "-13px"
    },
    roomLabel: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "16px",
        marginTop: "20px"
    },
    roomTextField: {
        width: "100%",
        background: "white",
        height: "35px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        border: "1px solid #1A405F",
        paddingLeft: "10px",
        borderRadius: "7px",
        marginTop: "15px"
    },
    giftCardLabel: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "16px",
        marginTop: "23px"
    },
    giftCardLabelNotes: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "16px",
        marginTop: "20px"
    },
    giftCardTextField: {
        width: "100%",
        background: "white",
        height: "35px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        border: "1px solid #1A405F",
        paddingLeft: "10px",
        borderRadius: "7px",
        marginTop: "15px"
    },
    ptsLabel: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "16px",
        marginTop: "22px",
        marginLeft: "10px"
    },
    selectMenuSort: {
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        background: "#FFFFFF",
        border: "1px solid #1A405F",
        borderRadius: "7px",
        width: "90%",
        height: "35px",
        textAlign: "center",
        fontWeight: "500",
        margin: "15px 0px 0px 0px",
    },
    imgSquare: {
        height: "100%",
        border: "2px dashed #56CA93",
        marginLeft: "-20px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px"
    },
    imgLabel: {
        textAlign: "center",
        fontFamily: "Josefin Sans",
        fontSize: "15px",
        color: "#1A405F"
    },
    imgDescription: {
        fontFamily: "Josefin Sans",
        fontSize: "15px",
        color: "#1A405F",
        fontWeight: 400,
        marginBottom: "15px"
    },
    checkboxes: {
        fontFamily: "Josefin Sans !important",
        fontWeight: 400,
        fontSize: "16px",
        color: "#1A405F"
    },
    scheduleModal: {
        padding: "15px",
        overflow: "hidden",
        width: "30%",
        position: "absolute",
        top: "0% !important",
        left: "35% !important",
        [theme.breakpoints.down("lg")]: {
            left: "28% !important",
        },
        [theme.breakpoints.up("1500")]: {
            width: "20%"
        },
    },
    RewardsFormTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "22px",
        textTransform: "capitalize",
        color: "#1A405F",
        marginTop: "5px"
    },
    rewardsBannerTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "24px",
        // textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "24px"
    },
    rewardsBannerDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        // textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "20px"
    },
    rewardsBannerButton: {
        width: "200px",
        borderRadius: "5px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "16px",
        background: "#E2A400",
        height: "42px",
        padding: "12px 25px",
        "&:hover, &:focus": {
            backgroundColor: "#E2A400",
        },
        [theme.breakpoints.down("lg")]: {
            fontFamily: "Josefin Sans",
            fontStyle: "normal",
            fontSize: "16px",
            width: "180px"
        },
    },
    bannerIcon: {
        marginRight: "10px",
        marginTop: "-5px"
    },
    input: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        '&::placeholder': {
            opacity: 1,
            fontFamily: "Josefin Sans",
            fontWeight: 400,
            color: "#1A405F",
        },
    },
    inputAmenities: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        marginTop: "5px",
        '&::placeholder': {
            opacity: 1,
            fontFamily: "Josefin Sans",
            fontWeight: 400,
            color: "#1A405F",
        },
    },
    searchBar: {
        width: "319px",
        padding: "3px 18px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        textTransform: "capitalize",
        borderRadius: "7px",
        height: "42px",
        background: "white",
        marginTop: "10px",
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E2A400',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#E2A400',
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: '#E2A400',
        },
        '&::placeholder': {
            color: "#1A405F !important",
        },
    },
    searchBarMobile: {
        width: "100%",
        padding: "3px 18px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        textTransform: "capitalize",
        borderRadius: "7px",
        height: "42px",
        background: "white",
        marginTop: "10px",
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E2A400',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#E2A400',
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: '#E2A400',
        },
        '&::placeholder': {
            color: "#1A405F !important",
        },
    },
    selectMenuSortRewardsPage: {
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        background: "#FFFFFF",
        border: "2px solid #56CA93",
        borderRadius: "7px",
        width: "318px",
        height: "42px",
        textAlign: "center",
        fontWeight: "700",
        margin: "10px 0px 0px 20px",
        paddingTop: "3px"
    },
    selectMenuSortRewardsPageMobile: {
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        background: "#FFFFFF",
        border: "2px solid #56CA93",
        borderRadius: "7px",
        width: "100%",
        height: "42px",
        textAlign: "center",
        fontWeight: "700",
        margin: "10px 0px 0px 0px",
        paddingTop: "3px"
    },
    wishListButton: {
        marginTop: "12px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "19px",
        background: "#1A405F",
        height: "42px",
        "&:hover, &:focus": {
            backgroundColor: "#1A405F",
        },
        borderRadius: "5px",
        width: "200px"
    },
    addToCartButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "19px",
        background: "#1A405F",
        height: "36px",
        width: "136px",
        margin: "10px 10px 0px 0px",
        "&:hover, &:focus": {
            backgroundColor: "#1A405F",
        },
        borderRadius: "7px",
        "&:disabled": {
            color: "#FFFFFF"
        }
    },
    addToCartButtonMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "19px",
        background: "#1A405F",
        height: "36px",
        width: "136px",
        margin: "10px 10px 0px 0px",
        "&:hover, &:focus": {
            backgroundColor: "#1A405F",
        },
        borderRadius: "5px",
        padding: "22px 30px",
        width: "90%",
        margin: "10px"
    },
    addedInCartButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "19px",
        background: "#06667C",
        height: "36px",
        width: "140px",
        margin: "10px 10px 0px 0px",
        "&:hover, &:focus": {
            backgroundColor: "#06667C",
        },
        borderRadius: "5px",
        padding: " 5px 10px"
    },
    addedInCartButtonMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "19px",
        background: "#06667C",
        height: "36px",
        width: "136px",
        margin: "10px 10px 0px 0px",
        "&:hover, &:focus": {
            backgroundColor: "#06667C",
        },
        borderRadius: "5px",
        padding: "22px 30px",
        width: "90%",
        margin: "10px"
    },
    rewardsCardListStyling: {
        height: "335px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        justifyContent: "center",
        width: "420px",
        [theme.breakpoints.down("lg")]: {
            width: "400px"
        },
    },
    rewardsCardListStylingMobile: {
        height: "auto",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "0px 5px !important",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        width: "100%",
        marginTop: "20px",
        marginBottom: "0px",
    },
    cardListStyling: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px",
        borderRadius: "7px",
        boxShadow: "0px 2px 2px 0px #DADADA",
        color: "#1A405F",
        width: "420px",
        [theme.breakpoints.down("lg")]: {
            width: "400px"
        },
    },
    cardListTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "20px",
        textTransform: "uppercase",
        marginTop: "10px",
        paddingBottom: "10px"
    },
    cardListTitleNoOverflow: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "20px",
        textTransform: "uppercase",
        marginTop: "10px",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    cardListDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "capitalize",
        marginTop: "10px",
        paddingLeft: "10px"
    },
    cardListPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "17px",
        marginTop: "10px",
        paddingLeft: "10px"
    },
    cardListPtsMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "17px",
        marginTop: "10px",
        paddingLeft: "10px",
        textAlign: "right"
    },
    rightArrow: {
        background: "transparent",
        border: 0,
        color: "#FECD2C",
        fontSize: "20px",
        position: "absolute",
        right: window.innerWidth <= 1280 && window.innerWidth > 1024 ? "80px" : "54px",
        [theme.breakpoints.up("xl")]: {
            right: "70px",
        },
        [theme.breakpoints.down("md")]: {
            right: "20px",
        },
        [theme.breakpoints.down("sm")]: {
            right: "20px",
        },
    },
    leftArrow: {
        background: "transparent",
        border: 0,
        color: "#FECD2C",
        fontSize: "20px",
        position: "absolute",
        left: window.innerWidth <= 1280 && window.innerWidth > 1024 ? "433px" : "475px",
        [theme.breakpoints.up("xl")]: {
            left: "635px",
        },
        [theme.breakpoints.down("md")]: {
            left: "360px",
        },
        [theme.breakpoints.down("sm")]: {
            left: "20px",
        },
    },
    icon: {
        marginRight: "10px !important",
        marginTop: "-5px !important",
        width: "24px !important",
        height: "24px !important"
    },
    cardListImg: {
        width: "180px",
        height: "150px",
        objectFit: "contain"
    },
    gridSpace: {
        display: "flex",
        padding: "0px 40px"
    },
    divStyle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    noRewardsTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#1A405F",
        marginTop: "30px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
        },
    },
    noRewardsNotBold: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "21px",
        textAlign: "center",
        color: "#1A405F",
        marginTop: "20px"
    },
    noRewardsCard: {
        border: "0.5px solid #56CA93",
        alignItems: "center",
        padding: "20px",
    },
    noRewardsButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        lineHeight: "17px",
        color: "#FFFFFF",
        background: "#56CA93",
        textTransform: "capitalize",
        height: "42px",
        padding: "15px",
        borderRadius: "7px",
        marginTop: "20px",
        "&:hover": {
            background: "#56CA93",
        },
    },
    selectMenuItem: {
        fontWeight: 500,
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F"
    },
    selectText: {
        fontWeight: 500,
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F"
    },
    title: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "30px",
        color: "#1A405F"
    },
    addToCartButtonDetail: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "19px",
        background: "#1A405F",
        height: "42px",
        width: "180px",
        margin: "-5px 0px 0px 20px",
        "&:hover, &:focus": {
            backgroundColor: "#1A405F",
        },
        borderRadius: "7px",
    },
    addToCartButtonDetailMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "19px",
        background: "#1A405F",
        height: "42px",
        width: "180px",
        margin: "10px 0px 0px 0px",
        "&:hover, &:focus": {
            backgroundColor: "#1A405F",
        },
        borderRadius: "7px",
    },
    detailCard: {
        padding: "20px",
        // border: "0.5px solid #56CA93",
        borderRadius: "7px",
        minHeight: "90px",
    },
    detailDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        marginRight: "10px",
        whiteSpace: "nowrap"
    },
    rewardDetailName: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "19px",
        lineHeight: "19px",
        color: "#1A405F",
        [theme.breakpoints.down('1300')]: {
            fontSize: "18px"
        },
    },
    travelDetailName: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "24px",
        color: "#1A405F",
        [theme.breakpoints.down('1300')]: {
            fontSize: "18px"
        },
    },
    travelDetailPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "20px",
        color: "#1A405F",
        [theme.breakpoints.down('1300')]: {
            fontSize: "18px"
        },
    },
    travelDetailNightlyDesc: {
        lineHeight: "21px",
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        color: "#1A405F",
        marginTop: "15px",
    },
    rewardDetailDescription: {
        fontFamily: "Josefin Sans !important",
        color: "#1A405F",
        fontSize: "16px",
        lineHeight: "21px",
        marginTop: "15px"
    },
    rewardDetailDescriptionDropdown: {
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        fontSize: "16px",
        lineHeight: "16px",
        marginTop: "17px",
        marginLeft: "2px",
        marginRight: "5px"
    },
    rewardDetailDescriptionDropdownMobile: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        color: "#1A405F",
        fontSize: "16px",
        lineHeight: "16px",
        marginTop: "17px",
        marginLeft: "2px",
        marginRight: "5px"
    },
    selectMenuSortDetailRewardsPage: {
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        background: "#FFFFFF",
        border: "2px solid #56CA93",
        borderRadius: "7px",
        width: "101px",
        height: "30px",
        textAlign: "center",
        fontWeight: "700",
        margin: "10px 0px 0px 0px",
        paddingTop: "3px"
    },
    divHover: {
        textAlign: "center",
        borderBottom: "1px solid #56CA93",
        "&:hover ": {
            cursor: "pointer"
        }
    },
    indexes: {
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        color: "#1A405F"
    },
    rdt: {
        position: "static",
        marginTop: "15px",
        top: "50%",
        left: "50%",
        width: "33%"
    },
    gridBorder: {
        border: "2px solid #56CA93",
        borderRadius: "7px",
        padding: "15px"
    },
    selectRoomButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "19px",
        lineHeight: "19px",
        color: "#FFFFFF",
        background: "#1A405F",
        "&:hover": {
            background: "#1A405F",
        },
        textTransform: "capitalize",
        borderRadius: "7px",
        height: "42px",
        width: "80%",
        marginTop: '10px'
    },
    datetime: {
        width: "100%",
        height: "30px !important",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        textTransform: "capitalize",
        borderRadius: "7px",
        background: "white",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#1A405F',
            },
            '&:hover fieldset': {
                borderColor: '#1A405F',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1A405F',
            },
        }
    },
    adournedEnd: {
        paddingRight: "0px !important",
        margin: "0px !important",
        maxHeight: "none !important",
        height: "30px"
    },
    datetimeButton: {
        background: "#1A405F",
        display: "block",
        margin: "0px !important",
        padding: "7px 11px !important",
        height: "30px",
        color: "white",
        width: "40px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 500,
        "&:hover, &:focus": {
            background: "#56CA93",
        },
    },
    inputDatetime: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        fontSize: "16px",
        padding: "5px"
        // '&::placeholder': {
        //     opacity:1,
        //     fontFamily: "Josefin Sans",
        //     fontWeight: 400,
        //     color:"#1A405F",
        //   },
    },
    minusAddBtn: {
        color: "#FFFFFF",
        background: "#56CA93",
        borderRadius: 100,
        width: "25px",
        height: "25px",
        marginTop: "10px"
    },
    travelAdultsChildrenDesc: {
        lineHeight: "21px",
        fontSize: "16px",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        color: "#1A405F",
        marginTop: "10px",
        display: "flex",
        justifyContent: "center"
    }
});


export default rewardsStyle;