const referralStyle = (theme) => ({
    gridItemFlexCenter: {
        display: "flex",
        justifyContent: "center"
    },
    gridItemMobileMarginTop: {
        marginTop: "15px"
    },
    isoMainContainer: {
        width: "52%",
        minHeight: "auto",
        // maxHeight: "32rem",
        background: "rgba(249, 249, 249, 1)",
        borderRadius: "10px",
        padding: "20px",
        marginTop:"30px"
    },
    cardHeaderTitle: {
        fontSize: "25px",
        fontWeight: 700,
        fontFamily: "Josefin Sans",
        marginBottom: "-25px",
        color: "#004253"
    },
    textFieldLabel: {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Josefin Sans",
        lineHeight: "16px",
        color: "#004253",
        marginTop: "20px"
    },
    textField: {
        width: "60%",
        background: "white",
        height: "35px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        border: "1px solid #929292",
        paddingLeft: "10px",
        borderRadius: "4px",
        "& .Mui-error": {
            fontFamily: "Josefin Sans",
            fontWeight: 600,
            // marginLeft: "-11px"
        },
        marginTop: "10px"
    },
    addIdButton: {
        background: "#06667C",
        color: "#FFFFFF",
        borderRadius: "8px",
        fontFamily: "Josefin Sans",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "16px",
        textTransform: "capitalize",
        marginTop: "20px",
        height: "44px",
        "&:hover": {
            background: "#06667C",
            color: "#FFFFFF",
        },
        width: "220px",
        [theme.breakpoints.down('md')]: {
            width: "165px",
        },
    },
    logoImg: {
        width: "90%",
        height: "200px",
        objectFit: "contain",
        paddingTop: "15px"
    },
    referralTermsStyle: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "14px",
        color: "#1A405F",
        "&:hover": {
            cursor: "pointer",
        },
        textDecoration: "underline"
    },
    selectMenuSortProfile: {
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        color: "#1A405F",
        background: "#FFFFFF",
        border: "1px solid #929292",
        borderRadius: "7px",
        width: "60%",
        height: "35px",
        textAlign: "center",
        margin: "10px 0px 0px 0px",
    },
    selectText: {
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F"
    },
    selectMenuItem: {
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        fontWeight: "500",
        fontSize: "16px"
    },
    input: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        fontSize: "16px",
        "&.MuiInputBase-input:-webkit-autofill": {
            height: "14px",
            marginLeft: "-5px",
            paddingLeft: "5px"
        },
    },
    errText: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        color: "red"
    },
    mobileIsoMainContainer: {
        width: "85%",
        height: "100%",
        background: "rgba(249, 249, 249, 1) !important",
        margin: "30px auto 0px auto",
        padding: "10px",
        paddingBottom: "30px"
    },
    textFieldMobile: {
        width: "100%",
        background: "white",
        height: "35px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        border: "1px solid #929292",
        paddingLeft: "10px",
        borderRadius: "4px",
        marginTop: "0px",
        "& .Mui-error": {
            fontFamily: "Josefin Sans",
            fontWeight: 600,
            // marginLeft: "-11px"
        },
    },
    addIdButtonMobile: {
        background: "#06667C",
        color: "#FFFFFF",
        borderRadius: "8px",
        fontFamily: "Josefin Sans",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "16px",
        textTransform: "capitalize",
        marginTop: "15px",
        height: "44px",
        width: "150px",
        "&:hover": {
          background: "#06667C",
          color: "#FFFFFF",
        }
      },
})

export default referralStyle;