import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import "./table.css"
import moment from "moment";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// mui core
import { MenuItem, Select, Typography } from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// styling
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';

// icons
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { zip } from "lodash";
import invMerchant from 'assets/img/tuzo/InviteMerchant.png'

function MobileTable(props) {
    const {
        classes,
        reservations,
        history,
        batchData,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        headers,
        points,
        docId,
        user
    } = props;

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    const renderEmptyTable = () => {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <img src={invMerchant} className={classes.emptyMerch} style={{ width: "100%" }} />
                </GridItem>

                <GridItem xs={12}
                    style={{ marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "left", flexDirection: "column" }}>
                    <Typography className={classes.invMerchHeader}>
                        No Reports Available
                    </Typography>

                    <Typography className={classes.invMerchDesc}>
                        It may take 24 hours for points data on each merchant to populate for the most recent batch.
                    </Typography>
                </GridItem>
            </GridContainer>
        )
    }

    useEffect(() => {
        batchData.slice(index, endIndex);
    }, [index]);
    const renderTableHeader = () => {
        return (
            <Tr
                // className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <Th className={classes.tableHeaders} key={index}>
                            {key.label === "# of Transactions" ? "Transactions" : key.label}
                        </Th>
                    );
                })}
            </Tr>
        );
    };


    const renderTableData = () => {
        let data = batchData
        return data
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <Tr
                        // className={index % 2 === 0 ? classes.tableRow : classes.tableRowOdd}
                        key={index}
                    >
                        <Td
                            className={classes.tableCell}
                        >
                            <span className={classes.spanHover}
                                onClick={() => {
                                    if (user.IsoAdminCompanyName) {
                                        history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reports/batch/${docId}/${d[0].docId}`)
                                    } else {
                                        history.push(`/merchant/reports/batch/${docId}/${d[0].docId}`)
                                    }
                                }}
                            >
                                {moment(d[0].reportDate).format("ddd MM/DD/YY, h:mm a")}
                            </span>
                        </Td>
                        <Td className={classes.tableCell}>{d[0].transactionCount}</Td>
                        <Td className={classes.tableCell}>{Number(d[0].saleAmount).toFixed(2)}</Td>
                        <Td className={classes.tableCell}>{d[0].batchNumber}</Td>
                        <Td className={classes.tableCell} style={{ fontWeight: 700 }}>{d[1]}</Td>
                    </Tr>
                );
            });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                {batchData.length > 0 ?
                    <Table className={classes.reportsTable} style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <Thead>
                            {renderTableHeader()}
                        </Thead>
                        <Tbody>
                            {renderTableData()}
                        </Tbody>
                    </Table>
                    :
                    <table className={classes.reportsTable} style={{ marginBottom: "10px", marginTop: "10px" }}>
                        {renderEmptyTable()}
                    </table>
                }

                {batchData.length > 0 ?
                    <GridContainer style={{ marginBottom: "-20px" }}>
                        <GridItem xs={8} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                            <h5
                                className={classes.indexes}
                            >
                                {index + 1} - {endIndex} of{" "}
                                {Math.ceil(batchData.length)}
                            </h5>
                        </GridItem>
                        <GridItem xs={4}>
                            <ReactPaginate
                                paginationSize={0}
                                onPageChange={handlePageClick}
                                pageCount={Math.ceil(batchData.length / itemsPerPage)}
                                nextLabel={
                                    <ArrowRight
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "white",
                                            marginTop: "-2px"
                                        }}
                                    />
                                }
                                previousLabel={
                                    <ArrowLeft
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "white",
                                            marginTop: "-2px"
                                        }}
                                    />
                                }
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </GridItem>
                    </GridContainer>
                    :
                    null
                }

            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(reportsStyle)
)(React.memo(MobileTable));
