import React from "react";
import PropTypes from "prop-types";

export const FooterText = props => (
  <span>
  </span>
);

FooterText.propTypes = {
  anchor: PropTypes.string.isRequired,
  rtlActive: PropTypes.bool
};
