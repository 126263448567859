import { getAccessorsForCollection, addCalculatedFields } from "../recordset";
import { CardFieldFormats } from "variables/constants";
import { DataTypes, ControlTypes } from "variables/constants";

export const config = {
    firestoreCollection: 'users',
    fields: {
        acceptedTerms: { type: DataTypes.BOOLEAN, },
        cellPhone: { type: DataTypes.NUMBER },
        companyName: { type: DataTypes.TEXT },
        credentials: { type: DataTypes.TEXT },
        dateOfBirth: { type: DataTypes.DATETIME },
        email: { type: DataTypes.TEXT },
        firstName: { type: DataTypes.TEXT },
        lastName: { type: DataTypes.TEXT },
        middleInitial: { type: DataTypes.TEXT },
        officePhone: { type: DataTypes.NUMBER },
        userType: { type: DataTypes.TEXT }
    },
    textSearchFields: [ "firstName", "lastName", "userType", "cellPhone" ]
}

function calculator(column, accessor) {
    switch(column) {
        case "fullName":
            return accessor("firstName") + " " + accessor("lastName");
        default:
            return accessor(column);
    }
}

export const accessorsForRecordset = addCalculatedFields(getAccessorsForCollection(config, "recordset","users"), calculator);

export default accessorsForRecordset;