/*eslint-disable*/
import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Recaptcha from "react-google-recaptcha";
import { db } from "firebase/client";
import { sendTempPassword } from "firebase/client";

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/merchantSignUpStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import {
  Button,
  Card,
  Hidden,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Close } from "@material-ui/icons";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PasswordPage from "./PasswordPage";
import Loader from "components/Loader/Loader";

export function RegisterPage(props) {
  const { classes, history } = props;
  const docId = window.location.pathname.split("/")[3];
  const [tempUser, setTempUser] = useState(false);
  const [checkCap, setCheckCap] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const generateTempPw = () => {
    let charList =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!^+%&/()=?_#${[]}|;:>÷`<.*-@";
    const charLength = charList.length;
    let pw = "";

    for (let i = 0; i < 8; i++) {
      pw += charList.charAt(Math.floor(Math.random() * charLength));
    }
    return pw;
  };

  const sendTempPw = async () => {
    const generatePw = generateTempPw();
    const data = {
      ...tempUser,
      newPw: generatePw,
    };
    setTempUser(data);
    await db
      .collection("temp-user")
      .doc(docId)
      .update({
        tempPw: generatePw,
      })
      .then(() => {
        history.push(`/auth/iso-register/${docId}/${generatePw}`, {
          tempUser: data,
        });
      });
  };

  useEffect(() => {
    if (!tempUser) {
      const getInfo = async () => {
        return db
          .collection("temp-user")
          .doc(docId)
          .get()
          .then((snapshot) => {
            const data = snapshot.data();
            setTempUser(data);
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setCompanyName(data.companyName);
            setEmail(data.email);
          });
      };
      getInfo();
    } else {
    }
  }, []);

  if (!tempUser) {
    return <Loader />;
  }

  return (
    <div style={{ overflow: "auto" }}>
      <Hidden mdUp implementation="css">
        <Card className={classes.mobileMainContainer}>
          <CardHeader className={classes.cardHeaderTitle}>
            <GridContainer>
              <GridItem xs={6}>Sign Up</GridItem>

              <GridItem
                xs={6}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <IconButton
                  style={{ color: "#004253", marginTop: "-15px" }}
                  onClick={() => history.push(`/auth/iso-register/${docId}`)}
                >
                  <Close />
                </IconButton>
              </GridItem>
              <GridItem
                xs={12}
                style={{ fontSize: "14px", fontWeight: 400, color: "#929292" }}
              >
                Step 1 of 2
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody className={classes.cardBody} style={{ marginTop: "30px" }}>
            <GridContainer>
              <GridItem xs={12}>
                <Typography className={classes.textFieldLabel}>
                  Company Name*
                </Typography>
                <TextField
                  className={classes.textFieldMobile}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                <Typography className={classes.textFieldLabel}>
                  First Name
                </Typography>
                <TextField
                  onChange={(e) => setFirstName(e.target.value)}
                  className={classes.textFieldMobile}
                  value={firstName}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                <Typography className={classes.textFieldLabel}>
                  Last Name
                </Typography>
                <TextField
                  onChange={(e) => setLastName(e.target.value)}
                  className={classes.textFieldMobile}
                  value={lastName}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                <Typography className={classes.textFieldLabel}>
                  Email*
                </Typography>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  className={classes.textFieldMobile}
                  value={email}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.captchaDiv}>
                  <Recaptcha
                    sitekey="6LcC51YoAAAAAIYCdO7dQ_BANZBPVLmKE_k3xAhU"
                    onChange={() => setCheckCap(true)}
                    onExpired={() => setCheckCap(false)}
                    size="normal"
                  />
                </div>
              </GridItem>

              <GridItem xs={12}>
                <Button
                  className={classes.addIdButtonMobile}
                  style={{ background: "#56CA93", width: "100%" }}
                  disabled={!checkCap}
                  onClick={() => {
                    sendTempPw();
                    // callMerchantInfo()
                  }}
                >
                  Next
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <div className={classes.linkContainerMobile}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <span
              className={classes.termsStyle}
              // onClick={handlePasswordReset}
              onClick={() =>
                window.open(
                  "https://www.tuzorewards.com/terms-of-use/",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Terms of Use
            </span>
            <span
              className={classes.termsStyle}
              // onClick={handlePasswordReset}
              onClick={() =>
                window.open(
                  "https://www.tuzorewards.com/privacy-policy/",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Privacy Policy
            </span>
          </div>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <Card className={classes.mainContainer}>
          <CardHeader className={classes.cardHeaderTitle}>
            <GridContainer>
              <GridItem xs={6}>Sign Up</GridItem>

              <GridItem
                xs={6}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <IconButton
                  style={{ color: "#004253", marginTop: "-15px" }}
                  onClick={() => history.push(`/auth/iso-register/${docId}`)}
                >
                  <Close />
                </IconButton>
              </GridItem>
              <GridItem
                xs={12}
                style={{ fontSize: "14px", fontWeight: 400, color: "#929292" }}
              >
                Step 1 of 2
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody className={classes.cardBody} style={{ marginTop: "30px" }}>
            <GridContainer>
              <GridItem xs={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "23vh",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className={classes.textFieldLabel}>
                    Company Name*
                  </Typography>

                  <Typography className={classes.textFieldLabel}>
                    First Name
                  </Typography>

                  <Typography className={classes.textFieldLabel}>
                    Last Name
                  </Typography>

                  <Typography className={classes.textFieldLabel}>
                    Email*
                  </Typography>
                </div>
              </GridItem>

              <GridItem xs={8}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "25vh",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    onChange={(e) => setCompanyName(e.target.value)}
                    className={classes.textField}
                    value={companyName}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />

                  <TextField
                    onChange={(e) => setFirstName(e.target.value)}
                    className={classes.textField}
                    value={firstName}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />

                  <TextField
                    onChange={(e) => setLastName(e.target.value)}
                    className={classes.textField}
                    value={lastName}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />

                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    className={classes.textField}
                    value={email}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </div>
              </GridItem>

              <GridItem xs={6}>
                <div
                  style={{
                    transform: "scale(0.95)",
                    marginTop: "5px",
                    marginLeft: "-10px",
                  }}
                >
                  <Recaptcha
                    sitekey="6LcC51YoAAAAAIYCdO7dQ_BANZBPVLmKE_k3xAhU"
                    onChange={() => setCheckCap(true)}
                    onExpired={() => setCheckCap(false)}
                  />
                </div>
              </GridItem>

              <GridItem
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  className={classes.addIdButton}
                  style={{ background: "#56CA93", marginLeft: "15px" }}
                  disabled={!checkCap}
                  onClick={() => {
                    sendTempPw();
                    // callMerchantInfo()
                  }}
                >
                  Next
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(RegisterPage)
);
