import React from 'react';
import DonutChart from 'react-donut-chart';
// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import { actionsForDialog } from 'store/actions/dialog';
import TermsAndConditionsDialog from 'views/Components/TermsAndConditionsDialog';
import Slide from '@material-ui/core/Slide';
import Hidden from "@material-ui/core/Hidden";
import { Typography } from '@material-ui/core';

// @material-ui/icons
import { AiOutlineDollar } from 'react-icons/ai';
import { Add, Search } from '@material-ui/icons';

import Style from 'assets/jss/material-dashboard-pro-react/views/adStyle';

import withStyles from '@material-ui/core/styles/withStyles';
import { useState } from 'react';
import { InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';

export function Filter(props) {
    const {
        classes,
        history,
        sortDropdown,
        setSortDropdown,
        ascDropdown,
        setAscDropdown,
        searchString,
        setSearchString,
        sortArr,
        ascArr,
        handleSearch,
        handleFilter
    } = props;
    return (
        <div>
            <GridContainer>
                <GridItem xs={4} style={{ display: "flex", alignItems: "center" }}>
                    <Select
                        className={classes.selectMenuSort}
                        value={sortDropdown}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => {
                            setSortDropdown(e.target.value)
                            handleFilter(ascDropdown, e.target.value)
                        }}
                    >
                        {sortArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        className={classes.selectAsc}
                        value={ascDropdown}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => {
                            setAscDropdown(e.target.value)
                            handleFilter(e.target.value, sortDropdown)
                        }}
                    >
                        {ascArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </GridItem>

                <GridItem xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <TextField
                        style={{ width: "50%" }}
                        className={classes.searchBar}
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                          }}
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                adornedEnd: classes.adournedEnd,
                                input: classes.input
                            },
                            endAdornment: (
                                <InputAdornment className={classes.adournedEnd} position="end">
                                    <Button 
                                    onClick={() => handleSearch()}
                                    className={classes.searchButton}>
                                        <Search style={{ marginRight: "5px" }} />
                                        Search
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) =>
            dispatch(
                actionsForNavigation.openChild('Profile Editor', '/admin/user/editor')
            ),
        logout: () => dispatch(AuthActions.startLogout()),
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Filter));
